/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProductOptionCreateRequest
 */
export interface ProductOptionCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductOptionCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionCreateRequest
   */
  partner_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ProductOptionCreateRequest
   */
  product_id: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionCreateRequest
   */
  time_slot_type: ProductOptionCreateRequestTimeSlotTypeEnum;
}

/**
 * @export
 */
export const ProductOptionCreateRequestTimeSlotTypeEnum = {
  StartingTime: 'starting_time',
  OpeningHours: 'opening_hours',
  TimeRange: 'time_range',
  Flexible: 'flexible'
} as const;
export type ProductOptionCreateRequestTimeSlotTypeEnum =
  (typeof ProductOptionCreateRequestTimeSlotTypeEnum)[keyof typeof ProductOptionCreateRequestTimeSlotTypeEnum];

/**
 * Check if a given object implements the ProductOptionCreateRequest interface.
 */
export function instanceOfProductOptionCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'time_slot_type' in value;

  return isInstance;
}

export function ProductOptionCreateRequestFromJSON(json: any): ProductOptionCreateRequest {
  return ProductOptionCreateRequestFromJSONTyped(json, false);
}

export function ProductOptionCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    product_id: json['product_id'],
    name: json['name'],
    time_slot_type: json['time_slot_type']
  };
}

export function ProductOptionCreateRequestToJSON(value?: ProductOptionCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    product_id: value.product_id,
    name: value.name,
    time_slot_type: value.time_slot_type
  };
}
