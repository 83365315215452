/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Addon Translation Inner Schema
 * @export
 * @interface AddonTranslationsSchema
 */
export interface AddonTranslationsSchema {
  /**
   *
   * @type {string}
   * @memberof AddonTranslationsSchema
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof AddonTranslationsSchema
   */
  display_name: string;
  /**
   *
   * @type {string}
   * @memberof AddonTranslationsSchema
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddonTranslationsSchema
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddonTranslationsSchema
   */
  addon_id?: string;
}

/**
 * Check if a given object implements the AddonTranslationsSchema interface.
 */
export function instanceOfAddonTranslationsSchema(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'display_name' in value;

  return isInstance;
}

export function AddonTranslationsSchemaFromJSON(json: any): AddonTranslationsSchema {
  return AddonTranslationsSchemaFromJSONTyped(json, false);
}

export function AddonTranslationsSchemaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonTranslationsSchema {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    display_name: json['display_name'],
    description: !exists(json, 'description') ? undefined : json['description'],
    id: !exists(json, 'id') ? undefined : json['id'],
    addon_id: !exists(json, 'addon_id') ? undefined : json['addon_id']
  };
}

export function AddonTranslationsSchemaToJSON(value?: AddonTranslationsSchema | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    display_name: value.display_name,
    description: value.description,
    id: value.id,
    addon_id: value.addon_id
  };
}
