/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserInviteRequest
 */
export interface UserInviteRequest {
  /**
   *
   * @type {string}
   * @memberof UserInviteRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof UserInviteRequest
   */
  partner_id?: string | null;
  /**
   * First name of the user
   * @type {string}
   * @memberof UserInviteRequest
   */
  first_name: string;
  /**
   * Last name of the user
   * @type {string}
   * @memberof UserInviteRequest
   */
  last_name: string;
  /**
   * Email address of the user
   * @type {string}
   * @memberof UserInviteRequest
   */
  email: string;
  /**
   * List of roles assigned to the user
   * @type {Array<string>}
   * @memberof UserInviteRequest
   */
  roles: Array<string>;
}

/**
 * Check if a given object implements the UserInviteRequest interface.
 */
export function instanceOfUserInviteRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'roles' in value;

  return isInstance;
}

export function UserInviteRequestFromJSON(json: any): UserInviteRequest {
  return UserInviteRequestFromJSONTyped(json, false);
}

export function UserInviteRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserInviteRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    email: json['email'],
    roles: json['roles']
  };
}

export function UserInviteRequestToJSON(value?: UserInviteRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    first_name: value.first_name,
    last_name: value.last_name,
    email: value.email,
    roles: value.roles
  };
}
