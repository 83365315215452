/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResellerUpdateRequest
 */
export interface ResellerUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  partner_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  commission_type?: ResellerUpdateRequestCommissionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ResellerUpdateRequest
   */
  commission_amount?: number;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  email_address: string;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof ResellerUpdateRequest
   */
  status?: ResellerUpdateRequestStatusEnum;
}

/**
 * @export
 */
export const ResellerUpdateRequestCommissionTypeEnum = {
  Absolute: 'absolute',
  Percentage: 'percentage'
} as const;
export type ResellerUpdateRequestCommissionTypeEnum =
  (typeof ResellerUpdateRequestCommissionTypeEnum)[keyof typeof ResellerUpdateRequestCommissionTypeEnum];

/**
 * @export
 */
export const ResellerUpdateRequestStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted',
  Invited: 'invited'
} as const;
export type ResellerUpdateRequestStatusEnum =
  (typeof ResellerUpdateRequestStatusEnum)[keyof typeof ResellerUpdateRequestStatusEnum];

/**
 * Check if a given object implements the ResellerUpdateRequest interface.
 */
export function instanceOfResellerUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email_address' in value;
  isInstance = isInstance && 'phone_number' in value;

  return isInstance;
}

export function ResellerUpdateRequestFromJSON(json: any): ResellerUpdateRequest {
  return ResellerUpdateRequestFromJSONTyped(json, false);
}

export function ResellerUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    commission_type: !exists(json, 'commission_type') ? undefined : json['commission_type'],
    commission_amount: !exists(json, 'commission_amount') ? undefined : json['commission_amount'],
    name: json['name'],
    last_name: json['last_name'],
    email_address: json['email_address'],
    phone_number: json['phone_number'],
    status: !exists(json, 'status') ? undefined : json['status']
  };
}

export function ResellerUpdateRequestToJSON(value?: ResellerUpdateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    commission_type: value.commission_type,
    commission_amount: value.commission_amount,
    name: value.name,
    last_name: value.last_name,
    email_address: value.email_address,
    phone_number: value.phone_number,
    status: value.status
  };
}
