/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResellerReadResponseDataInner } from './ResellerReadResponseDataInner';
import {
  ResellerReadResponseDataInnerFromJSON,
  ResellerReadResponseDataInnerFromJSONTyped,
  ResellerReadResponseDataInnerToJSON
} from './ResellerReadResponseDataInner';
import type { ResellerReadResponseMeta } from './ResellerReadResponseMeta';
import {
  ResellerReadResponseMetaFromJSON,
  ResellerReadResponseMetaFromJSONTyped,
  ResellerReadResponseMetaToJSON
} from './ResellerReadResponseMeta';

/**
 *
 * @export
 * @interface ResellerReadResponse
 */
export interface ResellerReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ResellerReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponse
   */
  error_code?: ResellerReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ResellerReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<ResellerReadResponseDataInner>}
   * @memberof ResellerReadResponse
   */
  data: Array<ResellerReadResponseDataInner>;
  /**
   *
   * @type {ResellerReadResponseMeta}
   * @memberof ResellerReadResponse
   */
  meta: ResellerReadResponseMeta;
}

/**
 * @export
 */
export const ResellerReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ResellerReadResponseErrorCodeEnum =
  (typeof ResellerReadResponseErrorCodeEnum)[keyof typeof ResellerReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the ResellerReadResponse interface.
 */
export function instanceOfResellerReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'data' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function ResellerReadResponseFromJSON(json: any): ResellerReadResponse {
  return ResellerReadResponseFromJSONTyped(json, false);
}

export function ResellerReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    data: (json['data'] as Array<any>).map(ResellerReadResponseDataInnerFromJSON),
    meta: ResellerReadResponseMetaFromJSON(json['meta'])
  };
}

export function ResellerReadResponseToJSON(value?: ResellerReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    data: (value.data as Array<any>).map(ResellerReadResponseDataInnerToJSON),
    meta: ResellerReadResponseMetaToJSON(value.meta)
  };
}
