/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmailTemplatesReadResponseEmailTemplatesInner
 */
export interface EmailTemplatesReadResponseEmailTemplatesInner {
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponseEmailTemplatesInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponseEmailTemplatesInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponseEmailTemplatesInner
   */
  status?: EmailTemplatesReadResponseEmailTemplatesInnerStatusEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponseEmailTemplatesInner
   */
  created_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponseEmailTemplatesInner
   */
  partner_id: string;
}

/**
 * @export
 */
export const EmailTemplatesReadResponseEmailTemplatesInnerStatusEnum = {
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type EmailTemplatesReadResponseEmailTemplatesInnerStatusEnum =
  (typeof EmailTemplatesReadResponseEmailTemplatesInnerStatusEnum)[keyof typeof EmailTemplatesReadResponseEmailTemplatesInnerStatusEnum];

/**
 * Check if a given object implements the EmailTemplatesReadResponseEmailTemplatesInner interface.
 */
export function instanceOfEmailTemplatesReadResponseEmailTemplatesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'partner_id' in value;

  return isInstance;
}

export function EmailTemplatesReadResponseEmailTemplatesInnerFromJSON(
  json: any
): EmailTemplatesReadResponseEmailTemplatesInner {
  return EmailTemplatesReadResponseEmailTemplatesInnerFromJSONTyped(json, false);
}

export function EmailTemplatesReadResponseEmailTemplatesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTemplatesReadResponseEmailTemplatesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    created_at: !exists(json, 'created_at') ? undefined : json['created_at'],
    partner_id: json['partner_id']
  };
}

export function EmailTemplatesReadResponseEmailTemplatesInnerToJSON(
  value?: EmailTemplatesReadResponseEmailTemplatesInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: value.status,
    created_at: value.created_at,
    partner_id: value.partner_id
  };
}
