/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResellerCreateResponseData
 */
export interface ResellerCreateResponseData {
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  commission_type: ResellerCreateResponseDataCommissionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ResellerCreateResponseData
   */
  commission_amount: number;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  email_address: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponseData
   */
  status: ResellerCreateResponseDataStatusEnum;
}

/**
 * @export
 */
export const ResellerCreateResponseDataCommissionTypeEnum = {
  Absolute: 'absolute',
  Percentage: 'percentage'
} as const;
export type ResellerCreateResponseDataCommissionTypeEnum =
  (typeof ResellerCreateResponseDataCommissionTypeEnum)[keyof typeof ResellerCreateResponseDataCommissionTypeEnum];

/**
 * @export
 */
export const ResellerCreateResponseDataStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted',
  Invited: 'invited'
} as const;
export type ResellerCreateResponseDataStatusEnum =
  (typeof ResellerCreateResponseDataStatusEnum)[keyof typeof ResellerCreateResponseDataStatusEnum];

/**
 * Check if a given object implements the ResellerCreateResponseData interface.
 */
export function instanceOfResellerCreateResponseData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'commission_type' in value;
  isInstance = isInstance && 'commission_amount' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email_address' in value;
  isInstance = isInstance && 'phone_number' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function ResellerCreateResponseDataFromJSON(json: any): ResellerCreateResponseData {
  return ResellerCreateResponseDataFromJSONTyped(json, false);
}

export function ResellerCreateResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerCreateResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    commission_type: json['commission_type'],
    commission_amount: json['commission_amount'],
    name: json['name'],
    last_name: json['last_name'],
    email_address: json['email_address'],
    phone_number: json['phone_number'],
    status: json['status']
  };
}

export function ResellerCreateResponseDataToJSON(value?: ResellerCreateResponseData | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    commission_type: value.commission_type,
    commission_amount: value.commission_amount,
    name: value.name,
    last_name: value.last_name,
    email_address: value.email_address,
    phone_number: value.phone_number,
    status: value.status
  };
}
