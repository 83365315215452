/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReceiptSettingsReadResponseReceiptSettings } from './ReceiptSettingsReadResponseReceiptSettings';
import {
  ReceiptSettingsReadResponseReceiptSettingsFromJSON,
  ReceiptSettingsReadResponseReceiptSettingsFromJSONTyped,
  ReceiptSettingsReadResponseReceiptSettingsToJSON
} from './ReceiptSettingsReadResponseReceiptSettings';

/**
 *
 * @export
 * @interface ReceiptSettingsReadResponse
 */
export interface ReceiptSettingsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ReceiptSettingsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ReceiptSettingsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ReceiptSettingsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ReceiptSettingsReadResponse
   */
  error_code?: ReceiptSettingsReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ReceiptSettingsReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {ReceiptSettingsReadResponseReceiptSettings}
   * @memberof ReceiptSettingsReadResponse
   */
  receipt_settings: ReceiptSettingsReadResponseReceiptSettings;
}

/**
 * @export
 */
export const ReceiptSettingsReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ReceiptSettingsReadResponseErrorCodeEnum =
  (typeof ReceiptSettingsReadResponseErrorCodeEnum)[keyof typeof ReceiptSettingsReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the ReceiptSettingsReadResponse interface.
 */
export function instanceOfReceiptSettingsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'receipt_settings' in value;

  return isInstance;
}

export function ReceiptSettingsReadResponseFromJSON(json: any): ReceiptSettingsReadResponse {
  return ReceiptSettingsReadResponseFromJSONTyped(json, false);
}

export function ReceiptSettingsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReceiptSettingsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    receipt_settings: ReceiptSettingsReadResponseReceiptSettingsFromJSON(json['receipt_settings'])
  };
}

export function ReceiptSettingsReadResponseToJSON(value?: ReceiptSettingsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    receipt_settings: ReceiptSettingsReadResponseReceiptSettingsToJSON(value.receipt_settings)
  };
}
