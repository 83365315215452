import { configure, defineRule } from 'vee-validate';
import { localize } from '@vee-validate/i18n';
import postalCodes from 'postal-codes-js';
import {
  alpha,
  alpha_spaces,
  required,
  max,
  min,
  url,
  email,
  alpha_dash,
  alpha_num,
  numeric,
  confirmed
} from '@vee-validate/rules';

// Import all the translations (TODO - can be optimized later by somehow dynamically importing the translations)
import da from '@vee-validate/i18n/dist/locale/da.json';
import de from '@vee-validate/i18n/dist/locale/de.json';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import fi from '@vee-validate/i18n/dist/locale/fi.json';
import fr from '@vee-validate/i18n/dist/locale/fr.json';
import it from '@vee-validate/i18n/dist/locale/it.json';
import ja from '@vee-validate/i18n/dist/locale/ja.json';
import nl from '@vee-validate/i18n/dist/locale/nl.json';
import no from '@vee-validate/i18n/dist/locale/nb_NO.json';
import pl from '@vee-validate/i18n/dist/locale/pl.json';
import sv from '@vee-validate/i18n/dist/locale/sv.json';
import ru from '@vee-validate/i18n/dist/locale/ru.json';
import { Temporal } from '@js-temporal/polyfill';
import { toTemporalDurationLocalizedString } from '@/utils/duration.ts';
import i18n from '@/i18n.ts';

// Define rules globally, otherwise the error messages are not translated
defineRule('required', required);
defineRule('alpha_spaces', alpha_spaces);
defineRule('alpha', alpha);
defineRule('max', max);
defineRule('min', min);
defineRule('url', url);
defineRule('email', email);
defineRule('alpha_dash', alpha_dash);
defineRule('alpha_num', alpha_num);
defineRule('numeric', numeric);
defineRule('confirmed', confirmed);

defineRule('postal_code', (value: string, [countryCode]: string) => {
  return postalCodes.validate(countryCode, value);
});

defineRule(
  'duration_smaller_than',
  (temporalDurationString: string | null | undefined, [compareTemporalDurationString]: string) => {
    if (!temporalDurationString || !compareTemporalDurationString) {
      return true;
    }
    const temporalDuration = Temporal.Duration.from(temporalDurationString);
    const temporalDurationCompare = Temporal.Duration.from(compareTemporalDurationString);
    if (Temporal.Duration.compare(temporalDuration, temporalDurationCompare) >= 0) {
      const temporalString = toTemporalDurationLocalizedString(temporalDurationCompare.toString());
      return i18n.global.t('portal_validation_duration_smaller_than').replace('%1', temporalString);
    } else {
      return true;
    }
  }
);
defineRule('duration_non_zero', (temporalDurationString: string | null | undefined) => {
  if (temporalDurationString === null || temporalDurationString === undefined) {
    return true;
  }
  const temporalDuration = Temporal.Duration.from(temporalDurationString);
  if (temporalDuration.total({ unit: 'seconds' }) === 0) {
    return i18n.global.t('portal_validation_duration_non_zero');
  }
  return true;
});

defineRule('phone', (value: string) => {
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  if (!value || !phoneRegex.test(value)) {
    return false;
  }
  return true;
});
defineRule('verify_password', (value: string) => {
  const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  return strongRegex.test(value);
});
defineRule('email_multiline', (value: string) => {
  if (!value) {
    return true;
  }

  const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
  // Split the value by new line and check each email
  const emails = value.split('\n');
  for (const email of emails) {
    if (!emailRegex.test(email)) {
      return false;
    }
  }
  return true;
});

defineRule('greater_than_zero', (value: number) => {
  if (typeof value !== 'number') {
    return false;
  }
  if (value <= 0) {
    return i18n.global.t('portal_validation_duration_greater_than_zero');
  }
  return true;
});

// Export the validator functions
export const noTrailingSpacesValidator = (value: string) => {
  if (!value) {
    return true;
  }
  if (value.trim() !== value) {
    return i18n.global.t('portal_validation_no_trailing_spaces');
  }
  return true;
};

export const noMiddleSpacesValidator = (value: string) => {
  if (!value) {
    return true;
  }
  const trimmed = value.trim();
  if (trimmed.includes(' ')) {
    return i18n.global.t('portal_validation_no_middle_spaces');
  }
  return true;
};

export const noSpacesValidator = (value: string) => {
  if (!value) {
    return true;
  }
  if (value.includes(' ')) {
    return i18n.global.t('portal_validation_no_spaces');
  }
  return true;
};

defineRule('no_trailing_spaces', noTrailingSpacesValidator);
defineRule('no_middle_spaces', noMiddleSpacesValidator);
defineRule('no_spaces', noSpacesValidator);

export const configureVeeValidate = async () => {
  configure({ generateMessage: localize({ da, de, en, es, fi, fr, it, ja, nl, no, pl, sv, ru }) });

  // Custom translations for translations
  configure({
    generateMessage: localize({
      da: { messages: { phone: 'Dette {field} skal være et internationalt telefonnummer' } },
      de: { messages: { phone: 'Dieses {field} sollte eine internationale Telefonnummer sein' } },
      en: { messages: { phone: 'This {field} should be an international phone number' } },
      es: { messages: { phone: 'Este {field} debe ser un número de teléfono internacional' } },
      fi: { messages: { phone: 'Tämän {field} tulee olla kansainvälinen puhelinnumero' } },
      fr: { messages: { phone: 'Ce {field} doit être un numéro de téléphone international' } },
      it: {
        messages: { phone: 'Questo {field} deve essere un numero di telefono internazionale' }
      },
      ja: { messages: { phone: 'この {field} は国際電話番号である必要があります' } },
      nl: { messages: { phone: 'Dit {field} moet een internationaal telefoonnummer zijn' } },
      no: { messages: { phone: 'Dette {field} skal være et internasjonalt telefonnummer' } },
      pl: { messages: { phone: 'To {field} powinno zawierać międzynarodowy numer telefonu' } },
      sv: { messages: { phone: 'Detta {field} ska vara ett internationellt telefonnummer' } },
      ru: { messages: { phone: 'Это {field} должно быть международным номером телефона.' } }
    })
  });
};
