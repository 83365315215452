import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const CHANNELS_ROUTES = [
  {
    path: `/sell/${ROUTE_NAMES.CHANNELS}`,
    name: ROUTE_NAMES.CHANNELS,
    component: () => import('@/views/pages/sell/channel/ChannelList.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.CHANNELS],
      label: PAGE_TITLES.CHANNELS,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ChannelView],
      icon: 'pi pi-fw pi-external-link'
    }
  },
  {
    path: `/sell/${ROUTE_NAMES.CHANNELS}/:channelId`,
    name: ROUTE_NAMES.CHANNEL_DETAILS,
    component: () => import('@/views/pages/sell/channel/ChannelDetails.vue'),
    redirect: { name: ROUTE_NAMES.CHANNEL_SETTINGS },
    children: [
      {
        path: `product-connections`,
        name: ROUTE_NAMES.CHANNEL_PRODUCT_CONNECTIONS,
        component: () => import('@/views/pages/sell/channel/ChannelProductConnections.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.CHANNELS,
            PAGE_TITLES.CHANNEL_DETAILS,
            PAGE_TITLES.CHANNEL_PRODUCT_CONFIGURATIONS
          ]
        }
      },
      {
        path: `product-connections/product-option-channel-configuration/:productOptionChannelConfigurationId`,
        name: ROUTE_NAMES.CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_EDIT,
        component: () =>
          import('@/views/pages/sell/channel/ChannelProductOptionChannelConfigurationEdit.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.CHANNELS,
            PAGE_TITLES.CHANNEL_DETAILS,
            PAGE_TITLES.CHANNEL_PRODUCT_CONFIGURATIONS,
            PAGE_TITLES.CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_EDIT
          ]
        }
      },
      {
        path: `product-connections/product-option-channel-configuration/+`,
        name: ROUTE_NAMES.CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_NEW,
        component: () =>
          import('@/views/pages/sell/channel/ChannelProductOptionChannelConfigurationNew.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.CHANNELS,
            PAGE_TITLES.CHANNEL_DETAILS,
            PAGE_TITLES.CHANNEL_PRODUCT_CONFIGURATIONS,
            PAGE_TITLES.CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_NEW
          ]
        }
      },

      {
        path: `settings`,
        name: ROUTE_NAMES.CHANNEL_SETTINGS,
        component: () => import('@/views/pages/sell/channel/ChannelSettings.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.SELL,
            PAGE_TITLES.CHANNELS,
            PAGE_TITLES.CHANNEL_DETAILS,
            PAGE_TITLES.CHANNEL_SETTINGS
          ]
        }
      }
    ]
  }
];
