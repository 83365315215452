/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelCategoryMappingReadResponsePricingCategoryMappingsInner } from './ChannelCategoryMappingReadResponsePricingCategoryMappingsInner';
import {
  ChannelCategoryMappingReadResponsePricingCategoryMappingsInnerFromJSON,
  ChannelCategoryMappingReadResponsePricingCategoryMappingsInnerFromJSONTyped,
  ChannelCategoryMappingReadResponsePricingCategoryMappingsInnerToJSON
} from './ChannelCategoryMappingReadResponsePricingCategoryMappingsInner';

/**
 *
 * @export
 * @interface ChannelCategoryMappingReadResponse
 */
export interface ChannelCategoryMappingReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ChannelCategoryMappingReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ChannelCategoryMappingReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelCategoryMappingReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelCategoryMappingReadResponse
   */
  error_code?: ChannelCategoryMappingReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ChannelCategoryMappingReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChannelCategoryMappingReadResponse
   */
  channel_allows_category_mapping: boolean;
  /**
   *
   * @type {string}
   * @memberof ChannelCategoryMappingReadResponse
   */
  channel_name: string;
  /**
   *
   * @type {Array<ChannelCategoryMappingReadResponsePricingCategoryMappingsInner>}
   * @memberof ChannelCategoryMappingReadResponse
   */
  pricing_category_mappings?: Array<ChannelCategoryMappingReadResponsePricingCategoryMappingsInner>;
  /**
   *
   * @type {Array<string>}
   * @memberof ChannelCategoryMappingReadResponse
   */
  allowed_channel_category_values?: Array<string>;
}

/**
 * @export
 */
export const ChannelCategoryMappingReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ChannelCategoryMappingReadResponseErrorCodeEnum =
  (typeof ChannelCategoryMappingReadResponseErrorCodeEnum)[keyof typeof ChannelCategoryMappingReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the ChannelCategoryMappingReadResponse interface.
 */
export function instanceOfChannelCategoryMappingReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'channel_allows_category_mapping' in value;
  isInstance = isInstance && 'channel_name' in value;

  return isInstance;
}

export function ChannelCategoryMappingReadResponseFromJSON(
  json: any
): ChannelCategoryMappingReadResponse {
  return ChannelCategoryMappingReadResponseFromJSONTyped(json, false);
}

export function ChannelCategoryMappingReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelCategoryMappingReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    channel_allows_category_mapping: json['channel_allows_category_mapping'],
    channel_name: json['channel_name'],
    pricing_category_mappings: !exists(json, 'pricing_category_mappings')
      ? undefined
      : (json['pricing_category_mappings'] as Array<any>).map(
          ChannelCategoryMappingReadResponsePricingCategoryMappingsInnerFromJSON
        ),
    allowed_channel_category_values: !exists(json, 'allowed_channel_category_values')
      ? undefined
      : json['allowed_channel_category_values']
  };
}

export function ChannelCategoryMappingReadResponseToJSON(
  value?: ChannelCategoryMappingReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    channel_allows_category_mapping: value.channel_allows_category_mapping,
    channel_name: value.channel_name,
    pricing_category_mappings:
      value.pricing_category_mappings === undefined
        ? undefined
        : (value.pricing_category_mappings as Array<any>).map(
            ChannelCategoryMappingReadResponsePricingCategoryMappingsInnerToJSON
          ),
    allowed_channel_category_values: value.allowed_channel_category_values
  };
}
