/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { NewsletterSettingsPartnerTranslationsInner } from './NewsletterSettingsPartnerTranslationsInner';
import {
  NewsletterSettingsPartnerTranslationsInnerFromJSON,
  NewsletterSettingsPartnerTranslationsInnerFromJSONTyped,
  NewsletterSettingsPartnerTranslationsInnerToJSON
} from './NewsletterSettingsPartnerTranslationsInner';

/**
 *
 * @export
 * @interface NewsletterSettingsUpdateRequest
 */
export interface NewsletterSettingsUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof NewsletterSettingsUpdateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof NewsletterSettingsUpdateRequest
   */
  partner_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof NewsletterSettingsUpdateRequest
   */
  id?: string;
  /**
   *
   * @type {boolean}
   * @memberof NewsletterSettingsUpdateRequest
   */
  show_subscribe_to_newsletter?: boolean | null;
  /**
   *
   * @type {Array<NewsletterSettingsPartnerTranslationsInner>}
   * @memberof NewsletterSettingsUpdateRequest
   */
  partner_translation: Array<NewsletterSettingsPartnerTranslationsInner>;
}

/**
 * Check if a given object implements the NewsletterSettingsUpdateRequest interface.
 */
export function instanceOfNewsletterSettingsUpdateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'partner_translation' in value;

  return isInstance;
}

export function NewsletterSettingsUpdateRequestFromJSON(
  json: any
): NewsletterSettingsUpdateRequest {
  return NewsletterSettingsUpdateRequestFromJSONTyped(json, false);
}

export function NewsletterSettingsUpdateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): NewsletterSettingsUpdateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    id: !exists(json, 'id') ? undefined : json['id'],
    show_subscribe_to_newsletter: !exists(json, 'show_subscribe_to_newsletter')
      ? undefined
      : json['show_subscribe_to_newsletter'],
    partner_translation: (json['partner_translation'] as Array<any>).map(
      NewsletterSettingsPartnerTranslationsInnerFromJSON
    )
  };
}

export function NewsletterSettingsUpdateRequestToJSON(
  value?: NewsletterSettingsUpdateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    id: value.id,
    show_subscribe_to_newsletter: value.show_subscribe_to_newsletter,
    partner_translation: (value.partner_translation as Array<any>).map(
      NewsletterSettingsPartnerTranslationsInnerToJSON
    )
  };
}
