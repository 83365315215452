/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingsRevenueGraphReadResponseDatasetInner } from './BookingsRevenueGraphReadResponseDatasetInner';
import {
  BookingsRevenueGraphReadResponseDatasetInnerFromJSON,
  BookingsRevenueGraphReadResponseDatasetInnerFromJSONTyped,
  BookingsRevenueGraphReadResponseDatasetInnerToJSON
} from './BookingsRevenueGraphReadResponseDatasetInner';

/**
 *
 * @export
 * @interface BookingsRevenueGraphReadResponse
 */
export interface BookingsRevenueGraphReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof BookingsRevenueGraphReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof BookingsRevenueGraphReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof BookingsRevenueGraphReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof BookingsRevenueGraphReadResponse
   */
  error_code?: BookingsRevenueGraphReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof BookingsRevenueGraphReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {string}
   * @memberof BookingsRevenueGraphReadResponse
   */
  currency: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BookingsRevenueGraphReadResponse
   */
  labels: Array<string>;
  /**
   *
   * @type {Array<BookingsRevenueGraphReadResponseDatasetInner>}
   * @memberof BookingsRevenueGraphReadResponse
   */
  dataset: Array<BookingsRevenueGraphReadResponseDatasetInner>;
}

/**
 * @export
 */
export const BookingsRevenueGraphReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type BookingsRevenueGraphReadResponseErrorCodeEnum =
  (typeof BookingsRevenueGraphReadResponseErrorCodeEnum)[keyof typeof BookingsRevenueGraphReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the BookingsRevenueGraphReadResponse interface.
 */
export function instanceOfBookingsRevenueGraphReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'currency' in value;
  isInstance = isInstance && 'labels' in value;
  isInstance = isInstance && 'dataset' in value;

  return isInstance;
}

export function BookingsRevenueGraphReadResponseFromJSON(
  json: any
): BookingsRevenueGraphReadResponse {
  return BookingsRevenueGraphReadResponseFromJSONTyped(json, false);
}

export function BookingsRevenueGraphReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingsRevenueGraphReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    currency: json['currency'],
    labels: json['labels'],
    dataset: (json['dataset'] as Array<any>).map(
      BookingsRevenueGraphReadResponseDatasetInnerFromJSON
    )
  };
}

export function BookingsRevenueGraphReadResponseToJSON(
  value?: BookingsRevenueGraphReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    currency: value.currency,
    labels: value.labels,
    dataset: (value.dataset as Array<any>).map(BookingsRevenueGraphReadResponseDatasetInnerToJSON)
  };
}
