import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { RESELLERS_FEATURE_FLAG } from './resellers';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';
import { ROUTE_NAMES } from '@/constants/routes';

export const RESELLER_ORDERS_ROUTES = [
  {
    path: 'sell/reseller-orders',
    name: ROUTE_NAMES.RESELLER_ORDERS,
    component: () => import('@/views/pages/sell/ResellerOrders.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELL, PAGE_TITLES.RESELLER_ORDERS],
      permissions: [InitializationReadResponsePermissionsEnum.ResellerBookingsView],
      menu: ['main'],
      label: PAGE_TITLES.RESELLER_ORDERS,
      icon: 'pi pi-fw pi-book',
      featureFlag: RESELLERS_FEATURE_FLAG
    }
  }
];
