/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BusinessInformationUpdateResponsePartner } from './BusinessInformationUpdateResponsePartner';
import {
  BusinessInformationUpdateResponsePartnerFromJSON,
  BusinessInformationUpdateResponsePartnerFromJSONTyped,
  BusinessInformationUpdateResponsePartnerToJSON
} from './BusinessInformationUpdateResponsePartner';

/**
 *
 * @export
 * @interface BusinessInformationUpdateResponse
 */
export interface BusinessInformationUpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof BusinessInformationUpdateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessInformationUpdateResponse
   */
  error_code?: BusinessInformationUpdateResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof BusinessInformationUpdateResponse
   */
  error_message?: string;
  /**
   *
   * @type {BusinessInformationUpdateResponsePartner}
   * @memberof BusinessInformationUpdateResponse
   */
  partner?: BusinessInformationUpdateResponsePartner;
}

/**
 * @export
 */
export const BusinessInformationUpdateResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type BusinessInformationUpdateResponseErrorCodeEnum =
  (typeof BusinessInformationUpdateResponseErrorCodeEnum)[keyof typeof BusinessInformationUpdateResponseErrorCodeEnum];

/**
 * Check if a given object implements the BusinessInformationUpdateResponse interface.
 */
export function instanceOfBusinessInformationUpdateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function BusinessInformationUpdateResponseFromJSON(
  json: any
): BusinessInformationUpdateResponse {
  return BusinessInformationUpdateResponseFromJSONTyped(json, false);
}

export function BusinessInformationUpdateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessInformationUpdateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    partner: !exists(json, 'partner')
      ? undefined
      : BusinessInformationUpdateResponsePartnerFromJSON(json['partner'])
  };
}

export function BusinessInformationUpdateResponseToJSON(
  value?: BusinessInformationUpdateResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    partner: BusinessInformationUpdateResponsePartnerToJSON(value.partner)
  };
}
