import { DIRECTUS_API_URL } from '@/config';
import { DEFAULT_LOCALE } from '@/constants/i18n';
import { ROUTE_NAMES } from '@/constants/routes';
import { directus } from '@/service/directus';
import { authApi as AuthAPIAuthenticated } from '@/service/TicketingApi';
import { useUserStore } from '@/stores/user';
import {
  AuthApi,
  type AuthRegisterPostRequest,
  type AuthResetPasswordPostRequest,
  type AuthRolesGetRequest,
  type AuthUpdateRolePostRequest,
  type AuthUserInviteAcceptPostRequest
} from '@openapi/apis/AuthApi';
import { Configuration } from '@openapi/runtime';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export function useAuth() {
  const { setImpersonatePartnerName } = useUserStore();
  const router = useRouter();

  const config = new Configuration({
    basePath: DIRECTUS_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const authApi: AuthApi = new AuthApi(config);

  const loading = ref(false);

  const registerInfo = async () => {
    loading.value = true;
    const response = await authApi.authRegisterGet();
    if (!response.success) {
      throw new Error('Failed to fetch register info');
    }
    loading.value = false;
    return response;
  };

  const registerUser = async (requestParameters: AuthRegisterPostRequest) => {
    return await authApi.authRegisterPost(requestParameters);
  };

  const checkUserRole = async () => {
    const response = await AuthAPIAuthenticated.authCheckRoleGet();
    setImpersonatePartnerName(response.impersonating ?? null);
    return response;
  };

  const updateUserRole = async (requestParameters: AuthUpdateRolePostRequest) => {
    await AuthAPIAuthenticated.authUpdateRolePost(requestParameters);
    await directus.refresh();
    setImpersonatePartnerName(requestParameters?.rolesUpdateRequest?.partner_id ?? null);
  };

  const resetPassword = async (requestParameters: AuthResetPasswordPostRequest) => {
    return await authApi.authResetPasswordPost(requestParameters);
  };

  const getRoles = async (localeCode: AuthRolesGetRequest['localeCode']) => {
    const response = await AuthAPIAuthenticated.authRolesGet({
      localeCode
    });
    return response.roles;
  };

  const userInviteAccept = async (requestParameters: AuthUserInviteAcceptPostRequest) => {
    return await authApi.authUserInviteAcceptPost(requestParameters);
  };

  const logout = async () => {
    const { isGygAdminUser } = storeToRefs(useUserStore());

    if (isGygAdminUser.value) {
      await updateUserRole({
        rolesUpdateRequest: {
          locale_code: DEFAULT_LOCALE
        }
      });
    }

    await directus.logout();
    window.localStorage.removeItem('auth_expires_at');
    await router.push({ name: ROUTE_NAMES.LOGIN });
  };

  return {
    loading,
    registerInfo,
    registerUser,
    checkUserRole,
    updateUserRole,
    resetPassword,
    getRoles,
    userInviteAccept,
    logout
  };
}
