import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { ROUTE_NAMES } from '@/constants/routes';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

export const COMMUNICATION_ROUTES = [
  {
    path: '/communication',
    name: ROUTE_NAMES.COMMUNICATION,
    component: () => import('@/views/pages/communication/Communication.vue'),
    redirect: { path: '/communication/email-triggers' },
    meta: {
      breadcrumbs: [PAGE_TITLES.COMMUNICATION],
      label: PAGE_TITLES.COMMUNICATION,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.EmailView],
      icon: 'pi pi-fw pi-envelope'
    },
    children: [
      {
        path: 'email-triggers',
        name: ROUTE_NAMES.EMAIL_TRIGGER,
        component: () => import('@/views/pages/communication/EmailTriggers.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.COMMUNICATION, PAGE_TITLES.COMMUNICATION_EMAIL_TRIGGER]
        }
      },
      {
        path: 'email-triggers/+',
        name: ROUTE_NAMES.EMAIL_TRIGGER_NEW,
        component: () => import('@/views/pages/communication/EmailTriggerNew.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.COMMUNICATION,
            PAGE_TITLES.COMMUNICATION_EMAIL_TRIGGER,
            PAGE_TITLES.COMMUNICATION_EMAIL_TRIGGER_NEW
          ]
        }
      },
      {
        path: 'email-triggers/:emailTriggerId',
        name: ROUTE_NAMES.EMAIL_TRIGGER_EDIT,
        component: () => import('@/views/pages/communication/EmailTriggerEdit.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.COMMUNICATION,
            PAGE_TITLES.COMMUNICATION_EMAIL_TRIGGER,
            PAGE_TITLES.COMMUNICATION_EMAIL_TRIGGER_EDIT
          ]
        }
      },
      {
        path: 'email-templates',
        name: ROUTE_NAMES.EMAIL_TEMPLATES,
        component: () => import('@/views/pages/communication/EmailTemplates.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.COMMUNICATION, PAGE_TITLES.COMMUNICATION_EMAIL_TEMPLATES]
        }
      },
      {
        path: 'email-templates/new',
        name: ROUTE_NAMES.EMAIL_TEMPLATE_NEW,
        component: () => import('@/views/pages/communication/EmailTemplateNewPage.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.COMMUNICATION,
            PAGE_TITLES.COMMUNICATION_EMAIL_TEMPLATES,
            PAGE_TITLES.COMMUNICATION_EMAIL_TEMPLATE_NEW
          ]
        }
      },
      {
        path: 'email-templates/:templateId',
        name: ROUTE_NAMES.EMAIL_TEMPLATE_EDIT,
        component: () => import('@/views/pages/communication/EmailTemplateEdit.vue'),
        meta: {
          breadcrumbs: [
            PAGE_TITLES.COMMUNICATION,
            PAGE_TITLES.COMMUNICATION_EMAIL_TEMPLATES,
            PAGE_TITLES.COMMUNICATION_EMAIL_TEMPLATE_EDIT
          ]
        }
      }
    ]
  }
];
