<template>
  <FieldArray v-slot="{ fields }" :name="name">
    <FileUpload
      ref="fu"
      auto
      :show-upload-button="false"
      :show-cancel-button="false"
      name="demo[]"
      custom-upload
      :multiple="true"
      :accept="accept"
      :max-file-size="5000000"
      @uploader="uploader"
    >
      <template #content>
        <div v-if="fields.length > 0">
          <draggable
            v-if="isImageUpload"
            :list="fields"
            item-key="key"
            class="files-validation"
            ghost-class="ghost"
            @end="onEnd"
          >
            <template #item="{ element, index }">
              <div class="files-validation__container">
                <Button
                  icon="pi pi-times"
                  size="small"
                  severity="secondary"
                  rounded
                  aria-label="Remove"
                  class="files-validation__button"
                  @click="onRemove(index)"
                />
                <img
                  class="files-validation__image h-40 w-full object-cover shadow rounded-border"
                  :src="getResizedImage(element.value.id ?? element.value)"
                />
              </div>
            </template>
          </draggable>
          <div v-else>
            <DataTable :value="fields" tableStyle="min-width: 50rem">
              <Column field="value.title" header="Title"></Column>
              <Column field="value.filesize" header="Size"></Column>
              <Column header="Actions">
                <template #body="{ index }">
                  <Button
                    :label="$t('portal_delete')"
                    size="small"
                    severity="secondary"
                    rounded
                    aria-label="Remove"
                    @click="onRemove(index)"
                  />
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </template>
      <template #empty>
        <p v-if="fields.length === 0">{{ $t('portal_file_upload_empty') }}</p>
      </template>
    </FileUpload>
  </FieldArray>
</template>

<script setup lang="ts">
import { FieldArray, useFieldArray } from 'vee-validate';
import draggable from 'vuedraggable';
import { computed, ref, toRefs, watch } from 'vue';
import FileUpload, { type FileUploadUploaderEvent } from 'primevue/fileupload';
import { directus } from '@/service/directus';
import Button from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { getResizedImage } from '@/utils/images';
import { uploadFiles } from '@directus/sdk';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
const props = defineProps({
  autofocus: {
    type: Boolean,
    required: false,
    default: false
  },
  name: {
    type: String,
    required: true
  },
  placeholder: {
    type: String,
    required: false,
    default: ''
  },
  rules: {
    type: String,
    required: false,
    default: ''
  },
  folder: {
    type: String,
    required: true,
    default: '316ec7cc-5d14-4a1e-b7c0-053b93c543dd'
  },
  accept: {
    type: String,
    required: false,
    default: 'image/*'
  }
});
type FileUploadMessages = {
  messages: string[];
};
const fu = ref<FileUploadMessages | null>(null);

const { name, folder, accept } = toRefs(props);
const { remove, push, move } = useFieldArray(name);
const toast = useToast();

const isImageUpload = computed(() => accept.value.includes('image'));

const uploader = async (event: FileUploadUploaderEvent) => {
  const { files } = event;
  if (Array.isArray(files)) {
    for (const fileIndex in files) {
      const form = new FormData();
      form.append('folder', folder.value);
      form.append('files[]', files[fileIndex]);
      const value = await directus.request(uploadFiles(form));
      if (value?.id) {
        if (isImageUpload.value) {
          // For image upload, we only need the id
          push(value.id);
        } else {
          // For file upload, we need the id, title and filesize
          push({
            id: value.id,
            title: value.title,
            filesize: ((Number(value.filesize) || 0) / 1024).toFixed(2) + ' KB'
          });
        }
      }
    }
  }
};

const onRemove = async (idx: number) => {
  remove(idx);
};

const onEnd = (event: any) => {
  const { newIndex, oldIndex } = event;
  move(oldIndex, newIndex);
};
const messages = computed(() => fu.value?.messages || []);

watch(messages, ([newMessage]) => {
  if (newMessage) {
    toast.add({
      severity: 'error',
      summary: 'Error',
      detail: newMessage,
      life: 10000
    });
  }
});
</script>

<style scoped lang="scss">
.files-validation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: 1em;

  &__container {
    position: relative;

    &:hover {
      cursor: move;
      .files-validation__button {
        transform: scale(1);
      }
    }
  }

  &__button {
    transition: transform 0.2s ease-in-out;
    position: absolute;
    top: -1em;
    right: -1em;
    transform: scale(0);
    &:hover {
      cursor: pointer;
    }
  }

  &__image {
    object-fit: cover;
  }
}
</style>
