/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTemplatesCreateResponseEmailTemplateTranslationsInner } from './EmailTemplatesCreateResponseEmailTemplateTranslationsInner';
import {
  EmailTemplatesCreateResponseEmailTemplateTranslationsInnerFromJSON,
  EmailTemplatesCreateResponseEmailTemplateTranslationsInnerFromJSONTyped,
  EmailTemplatesCreateResponseEmailTemplateTranslationsInnerToJSON
} from './EmailTemplatesCreateResponseEmailTemplateTranslationsInner';

/**
 *
 * @export
 * @interface EmailTemplatesReadByIdResponseEmailTemplate
 */
export interface EmailTemplatesReadByIdResponseEmailTemplate {
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadByIdResponseEmailTemplate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadByIdResponseEmailTemplate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadByIdResponseEmailTemplate
   */
  status?: EmailTemplatesReadByIdResponseEmailTemplateStatusEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadByIdResponseEmailTemplate
   */
  created_at?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadByIdResponseEmailTemplate
   */
  partner_id: string;
  /**
   *
   * @type {Array<EmailTemplatesCreateResponseEmailTemplateTranslationsInner>}
   * @memberof EmailTemplatesReadByIdResponseEmailTemplate
   */
  translations?: Array<EmailTemplatesCreateResponseEmailTemplateTranslationsInner>;
}

/**
 * @export
 */
export const EmailTemplatesReadByIdResponseEmailTemplateStatusEnum = {
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type EmailTemplatesReadByIdResponseEmailTemplateStatusEnum =
  (typeof EmailTemplatesReadByIdResponseEmailTemplateStatusEnum)[keyof typeof EmailTemplatesReadByIdResponseEmailTemplateStatusEnum];

/**
 * Check if a given object implements the EmailTemplatesReadByIdResponseEmailTemplate interface.
 */
export function instanceOfEmailTemplatesReadByIdResponseEmailTemplate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'partner_id' in value;

  return isInstance;
}

export function EmailTemplatesReadByIdResponseEmailTemplateFromJSON(
  json: any
): EmailTemplatesReadByIdResponseEmailTemplate {
  return EmailTemplatesReadByIdResponseEmailTemplateFromJSONTyped(json, false);
}

export function EmailTemplatesReadByIdResponseEmailTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTemplatesReadByIdResponseEmailTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    status: !exists(json, 'status') ? undefined : json['status'],
    created_at: !exists(json, 'created_at') ? undefined : json['created_at'],
    partner_id: json['partner_id'],
    translations: !exists(json, 'translations')
      ? undefined
      : (json['translations'] as Array<any>).map(
          EmailTemplatesCreateResponseEmailTemplateTranslationsInnerFromJSON
        )
  };
}

export function EmailTemplatesReadByIdResponseEmailTemplateToJSON(
  value?: EmailTemplatesReadByIdResponseEmailTemplate | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    status: value.status,
    created_at: value.created_at,
    partner_id: value.partner_id,
    translations:
      value.translations === undefined
        ? undefined
        : (value.translations as Array<any>).map(
            EmailTemplatesCreateResponseEmailTemplateTranslationsInnerToJSON
          )
  };
}
