/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTemplateTranslation } from './EmailTemplateTranslation';
import {
  EmailTemplateTranslationFromJSON,
  EmailTemplateTranslationFromJSONTyped,
  EmailTemplateTranslationToJSON
} from './EmailTemplateTranslation';
import type { EmailTriggerProductId } from './EmailTriggerProductId';
import {
  EmailTriggerProductIdFromJSON,
  EmailTriggerProductIdFromJSONTyped,
  EmailTriggerProductIdToJSON
} from './EmailTriggerProductId';
import type { EmailTriggerProductOptionId } from './EmailTriggerProductOptionId';
import {
  EmailTriggerProductOptionIdFromJSON,
  EmailTriggerProductOptionIdFromJSONTyped,
  EmailTriggerProductOptionIdToJSON
} from './EmailTriggerProductOptionId';

/**
 *
 * @export
 * @interface EmailTriggerCreateRequest
 */
export interface EmailTriggerCreateRequest {
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  partner_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  status: EmailTriggerCreateRequestStatusEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  schedule_relative_to: EmailTriggerCreateRequestScheduleRelativeToEnum;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  schedule_offset: string;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  triggers_on: EmailTriggerCreateRequestTriggersOnEnum;
  /**
   *
   * @type {boolean}
   * @memberof EmailTriggerCreateRequest
   */
  send_only_if_uncancelled: boolean;
  /**
   *
   * @type {string}
   * @memberof EmailTriggerCreateRequest
   */
  name: string;
  /**
   *
   * @type {EmailTriggerProductId}
   * @memberof EmailTriggerCreateRequest
   */
  product_id: EmailTriggerProductId;
  /**
   *
   * @type {EmailTriggerProductOptionId}
   * @memberof EmailTriggerCreateRequest
   */
  product_option_id: EmailTriggerProductOptionId;
  /**
   *
   * @type {Array<EmailTemplateTranslation>}
   * @memberof EmailTriggerCreateRequest
   */
  email_template_translations: Array<EmailTemplateTranslation>;
}

/**
 * @export
 */
export const EmailTriggerCreateRequestStatusEnum = {
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type EmailTriggerCreateRequestStatusEnum =
  (typeof EmailTriggerCreateRequestStatusEnum)[keyof typeof EmailTriggerCreateRequestStatusEnum];

/**
 * @export
 */
export const EmailTriggerCreateRequestScheduleRelativeToEnum = {
  PurchaseDateTime: 'purchase_date_time',
  VisitDateTime: 'visit_date_time'
} as const;
export type EmailTriggerCreateRequestScheduleRelativeToEnum =
  (typeof EmailTriggerCreateRequestScheduleRelativeToEnum)[keyof typeof EmailTriggerCreateRequestScheduleRelativeToEnum];

/**
 * @export
 */
export const EmailTriggerCreateRequestTriggersOnEnum = {
  CartConfirmed: 'cart_confirmed'
} as const;
export type EmailTriggerCreateRequestTriggersOnEnum =
  (typeof EmailTriggerCreateRequestTriggersOnEnum)[keyof typeof EmailTriggerCreateRequestTriggersOnEnum];

/**
 * Check if a given object implements the EmailTriggerCreateRequest interface.
 */
export function instanceOfEmailTriggerCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'schedule_relative_to' in value;
  isInstance = isInstance && 'schedule_offset' in value;
  isInstance = isInstance && 'triggers_on' in value;
  isInstance = isInstance && 'send_only_if_uncancelled' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'product_id' in value;
  isInstance = isInstance && 'product_option_id' in value;
  isInstance = isInstance && 'email_template_translations' in value;

  return isInstance;
}

export function EmailTriggerCreateRequestFromJSON(json: any): EmailTriggerCreateRequest {
  return EmailTriggerCreateRequestFromJSONTyped(json, false);
}

export function EmailTriggerCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTriggerCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    status: json['status'],
    schedule_relative_to: json['schedule_relative_to'],
    schedule_offset: json['schedule_offset'],
    triggers_on: json['triggers_on'],
    send_only_if_uncancelled: json['send_only_if_uncancelled'],
    name: json['name'],
    product_id: EmailTriggerProductIdFromJSON(json['product_id']),
    product_option_id: EmailTriggerProductOptionIdFromJSON(json['product_option_id']),
    email_template_translations: (json['email_template_translations'] as Array<any>).map(
      EmailTemplateTranslationFromJSON
    )
  };
}

export function EmailTriggerCreateRequestToJSON(value?: EmailTriggerCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    status: value.status,
    schedule_relative_to: value.schedule_relative_to,
    schedule_offset: value.schedule_offset,
    triggers_on: value.triggers_on,
    send_only_if_uncancelled: value.send_only_if_uncancelled,
    name: value.name,
    product_id: EmailTriggerProductIdToJSON(value.product_id),
    product_option_id: EmailTriggerProductOptionIdToJSON(value.product_option_id),
    email_template_translations: (value.email_template_translations as Array<any>).map(
      EmailTemplateTranslationToJSON
    )
  };
}
