/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CustomersReadResponseMeta } from './CustomersReadResponseMeta';
import {
  CustomersReadResponseMetaFromJSON,
  CustomersReadResponseMetaFromJSONTyped,
  CustomersReadResponseMetaToJSON
} from './CustomersReadResponseMeta';
import type { PayoutsDataTableReadResponsePayoutsInner } from './PayoutsDataTableReadResponsePayoutsInner';
import {
  PayoutsDataTableReadResponsePayoutsInnerFromJSON,
  PayoutsDataTableReadResponsePayoutsInnerFromJSONTyped,
  PayoutsDataTableReadResponsePayoutsInnerToJSON
} from './PayoutsDataTableReadResponsePayoutsInner';

/**
 *
 * @export
 * @interface PayoutsDataTableReadResponse
 */
export interface PayoutsDataTableReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PayoutsDataTableReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PayoutsDataTableReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutsDataTableReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutsDataTableReadResponse
   */
  error_code?: PayoutsDataTableReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof PayoutsDataTableReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {CustomersReadResponseMeta}
   * @memberof PayoutsDataTableReadResponse
   */
  meta: CustomersReadResponseMeta;
  /**
   *
   * @type {Array<PayoutsDataTableReadResponsePayoutsInner>}
   * @memberof PayoutsDataTableReadResponse
   */
  payouts: Array<PayoutsDataTableReadResponsePayoutsInner>;
}

/**
 * @export
 */
export const PayoutsDataTableReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type PayoutsDataTableReadResponseErrorCodeEnum =
  (typeof PayoutsDataTableReadResponseErrorCodeEnum)[keyof typeof PayoutsDataTableReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the PayoutsDataTableReadResponse interface.
 */
export function instanceOfPayoutsDataTableReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'payouts' in value;

  return isInstance;
}

export function PayoutsDataTableReadResponseFromJSON(json: any): PayoutsDataTableReadResponse {
  return PayoutsDataTableReadResponseFromJSONTyped(json, false);
}

export function PayoutsDataTableReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutsDataTableReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    meta: CustomersReadResponseMetaFromJSON(json['meta']),
    payouts: (json['payouts'] as Array<any>).map(PayoutsDataTableReadResponsePayoutsInnerFromJSON)
  };
}

export function PayoutsDataTableReadResponseToJSON(
  value?: PayoutsDataTableReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    meta: CustomersReadResponseMetaToJSON(value.meta),
    payouts: (value.payouts as Array<any>).map(PayoutsDataTableReadResponsePayoutsInnerToJSON)
  };
}
