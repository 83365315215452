/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface InitializationReadResponseUser
 */
export interface InitializationReadResponseUser {
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponseUser
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponseUser
   */
  first_name: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponseUser
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponseUser
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponseUser
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof InitializationReadResponseUser
   */
  role: string;
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponseUser
   */
  isReseller: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponseUser
   */
  isPartnerAdmin: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponseUser
   */
  isPartnerOperations: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponseUser
   */
  isPartnerScanning: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponseUser
   */
  isPartnerFrontDesk: boolean;
  /**
   *
   * @type {boolean}
   * @memberof InitializationReadResponseUser
   */
  isPartnerFinance: boolean;
}

/**
 * Check if a given object implements the InitializationReadResponseUser interface.
 */
export function instanceOfInitializationReadResponseUser(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'first_name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'role' in value;
  isInstance = isInstance && 'isReseller' in value;
  isInstance = isInstance && 'isPartnerAdmin' in value;
  isInstance = isInstance && 'isPartnerOperations' in value;
  isInstance = isInstance && 'isPartnerScanning' in value;
  isInstance = isInstance && 'isPartnerFrontDesk' in value;
  isInstance = isInstance && 'isPartnerFinance' in value;

  return isInstance;
}

export function InitializationReadResponseUserFromJSON(json: any): InitializationReadResponseUser {
  return InitializationReadResponseUserFromJSONTyped(json, false);
}

export function InitializationReadResponseUserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): InitializationReadResponseUser {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    first_name: json['first_name'],
    last_name: json['last_name'],
    locale_code: json['locale_code'],
    email: json['email'],
    role: json['role'],
    isReseller: json['isReseller'],
    isPartnerAdmin: json['isPartnerAdmin'],
    isPartnerOperations: json['isPartnerOperations'],
    isPartnerScanning: json['isPartnerScanning'],
    isPartnerFrontDesk: json['isPartnerFrontDesk'],
    isPartnerFinance: json['isPartnerFinance']
  };
}

export function InitializationReadResponseUserToJSON(
  value?: InitializationReadResponseUser | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    first_name: value.first_name,
    last_name: value.last_name,
    locale_code: value.locale_code,
    email: value.email,
    role: value.role,
    isReseller: value.isReseller,
    isPartnerAdmin: value.isPartnerAdmin,
    isPartnerOperations: value.isPartnerOperations,
    isPartnerScanning: value.isPartnerScanning,
    isPartnerFrontDesk: value.isPartnerFrontDesk,
    isPartnerFinance: value.isPartnerFinance
  };
}
