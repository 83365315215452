// Simple logging implementation
export const Logger = {
  captureMessage: (message: string, options?: any) => {
    console.log('[Log Message]:', message, options || '');
  },

  captureException: (error: unknown, options?: any) => {
    if (error instanceof Error) {
      console.error('[Log Exception]:', error.message, error.stack, options || '');
    } else {
      console.error('[Log Exception]:', error, options || '');
    }
  }
};

export default Logger;
