import usePermissions from '@/composables/use-permissions';
import { useUserFeedback } from '@/composables/user-feedback.ts';
import { DEFAULT_LOCALE } from '@/constants/i18n';
import i18n, { fetchPortalTranslations } from '@/i18n';
import { authApi } from '@/service/TicketingApi';
import { useCurrencyStore } from '@/stores/currencies';
import { isGygAdminEmail } from '@/utils/is-gyg-staff';
import type {
  InitializationReadResponsePartnersInner,
  UserSettingsUpdateRequest
} from '@openapi/models';
import { datadogRum } from '@datadog/browser-rum';
import { Logger } from '@/logger';
import { defineStore } from 'pinia';
import posthog from 'posthog-js';
import { useLocaleStore } from './locales';
import type { InitializationReadResponseUser } from '@openapi/models';
export type UserSettings = Omit<UserSettingsUpdateRequest, 'locale_code'>;

type RoleMetadata = Pick<
  InitializationReadResponseUser,
  | 'role'
  | 'isReseller'
  | 'isPartnerAdmin'
  | 'isPartnerOperations'
  | 'isPartnerScanning'
  | 'isPartnerFrontDesk'
  | 'isPartnerFinance'
>;

interface UserStoreState {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  roleMetadata: RoleMetadata;
  impersonatePartner: string | undefined | null;

  partners: Array<InitializationReadResponsePartnersInner>;
  selectedPartnerId: InitializationReadResponsePartnersInner['id'];
  selectedResellerId: InitializationReadResponsePartnersInner['id'];
}

const { awaitPromiseWithErrorHandling } = useUserFeedback();
export const useUserStore = defineStore('UserStore', {
  state: (): UserStoreState => {
    return {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      language: '',
      roleMetadata: {
        role: '',
        isReseller: false,
        isPartnerAdmin: false,
        isPartnerOperations: false,
        isPartnerScanning: false,
        isPartnerFrontDesk: false,
        isPartnerFinance: false
      },
      impersonatePartner: null,
      partners: [],
      selectedPartnerId: '',
      selectedResellerId: ''
    };
  },

  getters: {
    getUser: (state: UserStoreState) => {
      return state;
    },
    getName: (state: UserStoreState) => {
      return `${state.first_name} ${state.last_name}`;
    },
    getFirstName: (state: UserStoreState) => {
      return state.first_name;
    },
    getLastName: (state: UserStoreState) => {
      return state.last_name;
    },
    getLanguage: (state: UserStoreState) => {
      return state.language || DEFAULT_LOCALE;
    },
    getImpersonatePartnerName: (state: UserStoreState) => {
      return state.impersonatePartner;
    },
    isGygAdminUser: (state: UserStoreState) => {
      return isGygAdminEmail(state.email);
    },
    getPartners: (state: UserStoreState) => {
      return state.partners;
    },
    getSelectedPartner: (state: UserStoreState) => {
      return state.partners.find((partner) => partner.id === state.selectedPartnerId);
    },
    getAllowPartnerSelection: (state: UserStoreState) => {
      return state.partners.length > 1;
    },
    getActivePartnerIdSafe: (state: UserStoreState): string | null => {
      return state.selectedPartnerId || null;
    },
    getActivePartnerId: (state: UserStoreState) => {
      if (!state.selectedPartnerId) {
        throw new Error('Partner id is not set');
      }
      return state.selectedPartnerId;
    },
    isReseller: (state: UserStoreState) => {
      return state.roleMetadata.isReseller;
    },
    isPartnerAdmin: (state: UserStoreState) => {
      return state.roleMetadata.isPartnerAdmin;
    },
    isPartnerOperations: (state: UserStoreState) => {
      return state.roleMetadata.isPartnerOperations;
    },
    isPartnerScanning: (state: UserStoreState) => {
      return state.roleMetadata.isPartnerScanning;
    },
    isPartnerFrontDesk: (state: UserStoreState) => {
      return state.roleMetadata.isPartnerFrontDesk;
    },
    isPartnerFinance: (state: UserStoreState) => {
      return state.roleMetadata.isPartnerFinance;
    },
    getActiveResellerId: (state: UserStoreState) => {
      if (!state.selectedResellerId) {
        throw new Error('Reseller id is not set');
      }
      return state.selectedResellerId;
    }
  },
  actions: {
    setImpersonatePartnerName(name: string | null) {
      this.impersonatePartner = name;
    },
    async setActivePartnerId(id: InitializationReadResponsePartnersInner['id']) {
      // If we don't have partners loaded yet, we need to fetch them
      if (this.partners.length === 0) {
        await this.fetchUser();
      }

      // Check if the partner id is one of the available partners
      if (this.partners.some((partner) => partner.id === id)) {
        this.selectedPartnerId = id;
      } else {
        this.selectedPartnerId = '';
        Logger.captureException(
          new Error(
            `Partner id ${id} is not one of the available partners. 
                    
            Available partners: 
            ${this.partners.map((partner) => partner.id).join(', ')}`
          ),
          {
            level: 'info'
          }
        );
      }
    },
    async fetchUser() {
      // Call initialization endpoint
      const { setPermissions } = usePermissions();
      const { setLocales, setSelected: setSelectedLocale, getSelectedLocale } = useLocaleStore();
      const { setSelected: setPartnerCurrency, setNumFractionDigits } = useCurrencyStore();
      const { user, partner, locales, permissions, partners, resellers } =
        await authApi.authInitializationGet({
          localeCode: getSelectedLocale
        });

      setPermissions(permissions);
      setLocales(locales);
      setSelectedLocale(user.locale_code);

      // Need to rethink this when we have multiple partners
      setPartnerCurrency(partner.currency.code);
      setNumFractionDigits(partner.currency.num_fraction_digits);

      await fetchPortalTranslations();

      this.id = user.id;
      this.first_name = user.first_name;
      this.last_name = user.last_name;
      this.email = user.email;
      this.language = user.locale_code;
      this.roleMetadata = {
        role: user.role,
        isReseller: user.isReseller,
        isPartnerAdmin: user.isPartnerAdmin,
        isPartnerOperations: user.isPartnerOperations,
        isPartnerScanning: user.isPartnerScanning,
        isPartnerFrontDesk: user.isPartnerFrontDesk,
        isPartnerFinance: user.isPartnerFinance
      };

      this.partners = partners;

      if (!this.selectedPartnerId && partners.length === 1) {
        this.selectedPartnerId = partners[0].id;
      }

      if (!this.selectedResellerId && resellers.length === 1) {
        this.selectedResellerId = resellers[0].id;
      }

      // Set i18n locale for vee-validate
      i18n.global.locale.value = user.locale_code;

      posthog.setPersonPropertiesForFlags({ email: user.email });
      posthog.identify(
        user.id,
        {
          first_name: user.first_name,
          last_name: user.last_name,
          language: user.locale_code,
          email: user.email,
          role: user.role
        },
        {}
      );
      if (user) {
        datadogRum.setUser({ id: user.id, first_name: user.first_name, last_name: user.last_name });
      }
    },
    async updateUserSettings(userSettings: UserSettings) {
      const { getSelectedLocale } = useLocaleStore();
      const authUserSettingsPostRequest = {
        userSettingsUpdateRequest: {
          first_name: userSettings.first_name,
          last_name: userSettings.last_name,
          language: userSettings.language,
          locale_code: getSelectedLocale
        }
      };
      const response = await awaitPromiseWithErrorHandling(
        authApi.authUserSettingsPost(authUserSettingsPostRequest),
        true
      );
      if (!response.success) {
        throw new Error('Error updating user settings');
      }

      await this.fetchUser();
      return response;
    }
  }
});
