/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type {
  AiAnalystReadRequest,
  AiAnalystReadResponse,
  BusinessInformationReadResponse,
  BusinessInformationUpdateRequest,
  BusinessInformationUpdateResponse,
  CancellationPolicyCreateRequest,
  CancellationPolicyCreateResponse,
  CancellationPolicyDeleteRequest,
  CancellationPolicyDeleteResponse,
  CancellationPolicyReadByIdRequest,
  CancellationPolicyReadByIdResponse,
  CancellationPolicyReadRequest,
  CancellationPolicyReadResponse,
  CancellationPolicyUpdateRequest,
  CancellationPolicyUpdateResponse,
  CredentialsUpsertRequest,
  CredentialsUpsertResponse,
  CustomizationSettingsReadRequest,
  CustomizationSettingsReadResponse,
  CustomizationSettingsUpdateRequest,
  CustomizationSettingsUpdateResponse,
  DataTableFiltersCreateRequest,
  DataTableFiltersCreateResponse,
  DatatableFiltersDeleteRequest,
  DatatableFiltersDeleteResponse,
  EmailReadResponse,
  EmailUpdateRequest,
  EmailUpdateResponse,
  IntegrationCreateRequest,
  IntegrationCreateResponse,
  IntegrationReadResponse,
  IntegrationUpdateRequest,
  IntegrationsReadResponse,
  NewsletterSettingsReadRequest,
  NewsletterSettingsReadResponse,
  NewsletterSettingsUpdateRequest,
  NewsletterSettingsUpdateResponse,
  OnboardingPaypalReadResponse,
  OnboardingPaypalStartRequest,
  OnboardingPaypalStartResponse,
  OnboardingReadResponse,
  OnboardingStartRequest,
  OnboardingStartResponse,
  ReceiptSettingsReadResponse,
  ReceiptSettingsUpdateRequest,
  ReceiptSettingsUpdateResponse,
  ResellerCreateRequest,
  ResellerCreateResponse,
  ResellerDeleteRequest,
  ResellerDeleteResponse,
  ResellerReadByIdResponse,
  ResellerReadRequest,
  ResellerReadResponse,
  ResellerUpdateRequest,
  ResellerUpdateResponse,
  SupportedCountriesReadResponse,
  TranslateCreateRequest,
  TranslateCreateResponse,
  TranslationsReadResponse,
  UserByIdReadResponse,
  UserInviteRequest,
  UserInviteResponse,
  UserUpdateRequest,
  UserUpdateResponse,
  UsersReadRequest,
  UsersReadResponse
} from '../models';
import {
  AiAnalystReadRequestFromJSON,
  AiAnalystReadRequestToJSON,
  AiAnalystReadResponseFromJSON,
  AiAnalystReadResponseToJSON,
  BusinessInformationReadResponseFromJSON,
  BusinessInformationReadResponseToJSON,
  BusinessInformationUpdateRequestFromJSON,
  BusinessInformationUpdateRequestToJSON,
  BusinessInformationUpdateResponseFromJSON,
  BusinessInformationUpdateResponseToJSON,
  CancellationPolicyCreateRequestFromJSON,
  CancellationPolicyCreateRequestToJSON,
  CancellationPolicyCreateResponseFromJSON,
  CancellationPolicyCreateResponseToJSON,
  CancellationPolicyDeleteRequestFromJSON,
  CancellationPolicyDeleteRequestToJSON,
  CancellationPolicyDeleteResponseFromJSON,
  CancellationPolicyDeleteResponseToJSON,
  CancellationPolicyReadByIdRequestFromJSON,
  CancellationPolicyReadByIdRequestToJSON,
  CancellationPolicyReadByIdResponseFromJSON,
  CancellationPolicyReadByIdResponseToJSON,
  CancellationPolicyReadRequestFromJSON,
  CancellationPolicyReadRequestToJSON,
  CancellationPolicyReadResponseFromJSON,
  CancellationPolicyReadResponseToJSON,
  CancellationPolicyUpdateRequestFromJSON,
  CancellationPolicyUpdateRequestToJSON,
  CancellationPolicyUpdateResponseFromJSON,
  CancellationPolicyUpdateResponseToJSON,
  CredentialsUpsertRequestFromJSON,
  CredentialsUpsertRequestToJSON,
  CredentialsUpsertResponseFromJSON,
  CredentialsUpsertResponseToJSON,
  CustomizationSettingsReadRequestFromJSON,
  CustomizationSettingsReadRequestToJSON,
  CustomizationSettingsReadResponseFromJSON,
  CustomizationSettingsReadResponseToJSON,
  CustomizationSettingsUpdateRequestFromJSON,
  CustomizationSettingsUpdateRequestToJSON,
  CustomizationSettingsUpdateResponseFromJSON,
  CustomizationSettingsUpdateResponseToJSON,
  DataTableFiltersCreateRequestFromJSON,
  DataTableFiltersCreateRequestToJSON,
  DataTableFiltersCreateResponseFromJSON,
  DataTableFiltersCreateResponseToJSON,
  DatatableFiltersDeleteRequestFromJSON,
  DatatableFiltersDeleteRequestToJSON,
  DatatableFiltersDeleteResponseFromJSON,
  DatatableFiltersDeleteResponseToJSON,
  EmailReadResponseFromJSON,
  EmailReadResponseToJSON,
  EmailUpdateRequestFromJSON,
  EmailUpdateRequestToJSON,
  EmailUpdateResponseFromJSON,
  EmailUpdateResponseToJSON,
  IntegrationCreateRequestFromJSON,
  IntegrationCreateRequestToJSON,
  IntegrationCreateResponseFromJSON,
  IntegrationCreateResponseToJSON,
  IntegrationReadResponseFromJSON,
  IntegrationReadResponseToJSON,
  IntegrationUpdateRequestFromJSON,
  IntegrationUpdateRequestToJSON,
  IntegrationsReadResponseFromJSON,
  IntegrationsReadResponseToJSON,
  NewsletterSettingsReadRequestFromJSON,
  NewsletterSettingsReadRequestToJSON,
  NewsletterSettingsReadResponseFromJSON,
  NewsletterSettingsReadResponseToJSON,
  NewsletterSettingsUpdateRequestFromJSON,
  NewsletterSettingsUpdateRequestToJSON,
  NewsletterSettingsUpdateResponseFromJSON,
  NewsletterSettingsUpdateResponseToJSON,
  OnboardingPaypalReadResponseFromJSON,
  OnboardingPaypalReadResponseToJSON,
  OnboardingPaypalStartRequestFromJSON,
  OnboardingPaypalStartRequestToJSON,
  OnboardingPaypalStartResponseFromJSON,
  OnboardingPaypalStartResponseToJSON,
  OnboardingReadResponseFromJSON,
  OnboardingReadResponseToJSON,
  OnboardingStartRequestFromJSON,
  OnboardingStartRequestToJSON,
  OnboardingStartResponseFromJSON,
  OnboardingStartResponseToJSON,
  ReceiptSettingsReadResponseFromJSON,
  ReceiptSettingsReadResponseToJSON,
  ReceiptSettingsUpdateRequestFromJSON,
  ReceiptSettingsUpdateRequestToJSON,
  ReceiptSettingsUpdateResponseFromJSON,
  ReceiptSettingsUpdateResponseToJSON,
  ResellerCreateRequestFromJSON,
  ResellerCreateRequestToJSON,
  ResellerCreateResponseFromJSON,
  ResellerCreateResponseToJSON,
  ResellerDeleteRequestFromJSON,
  ResellerDeleteRequestToJSON,
  ResellerDeleteResponseFromJSON,
  ResellerDeleteResponseToJSON,
  ResellerReadByIdResponseFromJSON,
  ResellerReadByIdResponseToJSON,
  ResellerReadRequestFromJSON,
  ResellerReadRequestToJSON,
  ResellerReadResponseFromJSON,
  ResellerReadResponseToJSON,
  ResellerUpdateRequestFromJSON,
  ResellerUpdateRequestToJSON,
  ResellerUpdateResponseFromJSON,
  ResellerUpdateResponseToJSON,
  SupportedCountriesReadResponseFromJSON,
  SupportedCountriesReadResponseToJSON,
  TranslateCreateRequestFromJSON,
  TranslateCreateRequestToJSON,
  TranslateCreateResponseFromJSON,
  TranslateCreateResponseToJSON,
  TranslationsReadResponseFromJSON,
  TranslationsReadResponseToJSON,
  UserByIdReadResponseFromJSON,
  UserByIdReadResponseToJSON,
  UserInviteRequestFromJSON,
  UserInviteRequestToJSON,
  UserInviteResponseFromJSON,
  UserInviteResponseToJSON,
  UserUpdateRequestFromJSON,
  UserUpdateRequestToJSON,
  UserUpdateResponseFromJSON,
  UserUpdateResponseToJSON,
  UsersReadRequestFromJSON,
  UsersReadRequestToJSON,
  UsersReadResponseFromJSON,
  UsersReadResponseToJSON
} from '../models';

export interface ManageAiAnalystPostRequest {
  aiAnalystReadRequest?: AiAnalystReadRequest;
}

export interface ManageBusinessInformationGetRequest {
  partnerId?: string;
}

export interface ManageBusinessInformationPatchRequest {
  businessInformationUpdateRequest?: BusinessInformationUpdateRequest;
}

export interface ManageCancellationPolicyCancellationPolicyIdGetRequest {
  cancellationPolicyId: string;
  cancellationPolicyReadByIdRequest?: CancellationPolicyReadByIdRequest;
}

export interface ManageCancellationPolicyDeleteRequest {
  cancellationPolicyDeleteRequest?: CancellationPolicyDeleteRequest;
}

export interface ManageCancellationPolicyPostRequest {
  cancellationPolicyCreateRequest?: CancellationPolicyCreateRequest;
}

export interface ManageCancellationPolicyPutRequest {
  cancellationPolicyUpdateRequest?: CancellationPolicyUpdateRequest;
}

export interface ManageCustomizationSettingsGetRequest {
  partnerId?: string;
  customizationSettingsReadRequest?: CustomizationSettingsReadRequest;
}

export interface ManageCustomizationSettingsPostRequest {
  customizationSettingsUpdateRequest?: CustomizationSettingsUpdateRequest;
}

export interface ManageDatatableFiltersDatatableIdPostRequest {
  datatableId: string;
  dataTableFiltersCreateRequest?: DataTableFiltersCreateRequest;
}

export interface ManageDatatableFiltersIdDeleteRequest {
  id: string;
  datatableFiltersDeleteRequest?: DatatableFiltersDeleteRequest;
}

export interface ManageEmailPostRequest {
  emailUpdateRequest?: EmailUpdateRequest;
}

export interface ManageIntegrationIntegrationIdGetRequest {
  localeCode: string;
  integrationId: string;
  partnerId?: string;
}

export interface ManageIntegrationIntegrationIdPutRequest {
  integrationId: string;
  integrationUpdateRequest?: IntegrationUpdateRequest;
}

export interface ManageIntegrationPostRequest {
  integrationCreateRequest?: IntegrationCreateRequest;
}

export interface ManageIntegrationsGetRequest {
  partnerId?: string;
}

export interface ManageNewsletterSettingsGetRequest {
  partnerId?: string;
  newsletterSettingsReadRequest?: NewsletterSettingsReadRequest;
}

export interface ManageNewsletterSettingsPostRequest {
  newsletterSettingsUpdateRequest?: NewsletterSettingsUpdateRequest;
}

export interface ManageOnboardingGetRequest {
  partnerId?: string;
}

export interface ManageOnboardingPaypalPostRequest {
  onboardingPaypalStartRequest?: OnboardingPaypalStartRequest;
}

export interface ManageOnboardingPostRequest {
  onboardingStartRequest?: OnboardingStartRequest;
}

export interface ManagePageCancellationPolicyPostRequest {
  partnerId?: string;
  cancellationPolicyReadRequest?: CancellationPolicyReadRequest;
}

export interface ManageReceiptSettingsGetRequest {
  partnerId?: string;
}

export interface ManageReceiptSettingsPostRequest {
  receiptSettingsUpdateRequest?: ReceiptSettingsUpdateRequest;
}

export interface ManageResellerPostRequest {
  resellerCreateRequest?: ResellerCreateRequest;
}

export interface ManageResellersIdDeleteRequest {
  id: string;
  resellerDeleteRequest: ResellerDeleteRequest;
}

export interface ManageResellersIdGetRequest {
  id: string;
}

export interface ManageResellersIdPatchRequest {
  id: string;
  resellerUpdateRequest?: ResellerUpdateRequest;
}

export interface ManageResellersPostRequest {
  resellerReadRequest?: ResellerReadRequest;
}

export interface ManageTaxConnectorPostRequest {
  credentialsUpsertRequest?: CredentialsUpsertRequest;
}

export interface ManageTranslatePostRequest {
  translateCreateRequest?: TranslateCreateRequest;
}

export interface ManageTranslationsGetRequest {
  localeCode: string;
}

export interface ManageUserInvitePostRequest {
  userInviteRequest?: UserInviteRequest;
}

export interface ManageUserUserIdGetRequest {
  userId: string;
  localeCode?: string;
  partnerId?: string;
}

export interface ManageUserUserIdPutRequest {
  userId: string;
  userUpdateRequest?: UserUpdateRequest;
}

export interface ManageUsersPostRequest {
  usersReadRequest?: UsersReadRequest;
}

/**
 *
 */
export class ManageApi extends runtime.BaseAPI {
  /**
   * Summary
   */
  async manageAiAnalystPostRaw(
    requestParameters: ManageAiAnalystPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<AiAnalystReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/ai-analyst`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AiAnalystReadRequestToJSON(requestParameters.aiAnalystReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      AiAnalystReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Summary
   */
  async manageAiAnalystPost(
    requestParameters: ManageAiAnalystPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<AiAnalystReadResponse> {
    const response = await this.manageAiAnalystPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get partner business information
   */
  async manageBusinessInformationGetRaw(
    requestParameters: ManageBusinessInformationGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BusinessInformationReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/business-information`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BusinessInformationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get partner business information
   */
  async manageBusinessInformationGet(
    requestParameters: ManageBusinessInformationGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BusinessInformationReadResponse> {
    const response = await this.manageBusinessInformationGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update business information for a partner
   */
  async manageBusinessInformationPatchRaw(
    requestParameters: ManageBusinessInformationPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<BusinessInformationUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/business-information`,
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: BusinessInformationUpdateRequestToJSON(
          requestParameters.businessInformationUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      BusinessInformationUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update business information for a partner
   */
  async manageBusinessInformationPatch(
    requestParameters: ManageBusinessInformationPatchRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<BusinessInformationUpdateResponse> {
    const response = await this.manageBusinessInformationPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Cancellation Policy by ID
   */
  async manageCancellationPolicyCancellationPolicyIdGetRaw(
    requestParameters: ManageCancellationPolicyCancellationPolicyIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CancellationPolicyReadByIdResponse>> {
    if (
      requestParameters.cancellationPolicyId === null ||
      requestParameters.cancellationPolicyId === undefined
    ) {
      throw new runtime.RequiredError(
        'cancellationPolicyId',
        'Required parameter requestParameters.cancellationPolicyId was null or undefined when calling manageCancellationPolicyCancellationPolicyIdGet.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/cancellation-policy/{cancellation_policy_id}`.replace(
          `{${'cancellation_policy_id'}}`,
          encodeURIComponent(String(requestParameters.cancellationPolicyId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
        body: CancellationPolicyReadByIdRequestToJSON(
          requestParameters.cancellationPolicyReadByIdRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CancellationPolicyReadByIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Cancellation Policy by ID
   */
  async manageCancellationPolicyCancellationPolicyIdGet(
    requestParameters: ManageCancellationPolicyCancellationPolicyIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CancellationPolicyReadByIdResponse> {
    const response = await this.manageCancellationPolicyCancellationPolicyIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete a cancellation policy
   */
  async manageCancellationPolicyDeleteRaw(
    requestParameters: ManageCancellationPolicyDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CancellationPolicyDeleteResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/cancellation-policy`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: CancellationPolicyDeleteRequestToJSON(
          requestParameters.cancellationPolicyDeleteRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CancellationPolicyDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete a cancellation policy
   */
  async manageCancellationPolicyDelete(
    requestParameters: ManageCancellationPolicyDeleteRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CancellationPolicyDeleteResponse> {
    const response = await this.manageCancellationPolicyDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create a cancellation policy
   */
  async manageCancellationPolicyPostRaw(
    requestParameters: ManageCancellationPolicyPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CancellationPolicyCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/cancellation-policy`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CancellationPolicyCreateRequestToJSON(
          requestParameters.cancellationPolicyCreateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CancellationPolicyCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a cancellation policy
   */
  async manageCancellationPolicyPost(
    requestParameters: ManageCancellationPolicyPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CancellationPolicyCreateResponse> {
    const response = await this.manageCancellationPolicyPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Summary
   */
  async manageCancellationPolicyPutRaw(
    requestParameters: ManageCancellationPolicyPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CancellationPolicyUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/cancellation-policy`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: CancellationPolicyUpdateRequestToJSON(
          requestParameters.cancellationPolicyUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CancellationPolicyUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Summary
   */
  async manageCancellationPolicyPut(
    requestParameters: ManageCancellationPolicyPutRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CancellationPolicyUpdateResponse> {
    const response = await this.manageCancellationPolicyPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get customization settings
   */
  async manageCustomizationSettingsGetRaw(
    requestParameters: ManageCustomizationSettingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomizationSettingsReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/customization-settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
        body: CustomizationSettingsReadRequestToJSON(
          requestParameters.customizationSettingsReadRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CustomizationSettingsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get customization settings
   */
  async manageCustomizationSettingsGet(
    requestParameters: ManageCustomizationSettingsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomizationSettingsReadResponse> {
    const response = await this.manageCustomizationSettingsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update customization settings
   */
  async manageCustomizationSettingsPostRaw(
    requestParameters: ManageCustomizationSettingsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CustomizationSettingsUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/customization-settings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CustomizationSettingsUpdateRequestToJSON(
          requestParameters.customizationSettingsUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CustomizationSettingsUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update customization settings
   */
  async manageCustomizationSettingsPost(
    requestParameters: ManageCustomizationSettingsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CustomizationSettingsUpdateResponse> {
    const response = await this.manageCustomizationSettingsPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a new DataTable Filters
   */
  async manageDatatableFiltersDatatableIdPostRaw(
    requestParameters: ManageDatatableFiltersDatatableIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DataTableFiltersCreateResponse>> {
    if (requestParameters.datatableId === null || requestParameters.datatableId === undefined) {
      throw new runtime.RequiredError(
        'datatableId',
        'Required parameter requestParameters.datatableId was null or undefined when calling manageDatatableFiltersDatatableIdPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/datatable-filters/{datatable_id}`.replace(
          `{${'datatable_id'}}`,
          encodeURIComponent(String(requestParameters.datatableId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: DataTableFiltersCreateRequestToJSON(requestParameters.dataTableFiltersCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DataTableFiltersCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a new DataTable Filters
   */
  async manageDatatableFiltersDatatableIdPost(
    requestParameters: ManageDatatableFiltersDatatableIdPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DataTableFiltersCreateResponse> {
    const response = await this.manageDatatableFiltersDatatableIdPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Delete datatable filter id
   */
  async manageDatatableFiltersIdDeleteRaw(
    requestParameters: ManageDatatableFiltersIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<DatatableFiltersDeleteResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageDatatableFiltersIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/datatable-filters/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: DatatableFiltersDeleteRequestToJSON(requestParameters.datatableFiltersDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      DatatableFiltersDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete datatable filter id
   */
  async manageDatatableFiltersIdDelete(
    requestParameters: ManageDatatableFiltersIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<DatatableFiltersDeleteResponse> {
    const response = await this.manageDatatableFiltersIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get Email Settings
   */
  async manageEmailGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/email`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Email Settings
   */
  async manageEmailGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailReadResponse> {
    const response = await this.manageEmailGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Update email settings
   */
  async manageEmailPostRaw(
    requestParameters: ManageEmailPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<EmailUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/email`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: EmailUpdateRequestToJSON(requestParameters.emailUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      EmailUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update email settings
   */
  async manageEmailPost(
    requestParameters: ManageEmailPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<EmailUpdateResponse> {
    const response = await this.manageEmailPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get integration by ID
   */
  async manageIntegrationIntegrationIdGetRaw(
    requestParameters: ManageIntegrationIntegrationIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IntegrationReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling manageIntegrationIntegrationIdGet.'
      );
    }

    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
      throw new runtime.RequiredError(
        'integrationId',
        'Required parameter requestParameters.integrationId was null or undefined when calling manageIntegrationIntegrationIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/integration/{integration_id}`.replace(
          `{${'integration_id'}}`,
          encodeURIComponent(String(requestParameters.integrationId))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IntegrationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get integration by ID
   */
  async manageIntegrationIntegrationIdGet(
    requestParameters: ManageIntegrationIntegrationIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IntegrationReadResponse> {
    const response = await this.manageIntegrationIntegrationIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update integration by ID
   */
  async manageIntegrationIntegrationIdPutRaw(
    requestParameters: ManageIntegrationIntegrationIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IntegrationReadResponse>> {
    if (requestParameters.integrationId === null || requestParameters.integrationId === undefined) {
      throw new runtime.RequiredError(
        'integrationId',
        'Required parameter requestParameters.integrationId was null or undefined when calling manageIntegrationIntegrationIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/integration/{integration_id}`.replace(
          `{${'integration_id'}}`,
          encodeURIComponent(String(requestParameters.integrationId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: IntegrationUpdateRequestToJSON(requestParameters.integrationUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IntegrationReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update integration by ID
   */
  async manageIntegrationIntegrationIdPut(
    requestParameters: ManageIntegrationIntegrationIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IntegrationReadResponse> {
    const response = await this.manageIntegrationIntegrationIdPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Create a new integration
   */
  async manageIntegrationPostRaw(
    requestParameters: ManageIntegrationPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IntegrationCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/integration`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: IntegrationCreateRequestToJSON(requestParameters.integrationCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IntegrationCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a new integration
   */
  async manageIntegrationPost(
    requestParameters: ManageIntegrationPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IntegrationCreateResponse> {
    const response = await this.manageIntegrationPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get integrations
   */
  async manageIntegrationsGetRaw(
    requestParameters: ManageIntegrationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<IntegrationsReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/integrations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      IntegrationsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get integrations
   */
  async manageIntegrationsGet(
    requestParameters: ManageIntegrationsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<IntegrationsReadResponse> {
    const response = await this.manageIntegrationsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get newsletter settings
   */
  async manageNewsletterSettingsGetRaw(
    requestParameters: ManageNewsletterSettingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<NewsletterSettingsReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/newsletter-settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
        body: NewsletterSettingsReadRequestToJSON(requestParameters.newsletterSettingsReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NewsletterSettingsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get newsletter settings
   */
  async manageNewsletterSettingsGet(
    requestParameters: ManageNewsletterSettingsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<NewsletterSettingsReadResponse> {
    const response = await this.manageNewsletterSettingsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update newsletter settings
   */
  async manageNewsletterSettingsPostRaw(
    requestParameters: ManageNewsletterSettingsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<NewsletterSettingsUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/newsletter-settings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NewsletterSettingsUpdateRequestToJSON(
          requestParameters.newsletterSettingsUpdateRequest
        )
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      NewsletterSettingsUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update newsletter settings
   */
  async manageNewsletterSettingsPost(
    requestParameters: ManageNewsletterSettingsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<NewsletterSettingsUpdateResponse> {
    const response = await this.manageNewsletterSettingsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get partner onboarding status
   */
  async manageOnboardingGetRaw(
    requestParameters: ManageOnboardingGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OnboardingReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/onboarding`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OnboardingReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get partner onboarding status
   */
  async manageOnboardingGet(
    requestParameters: ManageOnboardingGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OnboardingReadResponse> {
    const response = await this.manageOnboardingGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Retrieves the status of PayPal seller onboarding process
   * Get PayPal onboarding status
   */
  async manageOnboardingPaypalGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OnboardingPaypalReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/onboarding/paypal`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OnboardingPaypalReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Retrieves the status of PayPal seller onboarding process
   * Get PayPal onboarding status
   */
  async manageOnboardingPaypalGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OnboardingPaypalReadResponse> {
    const response = await this.manageOnboardingPaypalGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Get PayPal onboarding url
   */
  async manageOnboardingPaypalPostRaw(
    requestParameters: ManageOnboardingPaypalPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OnboardingPaypalStartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/onboarding/paypal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OnboardingPaypalStartRequestToJSON(requestParameters.onboardingPaypalStartRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OnboardingPaypalStartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get PayPal onboarding url
   */
  async manageOnboardingPaypalPost(
    requestParameters: ManageOnboardingPaypalPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OnboardingPaypalStartResponse> {
    const response = await this.manageOnboardingPaypalPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Start partner onboarding
   */
  async manageOnboardingPostRaw(
    requestParameters: ManageOnboardingPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<OnboardingStartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/onboarding`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: OnboardingStartRequestToJSON(requestParameters.onboardingStartRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OnboardingStartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Start partner onboarding
   */
  async manageOnboardingPost(
    requestParameters: ManageOnboardingPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<OnboardingStartResponse> {
    const response = await this.manageOnboardingPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Summary
   */
  async managePageCancellationPolicyPostRaw(
    requestParameters: ManagePageCancellationPolicyPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CancellationPolicyReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/page/cancellation-policy`.replace(
          `{${'partner_id'}}`,
          encodeURIComponent(String(requestParameters.partnerId))
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CancellationPolicyReadRequestToJSON(requestParameters.cancellationPolicyReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CancellationPolicyReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Summary
   */
  async managePageCancellationPolicyPost(
    requestParameters: ManagePageCancellationPolicyPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CancellationPolicyReadResponse> {
    const response = await this.managePageCancellationPolicyPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get receipt settings
   */
  async manageReceiptSettingsGetRaw(
    requestParameters: ManageReceiptSettingsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReceiptSettingsReadResponse>> {
    const queryParameters: any = {};

    if (requestParameters.partnerId !== undefined) {
      queryParameters['partner_id'] = requestParameters.partnerId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/receipt-settings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReceiptSettingsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get receipt settings
   */
  async manageReceiptSettingsGet(
    requestParameters: ManageReceiptSettingsGetRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReceiptSettingsReadResponse> {
    const response = await this.manageReceiptSettingsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update receipt settings
   */
  async manageReceiptSettingsPostRaw(
    requestParameters: ManageReceiptSettingsPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ReceiptSettingsUpdateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/receipt-settings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ReceiptSettingsUpdateRequestToJSON(requestParameters.receiptSettingsUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ReceiptSettingsUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update receipt settings
   */
  async manageReceiptSettingsPost(
    requestParameters: ManageReceiptSettingsPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ReceiptSettingsUpdateResponse> {
    const response = await this.manageReceiptSettingsPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Create a reseller
   */
  async manageResellerPostRaw(
    requestParameters: ManageResellerPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResellerCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/reseller`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResellerCreateRequestToJSON(requestParameters.resellerCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResellerCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Create a reseller
   */
  async manageResellerPost(
    requestParameters: ManageResellerPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResellerCreateResponse> {
    const response = await this.manageResellerPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Delete a reseller
   */
  async manageResellersIdDeleteRaw(
    requestParameters: ManageResellersIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResellerDeleteResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageResellersIdDelete.'
      );
    }

    if (
      requestParameters.resellerDeleteRequest === null ||
      requestParameters.resellerDeleteRequest === undefined
    ) {
      throw new runtime.RequiredError(
        'resellerDeleteRequest',
        'Required parameter requestParameters.resellerDeleteRequest was null or undefined when calling manageResellersIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/resellers/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
        body: ResellerDeleteRequestToJSON(requestParameters.resellerDeleteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResellerDeleteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Delete a reseller
   */
  async manageResellersIdDelete(
    requestParameters: ManageResellersIdDeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResellerDeleteResponse> {
    const response = await this.manageResellersIdDeleteRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Read a reseller by ID
   */
  async manageResellersIdGetRaw(
    requestParameters: ManageResellersIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResellerReadByIdResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageResellersIdGet.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/resellers/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResellerReadByIdResponseFromJSON(jsonValue)
    );
  }

  /**
   * Read a reseller by ID
   */
  async manageResellersIdGet(
    requestParameters: ManageResellersIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResellerReadByIdResponse> {
    const response = await this.manageResellersIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update a reseller
   */
  async manageResellersIdPatchRaw(
    requestParameters: ManageResellersIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResellerUpdateResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling manageResellersIdPatch.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/resellers/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'PATCH',
        headers: headerParameters,
        query: queryParameters,
        body: ResellerUpdateRequestToJSON(requestParameters.resellerUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResellerUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update a reseller
   */
  async manageResellersIdPatch(
    requestParameters: ManageResellersIdPatchRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResellerUpdateResponse> {
    const response = await this.manageResellersIdPatchRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Read all resellers for a partner
   */
  async manageResellersPostRaw(
    requestParameters: ManageResellersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<ResellerReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/resellers`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResellerReadRequestToJSON(requestParameters.resellerReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResellerReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Read all resellers for a partner
   */
  async manageResellersPost(
    requestParameters: ManageResellersPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<ResellerReadResponse> {
    const response = await this.manageResellersPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get supported countries
   */
  async manageSupportedCountriesGetRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<SupportedCountriesReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/supported-countries`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SupportedCountriesReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get supported countries
   */
  async manageSupportedCountriesGet(
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<SupportedCountriesReadResponse> {
    const response = await this.manageSupportedCountriesGetRaw(initOverrides);
    return await response.value();
  }

  /**
   * Upsert tax connector credentials
   */
  async manageTaxConnectorPostRaw(
    requestParameters: ManageTaxConnectorPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<CredentialsUpsertResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/tax-connector`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: CredentialsUpsertRequestToJSON(requestParameters.credentialsUpsertRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CredentialsUpsertResponseFromJSON(jsonValue)
    );
  }

  /**
   * Upsert tax connector credentials
   */
  async manageTaxConnectorPost(
    requestParameters: ManageTaxConnectorPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<CredentialsUpsertResponse> {
    const response = await this.manageTaxConnectorPostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Auto translate i18n using google cloud v2
   */
  async manageTranslatePostRaw(
    requestParameters: ManageTranslatePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TranslateCreateResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/translate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: TranslateCreateRequestToJSON(requestParameters.translateCreateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TranslateCreateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Auto translate i18n using google cloud v2
   */
  async manageTranslatePost(
    requestParameters: ManageTranslatePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TranslateCreateResponse> {
    const response = await this.manageTranslatePostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Fetch translations for selected locale
   */
  async manageTranslationsGetRaw(
    requestParameters: ManageTranslationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<TranslationsReadResponse>> {
    if (requestParameters.localeCode === null || requestParameters.localeCode === undefined) {
      throw new runtime.RequiredError(
        'localeCode',
        'Required parameter requestParameters.localeCode was null or undefined when calling manageTranslationsGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/translations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TranslationsReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Fetch translations for selected locale
   */
  async manageTranslationsGet(
    requestParameters: ManageTranslationsGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<TranslationsReadResponse> {
    const response = await this.manageTranslationsGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Invite user
   */
  async manageUserInvitePostRaw(
    requestParameters: ManageUserInvitePostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserInviteResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/user/invite`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserInviteRequestToJSON(requestParameters.userInviteRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserInviteResponseFromJSON(jsonValue)
    );
  }

  /**
   * Invite user
   */
  async manageUserInvitePost(
    requestParameters: ManageUserInvitePostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserInviteResponse> {
    const response = await this.manageUserInvitePostRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get user by ID
   */
  async manageUserUserIdGetRaw(
    requestParameters: ManageUserUserIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserByIdReadResponse>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling manageUserUserIdGet.'
      );
    }

    const queryParameters: any = {};

    if (requestParameters.localeCode !== undefined) {
      queryParameters['locale_code'] = requestParameters.localeCode;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/manage/user/{user_id}`
          .replace(`{${'partner_id'}}`, encodeURIComponent(String(requestParameters.partnerId)))
          .replace(`{${'user_id'}}`, encodeURIComponent(String(requestParameters.userId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserByIdReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get user by ID
   */
  async manageUserUserIdGet(
    requestParameters: ManageUserUserIdGetRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserByIdReadResponse> {
    const response = await this.manageUserUserIdGetRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Update user
   */
  async manageUserUserIdPutRaw(
    requestParameters: ManageUserUserIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UserUpdateResponse>> {
    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling manageUserUserIdPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/user/{user_id}`.replace(
          `{${'user_id'}}`,
          encodeURIComponent(String(requestParameters.userId))
        ),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: UserUpdateRequestToJSON(requestParameters.userUpdateRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UserUpdateResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update user
   */
  async manageUserUserIdPut(
    requestParameters: ManageUserUserIdPutRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UserUpdateResponse> {
    const response = await this.manageUserUserIdPutRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all users
   */
  async manageUsersPostRaw(
    requestParameters: ManageUsersPostRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<UsersReadResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/manage/users`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UsersReadRequestToJSON(requestParameters.usersReadRequest)
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      UsersReadResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get all users
   */
  async manageUsersPost(
    requestParameters: ManageUsersPostRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<UsersReadResponse> {
    const response = await this.manageUsersPostRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
