/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTemplatesCreateResponseEmailTemplate } from './EmailTemplatesCreateResponseEmailTemplate';
import {
  EmailTemplatesCreateResponseEmailTemplateFromJSON,
  EmailTemplatesCreateResponseEmailTemplateFromJSONTyped,
  EmailTemplatesCreateResponseEmailTemplateToJSON
} from './EmailTemplatesCreateResponseEmailTemplate';

/**
 *
 * @export
 * @interface EmailTemplatesCreateResponse
 */
export interface EmailTemplatesCreateResponse {
  /**
   *
   * @type {boolean}
   * @memberof EmailTemplatesCreateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponse
   */
  error_code?: EmailTemplatesCreateResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof EmailTemplatesCreateResponse
   */
  error_message?: string;
  /**
   *
   * @type {EmailTemplatesCreateResponseEmailTemplate}
   * @memberof EmailTemplatesCreateResponse
   */
  email_template?: EmailTemplatesCreateResponseEmailTemplate;
}

/**
 * @export
 */
export const EmailTemplatesCreateResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type EmailTemplatesCreateResponseErrorCodeEnum =
  (typeof EmailTemplatesCreateResponseErrorCodeEnum)[keyof typeof EmailTemplatesCreateResponseErrorCodeEnum];

/**
 * Check if a given object implements the EmailTemplatesCreateResponse interface.
 */
export function instanceOfEmailTemplatesCreateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function EmailTemplatesCreateResponseFromJSON(json: any): EmailTemplatesCreateResponse {
  return EmailTemplatesCreateResponseFromJSONTyped(json, false);
}

export function EmailTemplatesCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTemplatesCreateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    email_template: !exists(json, 'email_template')
      ? undefined
      : EmailTemplatesCreateResponseEmailTemplateFromJSON(json['email_template'])
  };
}

export function EmailTemplatesCreateResponseToJSON(
  value?: EmailTemplatesCreateResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    email_template: EmailTemplatesCreateResponseEmailTemplateToJSON(value.email_template)
  };
}
