/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResellerReadByIdResponseData
 */
export interface ResellerReadByIdResponseData {
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  commission_type: ResellerReadByIdResponseDataCommissionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ResellerReadByIdResponseData
   */
  commission_amount: number;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  email_address: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  phone_number: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadByIdResponseData
   */
  status: ResellerReadByIdResponseDataStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof ResellerReadByIdResponseData
   */
  created_at: Date;
}

/**
 * @export
 */
export const ResellerReadByIdResponseDataCommissionTypeEnum = {
  Absolute: 'absolute',
  Percentage: 'percentage'
} as const;
export type ResellerReadByIdResponseDataCommissionTypeEnum =
  (typeof ResellerReadByIdResponseDataCommissionTypeEnum)[keyof typeof ResellerReadByIdResponseDataCommissionTypeEnum];

/**
 * @export
 */
export const ResellerReadByIdResponseDataStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted',
  Invited: 'invited'
} as const;
export type ResellerReadByIdResponseDataStatusEnum =
  (typeof ResellerReadByIdResponseDataStatusEnum)[keyof typeof ResellerReadByIdResponseDataStatusEnum];

/**
 * Check if a given object implements the ResellerReadByIdResponseData interface.
 */
export function instanceOfResellerReadByIdResponseData(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'commission_type' in value;
  isInstance = isInstance && 'commission_amount' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email_address' in value;
  isInstance = isInstance && 'phone_number' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'created_at' in value;

  return isInstance;
}

export function ResellerReadByIdResponseDataFromJSON(json: any): ResellerReadByIdResponseData {
  return ResellerReadByIdResponseDataFromJSONTyped(json, false);
}

export function ResellerReadByIdResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerReadByIdResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    commission_type: json['commission_type'],
    commission_amount: json['commission_amount'],
    name: json['name'],
    last_name: json['last_name'],
    email_address: json['email_address'],
    phone_number: json['phone_number'],
    status: json['status'],
    created_at: new Date(json['created_at'])
  };
}

export function ResellerReadByIdResponseDataToJSON(
  value?: ResellerReadByIdResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    commission_type: value.commission_type,
    commission_amount: value.commission_amount,
    name: value.name,
    last_name: value.last_name,
    email_address: value.email_address,
    phone_number: value.phone_number,
    status: value.status,
    created_at: value.created_at.toISOString()
  };
}
