/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyUpdateRequestCancellationPolicy } from './CancellationPolicyUpdateRequestCancellationPolicy';
import {
  CancellationPolicyUpdateRequestCancellationPolicyFromJSON,
  CancellationPolicyUpdateRequestCancellationPolicyFromJSONTyped,
  CancellationPolicyUpdateRequestCancellationPolicyToJSON
} from './CancellationPolicyUpdateRequestCancellationPolicy';

/**
 *
 * @export
 * @interface CancellationPolicyReadByIdResponse
 */
export interface CancellationPolicyReadByIdResponse {
  /**
   *
   * @type {boolean}
   * @memberof CancellationPolicyReadByIdResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyReadByIdResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyReadByIdResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyReadByIdResponse
   */
  error_code?: CancellationPolicyReadByIdResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof CancellationPolicyReadByIdResponse
   */
  error_message?: string;
  /**
   *
   * @type {CancellationPolicyUpdateRequestCancellationPolicy}
   * @memberof CancellationPolicyReadByIdResponse
   */
  cancellation_policy: CancellationPolicyUpdateRequestCancellationPolicy;
}

/**
 * @export
 */
export const CancellationPolicyReadByIdResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type CancellationPolicyReadByIdResponseErrorCodeEnum =
  (typeof CancellationPolicyReadByIdResponseErrorCodeEnum)[keyof typeof CancellationPolicyReadByIdResponseErrorCodeEnum];

/**
 * Check if a given object implements the CancellationPolicyReadByIdResponse interface.
 */
export function instanceOfCancellationPolicyReadByIdResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'cancellation_policy' in value;

  return isInstance;
}

export function CancellationPolicyReadByIdResponseFromJSON(
  json: any
): CancellationPolicyReadByIdResponse {
  return CancellationPolicyReadByIdResponseFromJSONTyped(json, false);
}

export function CancellationPolicyReadByIdResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyReadByIdResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    cancellation_policy: CancellationPolicyUpdateRequestCancellationPolicyFromJSON(
      json['cancellation_policy']
    )
  };
}

export function CancellationPolicyReadByIdResponseToJSON(
  value?: CancellationPolicyReadByIdResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    cancellation_policy: CancellationPolicyUpdateRequestCancellationPolicyToJSON(
      value.cancellation_policy
    )
  };
}
