import { ref } from 'vue';
import type { Ref } from 'vue';
import { manageApi } from '@/service/TicketingApi';
import { ManageApi, type ManageTranslatePostRequest } from '@openapi/apis/ManageApi';
import { useLocaleStore } from '@/stores/locales';
import { Configuration } from '@openapi/runtime';
import { DIRECTUS_API_URL } from '@/config';
import type { FieldEntry } from 'vee-validate';
import { filterObject } from '@/utils/object';

export interface TranslateTabsFormSchema {
  fields: {
    as: string;
    name: string;
    label: string;
    [key: string]: unknown;
    rules?: string;
  }[];
}
export interface TranslateFieldArray {
  locale_code: string;
  // Keys can be strings, numbers, or symbols.
  // If you know it to be strings only, you can also restrict it to that.
  // For the value you can use any or unknown,
  // with unknown being the more defensive approach.
  [x: string | number | symbol]: unknown;
}

export interface AutoTranslate {
  fields: FieldEntry<TranslateFieldArray>[];
  selectedLocaleCode: string;
  schema: TranslateTabsFormSchema;
  update: (idx: number, value: TranslateFieldArray) => void;
  formGroupName: string;
}

export default function useTranslate() {
  const loading: Ref<boolean> = ref(false);

  const translate = async (requestParameters: ManageTranslatePostRequest) => {
    loading.value = true;
    const response = await manageApi.manageTranslatePost(requestParameters);
    if (!response.success) {
      throw new Error('Error while auto-translating strings');
    }
    loading.value = false;
    return response.translations;
  };

  const fetchTranslationsByLocale = async () => {
    const config = new Configuration({
      basePath: DIRECTUS_API_URL,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const manageUnauthenticatedApi: ManageApi = new ManageApi(config);

    const { getSelectedLocale } = useLocaleStore();
    loading.value = true;
    const requestParameters = {
      localeCode: getSelectedLocale
    };
    const response = await manageUnauthenticatedApi.manageTranslationsGet(requestParameters);
    if (!response.success) {
      throw new Error('Error while fetching the receipt settings');
    }
    loading.value = false;
    return response.translations;
  };

  async function autoTranslate(
    fields: AutoTranslate['fields'],
    selectedLocaleCode: AutoTranslate['selectedLocaleCode'],
    schema: AutoTranslate['schema'],
    update: AutoTranslate['update']
  ) {
    // Get the source fields to translate from
    const sourceFields = fields.find((field) => field.value.locale_code === selectedLocaleCode);
    if (!sourceFields) {
      throw new Error('No fields to translate found');
    }

    // Track which fields were originally null or empty
    const nullFields = Object.fromEntries(
      Object.entries(sourceFields.value)
        .filter(([, value]) => value === null || value === '' || value === undefined)
        .map(([key]) => [key, null])
    );

    // Get list of translatable field names from schema
    const translatableFields = Object.values(schema.fields).map((field) => field.name);

    // Filter out fields that should not be translated
    const fieldsToTranslate = filterObject(sourceFields.value, ([key]) => {
      if (typeof key !== 'string') {
        throw new Error('Field name must be a string');
      }
      return translatableFields.includes(key) && nullFields[key] !== null;
    });

    const hasTranslatableFields = Object.keys(fieldsToTranslate).length > 0;

    // Get translations from API
    const translations = hasTranslatableFields
      ? await translate({
          translateCreateRequest: {
            locale_code: selectedLocaleCode,
            translate_from_locale: selectedLocaleCode,
            strings_to_translate: fieldsToTranslate
          }
        })
      : {};

    if (!translations) {
      throw new Error('No translations returned from API');
    }

    // Update translations for each target language
    for (const key in fields) {
      const { locale_code } = fields[key].value;
      if (locale_code === selectedLocaleCode) {
        update(parseInt(key), {
          ...fields[key].value,
          ...nullFields
        });
      } else {
        update(parseInt(key), {
          ...fields[key].value,
          ...(translations?.[locale_code] ?? {}),
          ...nullFields
        });
      }
    }
  }

  return { loading, translate, fetchTranslationsByLocale, autoTranslate };
}
