/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResellerCreateResponseData } from './ResellerCreateResponseData';
import {
  ResellerCreateResponseDataFromJSON,
  ResellerCreateResponseDataFromJSONTyped,
  ResellerCreateResponseDataToJSON
} from './ResellerCreateResponseData';

/**
 *
 * @export
 * @interface ResellerCreateResponse
 */
export interface ResellerCreateResponse {
  /**
   *
   * @type {boolean}
   * @memberof ResellerCreateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateResponse
   */
  error_code?: ResellerCreateResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ResellerCreateResponse
   */
  error_message?: string;
  /**
   *
   * @type {ResellerCreateResponseData}
   * @memberof ResellerCreateResponse
   */
  data: ResellerCreateResponseData;
}

/**
 * @export
 */
export const ResellerCreateResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ResellerCreateResponseErrorCodeEnum =
  (typeof ResellerCreateResponseErrorCodeEnum)[keyof typeof ResellerCreateResponseErrorCodeEnum];

/**
 * Check if a given object implements the ResellerCreateResponse interface.
 */
export function instanceOfResellerCreateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'data' in value;

  return isInstance;
}

export function ResellerCreateResponseFromJSON(json: any): ResellerCreateResponse {
  return ResellerCreateResponseFromJSONTyped(json, false);
}

export function ResellerCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerCreateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    data: ResellerCreateResponseDataFromJSON(json['data'])
  };
}

export function ResellerCreateResponseToJSON(value?: ResellerCreateResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    data: ResellerCreateResponseDataToJSON(value.data)
  };
}
