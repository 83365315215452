import { manageApi } from '@/service/TicketingApi';
import type { AiAnalystReadResponse } from '@openapi/models';
import { ref } from 'vue';
import type { Ref } from 'vue';
import { useUserFeedback } from '@/composables/user-feedback';
import type { ManageAiAnalystPostRequest } from '@openapi/apis/ManageApi';
import { useLocaleStore } from '@/stores/locales';
import { useUserStore } from '@/stores/user';

export default function useAiAnalyst() {
  const loading: Ref<boolean> = ref(true);
  const answer = ref<AiAnalystReadResponse['answer']>();
  const { awaitPromiseWithErrorHandling } = useUserFeedback();
  const { getSelectedLocale } = useLocaleStore();
  const { getActivePartnerId } = useUserStore();

  const askQuestion = async (question: string) => {
    const requestParams: ManageAiAnalystPostRequest = {
      aiAnalystReadRequest: {
        question,
        locale_code: getSelectedLocale,
        partner_id: getActivePartnerId
      }
    };
    loading.value = true;
    const response = await awaitPromiseWithErrorHandling(
      manageApi.manageAiAnalystPost(requestParams)
    );
    if (response.success) {
      answer.value = response.answer;
    }
    loading.value = false;
    return answer.value;
  };

  return {
    askQuestion
  };
}
