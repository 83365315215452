/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PayoutDetailsByPayoutIdReadResponseMeta } from './PayoutDetailsByPayoutIdReadResponseMeta';
import {
  PayoutDetailsByPayoutIdReadResponseMetaFromJSON,
  PayoutDetailsByPayoutIdReadResponseMetaFromJSONTyped,
  PayoutDetailsByPayoutIdReadResponseMetaToJSON
} from './PayoutDetailsByPayoutIdReadResponseMeta';
import type { PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails } from './PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails';
import {
  PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsFromJSON,
  PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsFromJSONTyped,
  PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsToJSON
} from './PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails';

/**
 *
 * @export
 * @interface PayoutDetailsRefundsByPayoutIdReadResponse
 */
export interface PayoutDetailsRefundsByPayoutIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  error_code?: PayoutDetailsRefundsByPayoutIdReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {PayoutDetailsByPayoutIdReadResponseMeta}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  meta: PayoutDetailsByPayoutIdReadResponseMeta;
  /**
   *
   * @type {PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails}
   * @memberof PayoutDetailsRefundsByPayoutIdReadResponse
   */
  payout_details: PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetails;
}

/**
 * @export
 */
export const PayoutDetailsRefundsByPayoutIdReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type PayoutDetailsRefundsByPayoutIdReadResponseErrorCodeEnum =
  (typeof PayoutDetailsRefundsByPayoutIdReadResponseErrorCodeEnum)[keyof typeof PayoutDetailsRefundsByPayoutIdReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the PayoutDetailsRefundsByPayoutIdReadResponse interface.
 */
export function instanceOfPayoutDetailsRefundsByPayoutIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'payout_details' in value;

  return isInstance;
}

export function PayoutDetailsRefundsByPayoutIdReadResponseFromJSON(
  json: any
): PayoutDetailsRefundsByPayoutIdReadResponse {
  return PayoutDetailsRefundsByPayoutIdReadResponseFromJSONTyped(json, false);
}

export function PayoutDetailsRefundsByPayoutIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PayoutDetailsRefundsByPayoutIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    meta: PayoutDetailsByPayoutIdReadResponseMetaFromJSON(json['meta']),
    payout_details: PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsFromJSON(
      json['payout_details']
    )
  };
}

export function PayoutDetailsRefundsByPayoutIdReadResponseToJSON(
  value?: PayoutDetailsRefundsByPayoutIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    meta: PayoutDetailsByPayoutIdReadResponseMetaToJSON(value.meta),
    payout_details: PayoutDetailsRefundsByPayoutIdReadResponsePayoutDetailsToJSON(
      value.payout_details
    )
  };
}
