/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
 */
export interface ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner {
  /**
   *
   * @type {string}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
   */
  pricing_category_id: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
   */
  pricing_category_name: string;
  /**
   *
   * @type {number}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
   */
  left: number;
  /**
   *
   * @type {Date}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
   */
  valid_from?: Date;
  /**
   *
   * @type {Date}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner
   */
  valid_until?: Date;
}

/**
 * Check if a given object implements the ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner interface.
 */
export function instanceOfExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'pricing_category_id' in value;
  isInstance = isInstance && 'pricing_category_name' in value;
  isInstance = isInstance && 'total' in value;
  isInstance = isInstance && 'left' in value;

  return isInstance;
}

export function ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerFromJSON(
  json: any
): ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner {
  return ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerFromJSONTyped(
    json,
    false
  );
}

export function ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    pricing_category_id: json['pricing_category_id'],
    pricing_category_name: json['pricing_category_name'],
    total: json['total'],
    left: json['left'],
    valid_from: !exists(json, 'valid_from') ? undefined : new Date(json['valid_from']),
    valid_until: !exists(json, 'valid_until') ? undefined : new Date(json['valid_until'])
  };
}

export function ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerToJSON(
  value?: ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    pricing_category_id: value.pricing_category_id,
    pricing_category_name: value.pricing_category_name,
    total: value.total,
    left: value.left,
    valid_from: value.valid_from === undefined ? undefined : value.valid_from.toISOString(),
    valid_until: value.valid_until === undefined ? undefined : value.valid_until.toISOString()
  };
}
