/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductOptionQuestionReadResponseProductOptionQuestionsInner } from './ProductOptionQuestionReadResponseProductOptionQuestionsInner';
import {
  ProductOptionQuestionReadResponseProductOptionQuestionsInnerFromJSON,
  ProductOptionQuestionReadResponseProductOptionQuestionsInnerFromJSONTyped,
  ProductOptionQuestionReadResponseProductOptionQuestionsInnerToJSON
} from './ProductOptionQuestionReadResponseProductOptionQuestionsInner';

/**
 *
 * @export
 * @interface ProductOptionQuestionReadResponse
 */
export interface ProductOptionQuestionReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductOptionQuestionReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductOptionQuestionReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionQuestionReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ProductOptionQuestionReadResponse
   */
  error_code?: ProductOptionQuestionReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ProductOptionQuestionReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<ProductOptionQuestionReadResponseProductOptionQuestionsInner>}
   * @memberof ProductOptionQuestionReadResponse
   */
  product_option_questions: Array<ProductOptionQuestionReadResponseProductOptionQuestionsInner>;
}

/**
 * @export
 */
export const ProductOptionQuestionReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ProductOptionQuestionReadResponseErrorCodeEnum =
  (typeof ProductOptionQuestionReadResponseErrorCodeEnum)[keyof typeof ProductOptionQuestionReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the ProductOptionQuestionReadResponse interface.
 */
export function instanceOfProductOptionQuestionReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_option_questions' in value;

  return isInstance;
}

export function ProductOptionQuestionReadResponseFromJSON(
  json: any
): ProductOptionQuestionReadResponse {
  return ProductOptionQuestionReadResponseFromJSONTyped(json, false);
}

export function ProductOptionQuestionReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductOptionQuestionReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    product_option_questions: (json['product_option_questions'] as Array<any>).map(
      ProductOptionQuestionReadResponseProductOptionQuestionsInnerFromJSON
    )
  };
}

export function ProductOptionQuestionReadResponseToJSON(
  value?: ProductOptionQuestionReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    product_option_questions: (value.product_option_questions as Array<any>).map(
      ProductOptionQuestionReadResponseProductOptionQuestionsInnerToJSON
    )
  };
}
