/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotBlockUpdateResponse
 */
export interface TimeSlotBlockUpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof TimeSlotBlockUpdateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TimeSlotBlockUpdateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotBlockUpdateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotBlockUpdateResponse
   */
  error_code?: TimeSlotBlockUpdateResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof TimeSlotBlockUpdateResponse
   */
  error_message?: string;
}

/**
 * @export
 */
export const TimeSlotBlockUpdateResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type TimeSlotBlockUpdateResponseErrorCodeEnum =
  (typeof TimeSlotBlockUpdateResponseErrorCodeEnum)[keyof typeof TimeSlotBlockUpdateResponseErrorCodeEnum];

/**
 * Check if a given object implements the TimeSlotBlockUpdateResponse interface.
 */
export function instanceOfTimeSlotBlockUpdateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function TimeSlotBlockUpdateResponseFromJSON(json: any): TimeSlotBlockUpdateResponse {
  return TimeSlotBlockUpdateResponseFromJSONTyped(json, false);
}

export function TimeSlotBlockUpdateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotBlockUpdateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message']
  };
}

export function TimeSlotBlockUpdateResponseToJSON(value?: TimeSlotBlockUpdateResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message
  };
}
