import { useToastService } from '@/utils/toast';
import i18n from '@/i18n';
import {
  TicketingResponsePayloadErrorCodeEnum,
  type TicketingResponsePayload
} from '@openapi/models';
import { ResponseError } from '@openapi/runtime';
import type { Ref } from 'vue';

const NOTIFICATION_DURATION_SHORT = 5 * 1000;
const NOTIFICATION_DURATION_LONG = 10 * 1000;

type TicketingResponseSuccessPayload<T> = T & { success: true };
type TicketingResponseFailedPayload = {
  success: false;
  exception_code?: string;
  exception_message?: string;
};

type TicketingResponsePayloadWithRequestValidationMessage = TicketingResponsePayload & {
  success: false;
  error_code: typeof TicketingResponsePayloadErrorCodeEnum.RequestValidationError;
  error_message: string;
};

// Type for expected exception responses with required exception details
type TicketingResponsePayloadWithExpectedException = TicketingResponsePayload & {
  success: false;
  exception_code: string;
  exception_message: string;
};

export const useUserFeedback = () => {
  const toast = useToastService();

  async function awaitPromiseWithErrorHandling<T>(
    fn: Promise<T & TicketingResponsePayload>,
    notifyUserAboutSuccessfulResponse = false,
    loadingRef?: Ref<boolean>
  ): Promise<TicketingResponseSuccessPayload<T> | TicketingResponseFailedPayload> {
    try {
      if (loadingRef) {
        loadingRef.value = true;
      }
      // Execute the promise
      const response = await fn;

      if (isSuccessResponse(response)) {
        if (notifyUserAboutSuccessfulResponse) {
          // Display notification about successfully completing the operation
          toast.add({
            severity: 'success',
            summary: i18n.global.t('portal_success_title'),
            detail: i18n.global.t('portal_success_text'),
            life: NOTIFICATION_DURATION_SHORT
          });
        }
        return response;
      } else if (isExpectedExceptionError(response)) {
        const userFriendlyExceptionMessage = `${response.exception_message} (${response.exception_code})`;

        // Show a notification to the user with translated exception and an error code
        toast.add({
          severity: 'error',
          summary: i18n.global.t('portal_error_title'),
          detail: userFriendlyExceptionMessage,
          life: NOTIFICATION_DURATION_SHORT
        });
      }
      return response;
    } catch (err) {
      // If err is not an instance of ResponseError, throw it. We have no way to extract the response body in this case.
      if (err instanceof ResponseError === false) {
        throw err;
      }

      // Extract the response body from the error response
      const body = await err.response.json();

      // If the response body is not a valid TicketingResponsePayload, throw the error
      if (!isTicketingResponsePayload(body)) {
        throw err;
      }

      // REQUEST_VALIDATION_ERROR: Handle errors that are request validation errors
      if (isRequestValidationError(body)) {
        toast.add({
          severity: 'warn',
          summary: i18n.global.t('portal_request_validation_error_title'),
          detail: `${i18n.global.t('portal_request_validation_error_detail')}\n\n${body.error_message}`,
          life: NOTIFICATION_DURATION_LONG
        });
        throw err;
      }
      // GENERIC_ERROR: Handle other errors
      toast.add({
        severity: 'warn',
        summary: i18n.global.t('portal_error_title'),
        detail: i18n.global.t('portal_error_text'),
        life: NOTIFICATION_DURATION_SHORT
      });
      throw err;
    } finally {
      if (loadingRef) {
        loadingRef.value = false;
      }
    }
  }

  async function awaitPromiseWithErrorHandlingNoSuccessNotification<T>(
    fn: Promise<T & TicketingResponsePayload>,
    loadingRef?: Ref<boolean>
  ): Promise<TicketingResponseSuccessPayload<T> | TicketingResponseFailedPayload> {
    return awaitPromiseWithErrorHandling(fn, false, loadingRef);
  }

  async function provideUserFeedbackForClipboard<T>(fn: Promise<T>): Promise<T> {
    try {
      const result = await fn;
      toast.add({
        severity: 'info',
        summary: i18n.global.t('portal_info_title'),
        detail: i18n.global.t('portal_info_text'),
        life: NOTIFICATION_DURATION_LONG
      });
      return result;
    } catch (err) {
      toast.add({
        severity: 'error',
        summary: i18n.global.t('portal_error_title'),
        detail: i18n.global.t('portal_error_text'),
        life: NOTIFICATION_DURATION_LONG
      });
      throw err;
    }
  }

  return {
    awaitPromiseWithErrorHandling,
    awaitPromiseWithErrorHandlingNoSuccessNotification,
    provideUserFeedbackForClipboard
  };
};

function isTicketingResponsePayload(response: unknown): response is TicketingResponsePayload {
  return Boolean(
    // Ensure response exists and is not null/undefined
    response &&
      // Ensure response is an object (not a primitive)
      typeof response === 'object' &&
      // Check if response has a 'success' property
      'success' in response &&
      // Verify 'success' is specifically a boolean
      typeof response.success === 'boolean'
  );
}

function isSuccessResponse<T>(response: unknown): response is TicketingResponseSuccessPayload<T> {
  if (!isTicketingResponsePayload(response)) {
    return false;
  }

  return Boolean(response.success === true);
}

function isRequestValidationError(
  response: unknown
): response is TicketingResponsePayloadWithRequestValidationMessage {
  if (!isTicketingResponsePayload(response)) {
    return false;
  }

  return Boolean(
    response.error_code &&
      response.error_code === TicketingResponsePayloadErrorCodeEnum.RequestValidationError &&
      response.error_message !== undefined &&
      response.error_message !== ''
  );
}

function isExpectedExceptionError(
  response: unknown
): response is TicketingResponsePayloadWithExpectedException {
  if (!isTicketingResponsePayload(response)) {
    return false;
  }

  return Boolean(
    response.success === false &&
      response.exception_code !== undefined &&
      response.exception_code !== '' &&
      response.exception_message !== undefined &&
      response.exception_message !== ''
  );
}
