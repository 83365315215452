/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner
 */
export interface ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner {
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner
   */
  checkout_field: ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerCheckoutFieldEnum;
  /**
   *
   * @type {boolean}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner
   */
  is_required: boolean;
}

/**
 * @export
 */
export const ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerCheckoutFieldEnum =
  {
    Company: 'company',
    TaxId: 'tax_id',
    Name: 'name',
    Email: 'email',
    PhoneNr: 'phone_nr',
    Street: 'street',
    City: 'city',
    State: 'state',
    Zip: 'zip',
    Country: 'country'
  } as const;
export type ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerCheckoutFieldEnum =
  (typeof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerCheckoutFieldEnum)[keyof typeof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerCheckoutFieldEnum];

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'checkout_field' in value;
  isInstance = isInstance && 'is_required' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner {
  return ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerFromJSONTyped(
    json,
    false
  );
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    checkout_field: json['checkout_field'],
    is_required: json['is_required']
  };
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerToJSON(
  value?: ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    checkout_field: value.checkout_field,
    is_required: value.is_required
  };
}
