/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { TicketConfigurationByProductOptionIdReadResponseTicketConfiguration } from './TicketConfigurationByProductOptionIdReadResponseTicketConfiguration';
import {
  TicketConfigurationByProductOptionIdReadResponseTicketConfigurationFromJSON,
  TicketConfigurationByProductOptionIdReadResponseTicketConfigurationFromJSONTyped,
  TicketConfigurationByProductOptionIdReadResponseTicketConfigurationToJSON
} from './TicketConfigurationByProductOptionIdReadResponseTicketConfiguration';

/**
 *
 * @export
 * @interface TicketConfigurationByProductOptionIdReadResponse
 */
export interface TicketConfigurationByProductOptionIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof TicketConfigurationByProductOptionIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationByProductOptionIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationByProductOptionIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationByProductOptionIdReadResponse
   */
  error_code?: TicketConfigurationByProductOptionIdReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof TicketConfigurationByProductOptionIdReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {TicketConfigurationByProductOptionIdReadResponseTicketConfiguration}
   * @memberof TicketConfigurationByProductOptionIdReadResponse
   */
  ticket_configuration: TicketConfigurationByProductOptionIdReadResponseTicketConfiguration;
}

/**
 * @export
 */
export const TicketConfigurationByProductOptionIdReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type TicketConfigurationByProductOptionIdReadResponseErrorCodeEnum =
  (typeof TicketConfigurationByProductOptionIdReadResponseErrorCodeEnum)[keyof typeof TicketConfigurationByProductOptionIdReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the TicketConfigurationByProductOptionIdReadResponse interface.
 */
export function instanceOfTicketConfigurationByProductOptionIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'ticket_configuration' in value;

  return isInstance;
}

export function TicketConfigurationByProductOptionIdReadResponseFromJSON(
  json: any
): TicketConfigurationByProductOptionIdReadResponse {
  return TicketConfigurationByProductOptionIdReadResponseFromJSONTyped(json, false);
}

export function TicketConfigurationByProductOptionIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketConfigurationByProductOptionIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    ticket_configuration:
      TicketConfigurationByProductOptionIdReadResponseTicketConfigurationFromJSON(
        json['ticket_configuration']
      )
  };
}

export function TicketConfigurationByProductOptionIdReadResponseToJSON(
  value?: TicketConfigurationByProductOptionIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    ticket_configuration: TicketConfigurationByProductOptionIdReadResponseTicketConfigurationToJSON(
      value.ticket_configuration
    )
  };
}
