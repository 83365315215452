/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RolesReadResponsePartnersInner } from './RolesReadResponsePartnersInner';
import {
  RolesReadResponsePartnersInnerFromJSON,
  RolesReadResponsePartnersInnerFromJSONTyped,
  RolesReadResponsePartnersInnerToJSON
} from './RolesReadResponsePartnersInner';

/**
 *
 * @export
 * @interface RolesReadResponse
 */
export interface RolesReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof RolesReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponse
   */
  error_code?: RolesReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof RolesReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {string}
   * @memberof RolesReadResponse
   */
  impersonating?: string;
  /**
   *
   * @type {Array<RolesReadResponsePartnersInner>}
   * @memberof RolesReadResponse
   */
  partners?: Array<RolesReadResponsePartnersInner>;
}

/**
 * @export
 */
export const RolesReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type RolesReadResponseErrorCodeEnum =
  (typeof RolesReadResponseErrorCodeEnum)[keyof typeof RolesReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the RolesReadResponse interface.
 */
export function instanceOfRolesReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function RolesReadResponseFromJSON(json: any): RolesReadResponse {
  return RolesReadResponseFromJSONTyped(json, false);
}

export function RolesReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RolesReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    impersonating: !exists(json, 'impersonating') ? undefined : json['impersonating'],
    partners: !exists(json, 'partners')
      ? undefined
      : (json['partners'] as Array<any>).map(RolesReadResponsePartnersInnerFromJSON)
  };
}

export function RolesReadResponseToJSON(value?: RolesReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    impersonating: value.impersonating,
    partners:
      value.partners === undefined
        ? undefined
        : (value.partners as Array<any>).map(RolesReadResponsePartnersInnerToJSON)
  };
}
