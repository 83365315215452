/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Translation details for a specific locale
 * @export
 * @interface PricingCategoryTranslations
 */
export interface PricingCategoryTranslations {
  /**
   * Locale code
   * @type {string}
   * @memberof PricingCategoryTranslations
   */
  locale_code: string;
  /**
   * Display name of the pricing category
   * @type {string}
   * @memberof PricingCategoryTranslations
   */
  display_name: string | null;
  /**
   * Unique identifier for the translation
   * @type {string}
   * @memberof PricingCategoryTranslations
   */
  id?: string | null;
  /**
   * Associated pricing category ID
   * @type {string}
   * @memberof PricingCategoryTranslations
   */
  pricing_category_id?: string;
}

/**
 * Check if a given object implements the PricingCategoryTranslations interface.
 */
export function instanceOfPricingCategoryTranslations(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'display_name' in value;

  return isInstance;
}

export function PricingCategoryTranslationsFromJSON(json: any): PricingCategoryTranslations {
  return PricingCategoryTranslationsFromJSONTyped(json, false);
}

export function PricingCategoryTranslationsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingCategoryTranslations {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    display_name: json['display_name'],
    id: !exists(json, 'id') ? undefined : json['id'],
    pricing_category_id: !exists(json, 'pricing_category_id')
      ? undefined
      : json['pricing_category_id']
  };
}

export function PricingCategoryTranslationsToJSON(value?: PricingCategoryTranslations | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    display_name: value.display_name,
    id: value.id,
    pricing_category_id: value.pricing_category_id
  };
}
