import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { useUserStore } from '@/stores/user';
import type { RouteRecordRaw } from 'vue-router';
import { DEFAULT_LOCALE } from '@/constants/i18n.ts';
import { useAuth } from '@/composables/use-auth.ts';

export const AUTH_ROUTES: RouteRecordRaw[] = [
  {
    path: '/auth/login',
    name: ROUTE_NAMES.LOGIN,
    component: () => import('@/views/pages/auth/Login.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.LOGIN]
    }
  },
  {
    path: '/auth/reset-password',
    name: ROUTE_NAMES.RESET_PASSWORD,
    component: () => import('@/views/pages/auth/ResetPassword.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.RESET_PASSWORD]
    }
  },
  {
    path: '/auth/update-password',
    name: ROUTE_NAMES.UPDATE_PASSWORD,
    component: () => import('@/views/pages/auth/UpdatePassword.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.UPDATE_PASSWORD]
    }
  },
  {
    path: '/auth/register',
    name: ROUTE_NAMES.REGISTER,
    component: () => import('@/views/pages/auth/Register.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.REGISTER]
    },
    children: [
      {
        path: '/auth/register',
        name: ROUTE_NAMES.REGISTER_CREATE_ACCOUNT,
        component: () => import('@/views/pages/auth/RegisterCreate.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REGISTER, PAGE_TITLES.REGISTER_CREATE_ACCOUNT]
        }
      },
      {
        path: '/auth/register/business-information',
        name: ROUTE_NAMES.REGISTER_BUSINESS_INFORMATION,
        component: () => import('@/views/pages/auth/RegisterBusinessInfo.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REGISTER, PAGE_TITLES.REGISTER_BUSINESS_INFORMATION]
        }
      },
      {
        path: '/auth/register/confirmation',
        name: ROUTE_NAMES.REGISTER_CONFIRMATION,
        component: () => import('@/views/pages/auth/RegisterConfirmation.vue'),
        meta: {
          breadcrumbs: [PAGE_TITLES.REGISTER, PAGE_TITLES.REGISTER_CONFIRMATION]
        }
      }
    ]
  },
  {
    path: '/auth/select-partner',
    name: ROUTE_NAMES.SELECT_PARTNER,
    component: () => import('@/views/pages/auth/SelectPartner.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.SELECT_PARTNER]
    },
    beforeEnter: async (to, from, next) => {
      const userStore = useUserStore();
      const { updateUserRole } = useAuth();

      const { id, isGygAdminUser } = userStore;
      if (id && isGygAdminUser) {
        await updateUserRole({
          rolesUpdateRequest: {
            locale_code: DEFAULT_LOCALE
          }
        });
      }

      const redirect = to.query.redirect as string;

      await userStore.fetchUser();
      if (userStore.getPartners.length === 1) {
        await userStore.setActivePartnerId(userStore.getPartners[0].id);
        if (redirect) {
          return next({
            path: redirect
          });
        } else {
          return next({
            name: ROUTE_NAMES.DASHBOARD
          });
        }
      }
      return next();
    }
  },
  {
    path: '/auth/access',
    name: ROUTE_NAMES.ACCESS_DENIED,
    component: () => import('@/views/pages/auth/Access.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.ACCESS_DENIED]
    }
  },
  {
    path: '/auth/error',
    name: ROUTE_NAMES.ERROR,
    component: () => import('@/views/pages/auth/Error.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.ERROR]
    }
  }
];
