/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TimeSlotMassEmailRequest
 */
export interface TimeSlotMassEmailRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof TimeSlotMassEmailRequest
   */
  time_slot_ids: Array<string>;
  /**
   *
   * @type {string}
   * @memberof TimeSlotMassEmailRequest
   */
  email_template_id: string;
  /**
   *
   * @type {string}
   * @memberof TimeSlotMassEmailRequest
   */
  partner_id: string;
  /**
   *
   * @type {boolean}
   * @memberof TimeSlotMassEmailRequest
   */
  send_to_cancelled_bookings: boolean;
}

/**
 * Check if a given object implements the TimeSlotMassEmailRequest interface.
 */
export function instanceOfTimeSlotMassEmailRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'time_slot_ids' in value;
  isInstance = isInstance && 'email_template_id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'send_to_cancelled_bookings' in value;

  return isInstance;
}

export function TimeSlotMassEmailRequestFromJSON(json: any): TimeSlotMassEmailRequest {
  return TimeSlotMassEmailRequestFromJSONTyped(json, false);
}

export function TimeSlotMassEmailRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TimeSlotMassEmailRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    time_slot_ids: json['time_slot_ids'],
    email_template_id: json['email_template_id'],
    partner_id: json['partner_id'],
    send_to_cancelled_bookings: json['send_to_cancelled_bookings']
  };
}

export function TimeSlotMassEmailRequestToJSON(value?: TimeSlotMassEmailRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    time_slot_ids: value.time_slot_ids,
    email_template_id: value.email_template_id,
    partner_id: value.partner_id,
    send_to_cancelled_bookings: value.send_to_cancelled_bookings
  };
}
