/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { IntegrationReadResponseAvailableProductsInner } from './IntegrationReadResponseAvailableProductsInner';
import {
  IntegrationReadResponseAvailableProductsInnerFromJSON,
  IntegrationReadResponseAvailableProductsInnerFromJSONTyped,
  IntegrationReadResponseAvailableProductsInnerToJSON
} from './IntegrationReadResponseAvailableProductsInner';
import type { IntegrationReadResponseIntegration } from './IntegrationReadResponseIntegration';
import {
  IntegrationReadResponseIntegrationFromJSON,
  IntegrationReadResponseIntegrationFromJSONTyped,
  IntegrationReadResponseIntegrationToJSON
} from './IntegrationReadResponseIntegration';

/**
 *
 * @export
 * @interface IntegrationReadResponse
 */
export interface IntegrationReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof IntegrationReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof IntegrationReadResponse
   */
  error_code?: IntegrationReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof IntegrationReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<IntegrationReadResponseAvailableProductsInner>}
   * @memberof IntegrationReadResponse
   */
  available_products?: Array<IntegrationReadResponseAvailableProductsInner>;
  /**
   *
   * @type {IntegrationReadResponseIntegration}
   * @memberof IntegrationReadResponse
   */
  integration?: IntegrationReadResponseIntegration;
}

/**
 * @export
 */
export const IntegrationReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type IntegrationReadResponseErrorCodeEnum =
  (typeof IntegrationReadResponseErrorCodeEnum)[keyof typeof IntegrationReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the IntegrationReadResponse interface.
 */
export function instanceOfIntegrationReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function IntegrationReadResponseFromJSON(json: any): IntegrationReadResponse {
  return IntegrationReadResponseFromJSONTyped(json, false);
}

export function IntegrationReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IntegrationReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    available_products: !exists(json, 'available_products')
      ? undefined
      : (json['available_products'] as Array<any>).map(
          IntegrationReadResponseAvailableProductsInnerFromJSON
        ),
    integration: !exists(json, 'integration')
      ? undefined
      : IntegrationReadResponseIntegrationFromJSON(json['integration'])
  };
}

export function IntegrationReadResponseToJSON(value?: IntegrationReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    available_products:
      value.available_products === undefined
        ? undefined
        : (value.available_products as Array<any>).map(
            IntegrationReadResponseAvailableProductsInnerToJSON
          ),
    integration: IntegrationReadResponseIntegrationToJSON(value.integration)
  };
}
