import { useRouter } from 'vue-router';
import { ROUTE_NAMES } from '@/constants/routes';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';

export function usePartnerRedirect() {
  const router = useRouter();
  const userStore = useUserStore();
  const { getActivePartnerIdSafe } = storeToRefs(userStore);

  const redirectToPartnerSelection = (redirectPath?: string) => {
    const query = redirectPath ? { redirect: redirectPath } : undefined;
    return router.push({
      name: ROUTE_NAMES.SELECT_PARTNER,
      query
    });
  };

  const requirePartner = async () => {
    if (!getActivePartnerIdSafe.value) {
      await redirectToPartnerSelection(router.currentRoute.value.path);
    }
  };

  return {
    redirectToPartnerSelection,
    requirePartner
  };
}
