export const isGygStaff = () => {
  return window.location.href.includes('gyg_staff=true');
};

export const isGygAdminEmail = (email: string | null): boolean => {
  // If email is not provided, we can't determine if it's a GYG staff
  if (!email) {
    return false;
  }

  // TESTXXX emails are used for testing purposes and should not be considered as GYG staff
  // even though they contain the getyourguide.com domain (e.g. TESTM1111@getyourguide.com)
  if (email.startsWith('TEST')) {
    return false;
  }

  // If the email ends with @getyourguide.com, we can consider it as a GYG staff
  return email.endsWith('@getyourguide.com');
};
