/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SupportedCountriesReadResponseSupportedCountriesInner } from './SupportedCountriesReadResponseSupportedCountriesInner';
import {
  SupportedCountriesReadResponseSupportedCountriesInnerFromJSON,
  SupportedCountriesReadResponseSupportedCountriesInnerFromJSONTyped,
  SupportedCountriesReadResponseSupportedCountriesInnerToJSON
} from './SupportedCountriesReadResponseSupportedCountriesInner';

/**
 *
 * @export
 * @interface SupportedCountriesReadResponse
 */
export interface SupportedCountriesReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof SupportedCountriesReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof SupportedCountriesReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof SupportedCountriesReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof SupportedCountriesReadResponse
   */
  error_code?: SupportedCountriesReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof SupportedCountriesReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<SupportedCountriesReadResponseSupportedCountriesInner>}
   * @memberof SupportedCountriesReadResponse
   */
  supported_countries?: Array<SupportedCountriesReadResponseSupportedCountriesInner>;
}

/**
 * @export
 */
export const SupportedCountriesReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type SupportedCountriesReadResponseErrorCodeEnum =
  (typeof SupportedCountriesReadResponseErrorCodeEnum)[keyof typeof SupportedCountriesReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the SupportedCountriesReadResponse interface.
 */
export function instanceOfSupportedCountriesReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function SupportedCountriesReadResponseFromJSON(json: any): SupportedCountriesReadResponse {
  return SupportedCountriesReadResponseFromJSONTyped(json, false);
}

export function SupportedCountriesReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupportedCountriesReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    supported_countries: !exists(json, 'supported_countries')
      ? undefined
      : (json['supported_countries'] as Array<any>).map(
          SupportedCountriesReadResponseSupportedCountriesInnerFromJSON
        )
  };
}

export function SupportedCountriesReadResponseToJSON(
  value?: SupportedCountriesReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    supported_countries:
      value.supported_countries === undefined
        ? undefined
        : (value.supported_countries as Array<any>).map(
            SupportedCountriesReadResponseSupportedCountriesInnerToJSON
          )
  };
}
