/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingCategoriesReadResponseMeta } from './PricingCategoriesReadResponseMeta';
import {
  PricingCategoriesReadResponseMetaFromJSON,
  PricingCategoriesReadResponseMetaFromJSONTyped,
  PricingCategoriesReadResponseMetaToJSON
} from './PricingCategoriesReadResponseMeta';
import type { PricingCategoriesReadResponsePricingCategoriesInner } from './PricingCategoriesReadResponsePricingCategoriesInner';
import {
  PricingCategoriesReadResponsePricingCategoriesInnerFromJSON,
  PricingCategoriesReadResponsePricingCategoriesInnerFromJSONTyped,
  PricingCategoriesReadResponsePricingCategoriesInnerToJSON
} from './PricingCategoriesReadResponsePricingCategoriesInner';

/**
 *
 * @export
 * @interface PricingCategoriesReadResponse
 */
export interface PricingCategoriesReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PricingCategoriesReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PricingCategoriesReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PricingCategoriesReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof PricingCategoriesReadResponse
   */
  error_code?: PricingCategoriesReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof PricingCategoriesReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {PricingCategoriesReadResponseMeta}
   * @memberof PricingCategoriesReadResponse
   */
  meta: PricingCategoriesReadResponseMeta;
  /**
   *
   * @type {Array<PricingCategoriesReadResponsePricingCategoriesInner>}
   * @memberof PricingCategoriesReadResponse
   */
  pricing_categories: Array<PricingCategoriesReadResponsePricingCategoriesInner>;
}

/**
 * @export
 */
export const PricingCategoriesReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type PricingCategoriesReadResponseErrorCodeEnum =
  (typeof PricingCategoriesReadResponseErrorCodeEnum)[keyof typeof PricingCategoriesReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the PricingCategoriesReadResponse interface.
 */
export function instanceOfPricingCategoriesReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'pricing_categories' in value;

  return isInstance;
}

export function PricingCategoriesReadResponseFromJSON(json: any): PricingCategoriesReadResponse {
  return PricingCategoriesReadResponseFromJSONTyped(json, false);
}

export function PricingCategoriesReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingCategoriesReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    meta: PricingCategoriesReadResponseMetaFromJSON(json['meta']),
    pricing_categories: (json['pricing_categories'] as Array<any>).map(
      PricingCategoriesReadResponsePricingCategoriesInnerFromJSON
    )
  };
}

export function PricingCategoriesReadResponseToJSON(
  value?: PricingCategoriesReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    meta: PricingCategoriesReadResponseMetaToJSON(value.meta),
    pricing_categories: (value.pricing_categories as Array<any>).map(
      PricingCategoriesReadResponsePricingCategoriesInnerToJSON
    )
  };
}
