/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CartCreateResponse
 */
export interface CartCreateResponse {
  /**
   *
   * @type {boolean}
   * @memberof CartCreateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof CartCreateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof CartCreateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof CartCreateResponse
   */
  error_code?: CartCreateResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof CartCreateResponse
   */
  error_message?: string;
  /**
   *
   * @type {string}
   * @memberof CartCreateResponse
   */
  hash_code: string;
  /**
   *
   * @type {string}
   * @memberof CartCreateResponse
   */
  integration_id: string;
}

/**
 * @export
 */
export const CartCreateResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type CartCreateResponseErrorCodeEnum =
  (typeof CartCreateResponseErrorCodeEnum)[keyof typeof CartCreateResponseErrorCodeEnum];

/**
 * Check if a given object implements the CartCreateResponse interface.
 */
export function instanceOfCartCreateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'integration_id' in value;

  return isInstance;
}

export function CartCreateResponseFromJSON(json: any): CartCreateResponse {
  return CartCreateResponseFromJSONTyped(json, false);
}

export function CartCreateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CartCreateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    hash_code: json['hash_code'],
    integration_id: json['integration_id']
  };
}

export function CartCreateResponseToJSON(value?: CartCreateResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    hash_code: value.hash_code,
    integration_id: value.integration_id
  };
}
