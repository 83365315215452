/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductTranslationUpdateResponseProductTranslationsInner } from './ProductTranslationUpdateResponseProductTranslationsInner';
import {
  ProductTranslationUpdateResponseProductTranslationsInnerFromJSON,
  ProductTranslationUpdateResponseProductTranslationsInnerFromJSONTyped,
  ProductTranslationUpdateResponseProductTranslationsInnerToJSON
} from './ProductTranslationUpdateResponseProductTranslationsInner';

/**
 *
 * @export
 * @interface ProductTranslationUpdateResponse
 */
export interface ProductTranslationUpdateResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductTranslationUpdateResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductTranslationUpdateResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTranslationUpdateResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ProductTranslationUpdateResponse
   */
  error_code?: ProductTranslationUpdateResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ProductTranslationUpdateResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<ProductTranslationUpdateResponseProductTranslationsInner>}
   * @memberof ProductTranslationUpdateResponse
   */
  product_translations: Array<ProductTranslationUpdateResponseProductTranslationsInner>;
}

/**
 * @export
 */
export const ProductTranslationUpdateResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ProductTranslationUpdateResponseErrorCodeEnum =
  (typeof ProductTranslationUpdateResponseErrorCodeEnum)[keyof typeof ProductTranslationUpdateResponseErrorCodeEnum];

/**
 * Check if a given object implements the ProductTranslationUpdateResponse interface.
 */
export function instanceOfProductTranslationUpdateResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_translations' in value;

  return isInstance;
}

export function ProductTranslationUpdateResponseFromJSON(
  json: any
): ProductTranslationUpdateResponse {
  return ProductTranslationUpdateResponseFromJSONTyped(json, false);
}

export function ProductTranslationUpdateResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductTranslationUpdateResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    product_translations: (json['product_translations'] as Array<any>).map(
      ProductTranslationUpdateResponseProductTranslationsInnerFromJSON
    )
  };
}

export function ProductTranslationUpdateResponseToJSON(
  value?: ProductTranslationUpdateResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    product_translations: (value.product_translations as Array<any>).map(
      ProductTranslationUpdateResponseProductTranslationsInnerToJSON
    )
  };
}
