import { useAuth } from '@/composables/use-auth';
import AppLayout from '@/layout/AppLayout.vue';
import { directus } from '@/service/directus';
import { navigateToLoginPage } from '@/service/TicketingApi';
import { useUserStore } from '@/stores/user';
import posthog from 'posthog-js';
import { nextTick } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';

// NAMES
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { ROUTE_NAMES } from '@/constants/routes';

// ROUTES
import { ADDONS_ROUTES } from './addons';
import { AUTH_ROUTES } from './auth';
import { AVAILABILITIES_ROUTES } from './availabilities';
import { CHANNELS_ROUTES } from './channels';
import { COMMUNICATION_ROUTES } from './communication';
import { CUSTOMERS_ROUTES } from './customers';
import { GIFTCODES_ROUTES } from './giftcodes';
import { HOME_ROUTES } from './home';
import { OPTION_ROUTES } from './options';
import { ORDERS_ROUTES } from './orders';
import { PRICINGCATEGORIES_ROUTES } from './pricingcategories';
import { PRODUCT_ROUTES } from './products';
import { PROMOCODES_ROUTES } from './promocodes';
import { QUESTIONS_ROUTES } from './questions';
import { REPORTS_ROUTES } from './reports';
import { SCANNER_ROUTES } from './scanner';
import { SETTINGS_ROUTES } from './settings';
import { USERS_ROUTES } from './users';
import { usePartnerRedirect } from '@/composables/use-partner-redirect';
import { RESELLERS_ROUTES } from './resellers';
import { RESELLER_ORDERS_ROUTES } from './reseller-orders';

export const authRoutes = [
  ROUTE_NAMES.LOGIN,
  ROUTE_NAMES.REGISTER,
  ROUTE_NAMES.REGISTER_CREATE_ACCOUNT,
  ROUTE_NAMES.REGISTER_BUSINESS_INFORMATION,
  ROUTE_NAMES.REGISTER_CONFIRMATION,
  ROUTE_NAMES.RESET_PASSWORD,
  ROUTE_NAMES.UPDATE_PASSWORD
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...AUTH_ROUTES,
    {
      path: '/',
      redirect: { name: ROUTE_NAMES.DASHBOARD },
      component: AppLayout,
      beforeEnter: async (to, from, next) => {
        const { checkUserRole } = useAuth();
        await checkUserRole();
        next();
      },
      children: [
        // Heads-up: this also determines the order the pages show in the main menu (if tagged as 'main')
        ...HOME_ROUTES,
        ...REPORTS_ROUTES,

        ...ORDERS_ROUTES,
        ...RESELLER_ORDERS_ROUTES,
        ...CUSTOMERS_ROUTES,
        ...AVAILABILITIES_ROUTES,
        ...CHANNELS_ROUTES,

        ...PRODUCT_ROUTES,
        ...OPTION_ROUTES,
        ...PRICINGCATEGORIES_ROUTES,
        ...ADDONS_ROUTES,
        ...QUESTIONS_ROUTES,
        ...RESELLERS_ROUTES,

        ...PROMOCODES_ROUTES,
        ...GIFTCODES_ROUTES,
        ...COMMUNICATION_ROUTES,

        ...SCANNER_ROUTES,

        ...USERS_ROUTES,
        ...SETTINGS_ROUTES,

        {
          path: '/profile',
          name: ROUTE_NAMES.PROFILE,
          component: () => import('@/views/Profile.vue'),
          meta: {
            breadcrumbs: [PAGE_TITLES.PROFILE]
          }
        },
        {
          path: '/not-found',
          name: ROUTE_NAMES.NOT_FOUND,
          component: () => import('@/views/NotFound.vue'),
          meta: {
            breadcrumbs: [PAGE_TITLES.NOT_FOUND]
          }
        }
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/not-found'
    }
  ],
  scrollBehavior() {
    return { top: 0 }; // always scroll to top
  }
});

/**
 * Global navigation guard that handles authentication, partner selection, and route protection
 *
 * Flow:
 * 1. Allows access to auth routes without any checks
 * 2. Verifies user authentication
 *    - Redirects to login if not authenticated
 *    - Redirects to partner selection if logged in and trying to access login page
 * 3. Handles partner context
 *    - Sets active partner from URL query parameter if present
 *    - Ensures partner is selected for non-partner-selection routes
 *    - Maintains partner_id in URL query parameters
 *
 * @param {RouteLocationNormalized} to - Target route the user is navigating to
 * @returns {Promise<void | RouteLocationRaw | boolean>}
 *    - void: allows navigation to proceed
 *    - false: cancels navigation
 *    - RouteLocationRaw: redirects to a new location
 * @throws Will redirect to login page if any error occurs during navigation guard execution
 */
router.beforeEach(async (to) => {
  try {
    const userStore = useUserStore();
    const { requirePartner } = usePartnerRedirect();
    const desiredRouteName = to.name?.toString();
    const queryPartnerId = to.query.partner_id;

    // Skip auth check for auth routes
    if (desiredRouteName && authRoutes.includes(desiredRouteName)) {
      return;
    }

    // Check authentication
    const isLoggedIn = await directus.getToken();

    // Handle login redirections
    if (!isLoggedIn) {
      if (to.name === ROUTE_NAMES.LOGIN) {
        return; // Allow access to login page
      }
      await navigateToLoginPage(to);
      return false;
    }

    // Redirect from login page if already logged in
    if (isLoggedIn && desiredRouteName === ROUTE_NAMES.LOGIN) {
      return {
        name: ROUTE_NAMES.SELECT_PARTNER
      };
    }

    // Handle partner ID from query
    if (queryPartnerId) {
      await userStore.setActivePartnerId(queryPartnerId.toString());
    }

    const isPartnerSelectionRoute = desiredRouteName === ROUTE_NAMES.SELECT_PARTNER;
    const hasSelectedPartner = !!userStore.getSelectedPartner;

    // Only check for partner requirement if not on partner selection route
    if (!isPartnerSelectionRoute && !hasSelectedPartner) {
      return requirePartner();
    }

    // Add partner_id to query if missing
    if (hasSelectedPartner && !queryPartnerId) {
      return {
        ...to,
        query: {
          ...to.query,
          partner_id: userStore.getSelectedPartner.id
        }
      };
    }

    return true;
  } catch (error) {
    console.error('Router guard error:', error);
    return { name: ROUTE_NAMES.LOGIN };
  }
});

router.afterEach((to) => {
  nextTick(() => {
    posthog.capture('$pageview', {
      $current_url: to.fullPath
    });
  });
});

export default router;
