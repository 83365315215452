/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserByIdReadResponseUser } from './UserByIdReadResponseUser';
import {
  UserByIdReadResponseUserFromJSON,
  UserByIdReadResponseUserFromJSONTyped,
  UserByIdReadResponseUserToJSON
} from './UserByIdReadResponseUser';

/**
 *
 * @export
 * @interface UserByIdReadResponse
 */
export interface UserByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof UserByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof UserByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof UserByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof UserByIdReadResponse
   */
  error_code?: UserByIdReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof UserByIdReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {UserByIdReadResponseUser}
   * @memberof UserByIdReadResponse
   */
  user: UserByIdReadResponseUser;
}

/**
 * @export
 */
export const UserByIdReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type UserByIdReadResponseErrorCodeEnum =
  (typeof UserByIdReadResponseErrorCodeEnum)[keyof typeof UserByIdReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the UserByIdReadResponse interface.
 */
export function instanceOfUserByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'user' in value;

  return isInstance;
}

export function UserByIdReadResponseFromJSON(json: any): UserByIdReadResponse {
  return UserByIdReadResponseFromJSONTyped(json, false);
}

export function UserByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    user: UserByIdReadResponseUserFromJSON(json['user'])
  };
}

export function UserByIdReadResponseToJSON(value?: UserByIdReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    user: UserByIdReadResponseUserToJSON(value.user)
  };
}
