<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import GetYourGuideLogo from '@/components/GetYourGuideLogo.vue';
import AppUserSideBar from './AppUserSideBar.vue';
import Button from 'primevue/button';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';

const { onMenuToggle } = useLayout();
const userSideBarActive = ref(false);

type ClickListenerFunction = (event: Event) => void;
const outsideClickListener = ref<null | ClickListenerFunction>(null);
const topbarMenuActive = ref(false);

const { getSelectedPartner, getAllowPartnerSelection } = storeToRefs(useUserStore());

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
  onMenuToggle();
};

const topbarMenuClasses = computed(() => {
  return {
    'layout-topbar-menu-mobile-active': topbarMenuActive.value
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener('click', outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener('click', outsideClickListener.value);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event: Event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector('.layout-topbar-menu');
  const topbarEl = document.querySelector('.layout-topbar-menu-button');
  if (!sidebarEl) return false;
  if (!topbarEl) return false;

  function assertIsNode(e: EventTarget | null): e is Node {
    return !(!e || !('nodeType' in e));
  }
  if (!assertIsNode(event.target)) {
    return false;
  }

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
</script>

<template>
  <div class="layout-topbar z-40 justify-between">
    <GetYourGuideLogo />
    <div :class="topbarMenuClasses">
      <span
        v-if="getAllowPartnerSelection && getSelectedPartner"
        class="text-sm font-bold text-gray-500"
      >
        {{ getSelectedPartner?.name }}
      </span>
      <Button
        text
        rounded
        severity="secondary"
        icon="pi pi-user"
        @click="userSideBarActive = true"
      />
      <Button text rounded severity="secondary" icon="pi pi-bars" @click="onTopBarMenuButton" />
    </div>
  </div>
  <AppUserSideBar :is-visible="userSideBarActive" @hide="userSideBarActive = false" />
</template>

<style lang="scss" scoped>
.layout-topbar {
  background: transparent;
  backdrop-filter: blur(2px);
}
</style>
