/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookingByIdReadResponseBookingChannel } from './BookingByIdReadResponseBookingChannel';
import {
  BookingByIdReadResponseBookingChannelFromJSON,
  BookingByIdReadResponseBookingChannelFromJSONTyped,
  BookingByIdReadResponseBookingChannelToJSON
} from './BookingByIdReadResponseBookingChannel';
import type { BookingByIdReadResponseBookingPartnerQuestionAnswers } from './BookingByIdReadResponseBookingPartnerQuestionAnswers';
import {
  BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSON,
  BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSONTyped,
  BookingByIdReadResponseBookingPartnerQuestionAnswersToJSON
} from './BookingByIdReadResponseBookingPartnerQuestionAnswers';
import type { BookingByIdReadResponseBookingPaymentMethodsInner } from './BookingByIdReadResponseBookingPaymentMethodsInner';
import {
  BookingByIdReadResponseBookingPaymentMethodsInnerFromJSON,
  BookingByIdReadResponseBookingPaymentMethodsInnerFromJSONTyped,
  BookingByIdReadResponseBookingPaymentMethodsInnerToJSON
} from './BookingByIdReadResponseBookingPaymentMethodsInner';
import type { BookingByIdReadResponseBookingTicketsInner } from './BookingByIdReadResponseBookingTicketsInner';
import {
  BookingByIdReadResponseBookingTicketsInnerFromJSON,
  BookingByIdReadResponseBookingTicketsInnerFromJSONTyped,
  BookingByIdReadResponseBookingTicketsInnerToJSON
} from './BookingByIdReadResponseBookingTicketsInner';

/**
 *
 * @export
 * @interface BookingByIdReadResponseBooking
 */
export interface BookingByIdReadResponseBooking {
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  hash_code: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  product_title: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  product_option_title: string;
  /**
   * Text summary of pricing categories purchased in a booking
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  participant_summary: string;
  /**
   * Text summary of addons purchased in a booking
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  addon_summary: string | null;
  /**
   *
   * @type {number}
   * @memberof BookingByIdReadResponseBooking
   */
  total_price: number;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  total_price_formatted: string;
  /**
   *
   * @type {number}
   * @memberof BookingByIdReadResponseBooking
   */
  cancellation_policy_refund_amount: number;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  cancellation_policy_refund_amount_formatted: string;
  /**
   *
   * @type {Array<any>}
   * @memberof BookingByIdReadResponseBooking
   */
  cancellation_policy_description: Array<any>;
  /**
   *
   * @type {boolean}
   * @memberof BookingByIdReadResponseBooking
   */
  cancellation_policy_allows_customer_cancellation: boolean;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  currency_code: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  status: BookingByIdReadResponseBookingStatusEnum;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  visit_datetime: string | null;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  purchase_datetime?: string;
  /**
   *
   * @type {string}
   * @memberof BookingByIdReadResponseBooking
   */
  receipt_file_id: string | null;
  /**
   *
   * @type {Array<BookingByIdReadResponseBookingTicketsInner>}
   * @memberof BookingByIdReadResponseBooking
   */
  tickets: Array<BookingByIdReadResponseBookingTicketsInner>;
  /**
   *
   * @type {BookingByIdReadResponseBookingChannel}
   * @memberof BookingByIdReadResponseBooking
   */
  channel: BookingByIdReadResponseBookingChannel;
  /**
   *
   * @type {Array<BookingByIdReadResponseBookingPaymentMethodsInner>}
   * @memberof BookingByIdReadResponseBooking
   */
  payment_methods: Array<BookingByIdReadResponseBookingPaymentMethodsInner>;
  /**
   *
   * @type {BookingByIdReadResponseBookingPartnerQuestionAnswers}
   * @memberof BookingByIdReadResponseBooking
   */
  partner_question_answers?: BookingByIdReadResponseBookingPartnerQuestionAnswers;
  /**
   *
   * @type {Array<string>}
   * @memberof BookingByIdReadResponseBooking
   */
  redeemed_gift_codes: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BookingByIdReadResponseBooking
   */
  redeemed_promo_codes: Array<string>;
}

/**
 * @export
 */
export const BookingByIdReadResponseBookingStatusEnum = {
  Active: 'active',
  CancelledByCustomer: 'cancelled_by_customer',
  CancelledByPartner: 'cancelled_by_partner',
  DeletedByCustomer: 'deleted_by_customer',
  Processed: 'processed',
  CancelledByAdmin: 'cancelled_by_admin',
  DeletedByCleanupJob: 'deleted_by_cleanup_job',
  DeletedForCloning: 'deleted_for_cloning',
  ReservationSystemUnconfirmed: 'reservation_system_unconfirmed',
  AwaitingPartnerConfirmation: 'awaiting_partner_confirmation',
  PendingPaymentCompletion: 'pending_payment_completion'
} as const;
export type BookingByIdReadResponseBookingStatusEnum =
  (typeof BookingByIdReadResponseBookingStatusEnum)[keyof typeof BookingByIdReadResponseBookingStatusEnum];

/**
 * Check if a given object implements the BookingByIdReadResponseBooking interface.
 */
export function instanceOfBookingByIdReadResponseBooking(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'hash_code' in value;
  isInstance = isInstance && 'product_title' in value;
  isInstance = isInstance && 'product_option_title' in value;
  isInstance = isInstance && 'participant_summary' in value;
  isInstance = isInstance && 'addon_summary' in value;
  isInstance = isInstance && 'total_price' in value;
  isInstance = isInstance && 'total_price_formatted' in value;
  isInstance = isInstance && 'cancellation_policy_refund_amount' in value;
  isInstance = isInstance && 'cancellation_policy_refund_amount_formatted' in value;
  isInstance = isInstance && 'cancellation_policy_description' in value;
  isInstance = isInstance && 'cancellation_policy_allows_customer_cancellation' in value;
  isInstance = isInstance && 'currency_code' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'visit_datetime' in value;
  isInstance = isInstance && 'receipt_file_id' in value;
  isInstance = isInstance && 'tickets' in value;
  isInstance = isInstance && 'channel' in value;
  isInstance = isInstance && 'payment_methods' in value;
  isInstance = isInstance && 'redeemed_gift_codes' in value;
  isInstance = isInstance && 'redeemed_promo_codes' in value;

  return isInstance;
}

export function BookingByIdReadResponseBookingFromJSON(json: any): BookingByIdReadResponseBooking {
  return BookingByIdReadResponseBookingFromJSONTyped(json, false);
}

export function BookingByIdReadResponseBookingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BookingByIdReadResponseBooking {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    hash_code: json['hash_code'],
    product_title: json['product_title'],
    product_option_title: json['product_option_title'],
    participant_summary: json['participant_summary'],
    addon_summary: json['addon_summary'],
    total_price: json['total_price'],
    total_price_formatted: json['total_price_formatted'],
    cancellation_policy_refund_amount: json['cancellation_policy_refund_amount'],
    cancellation_policy_refund_amount_formatted:
      json['cancellation_policy_refund_amount_formatted'],
    cancellation_policy_description: json['cancellation_policy_description'],
    cancellation_policy_allows_customer_cancellation:
      json['cancellation_policy_allows_customer_cancellation'],
    currency_code: json['currency_code'],
    status: json['status'],
    visit_datetime: json['visit_datetime'],
    purchase_datetime: !exists(json, 'purchase_datetime') ? undefined : json['purchase_datetime'],
    receipt_file_id: json['receipt_file_id'],
    tickets: (json['tickets'] as Array<any>).map(
      BookingByIdReadResponseBookingTicketsInnerFromJSON
    ),
    channel: BookingByIdReadResponseBookingChannelFromJSON(json['channel']),
    payment_methods: (json['payment_methods'] as Array<any>).map(
      BookingByIdReadResponseBookingPaymentMethodsInnerFromJSON
    ),
    partner_question_answers: !exists(json, 'partner_question_answers')
      ? undefined
      : BookingByIdReadResponseBookingPartnerQuestionAnswersFromJSON(
          json['partner_question_answers']
        ),
    redeemed_gift_codes: json['redeemed_gift_codes'],
    redeemed_promo_codes: json['redeemed_promo_codes']
  };
}

export function BookingByIdReadResponseBookingToJSON(
  value?: BookingByIdReadResponseBooking | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    hash_code: value.hash_code,
    product_title: value.product_title,
    product_option_title: value.product_option_title,
    participant_summary: value.participant_summary,
    addon_summary: value.addon_summary,
    total_price: value.total_price,
    total_price_formatted: value.total_price_formatted,
    cancellation_policy_refund_amount: value.cancellation_policy_refund_amount,
    cancellation_policy_refund_amount_formatted: value.cancellation_policy_refund_amount_formatted,
    cancellation_policy_description: value.cancellation_policy_description,
    cancellation_policy_allows_customer_cancellation:
      value.cancellation_policy_allows_customer_cancellation,
    currency_code: value.currency_code,
    status: value.status,
    visit_datetime: value.visit_datetime,
    purchase_datetime: value.purchase_datetime,
    receipt_file_id: value.receipt_file_id,
    tickets: (value.tickets as Array<any>).map(BookingByIdReadResponseBookingTicketsInnerToJSON),
    channel: BookingByIdReadResponseBookingChannelToJSON(value.channel),
    payment_methods: (value.payment_methods as Array<any>).map(
      BookingByIdReadResponseBookingPaymentMethodsInnerToJSON
    ),
    partner_question_answers: BookingByIdReadResponseBookingPartnerQuestionAnswersToJSON(
      value.partner_question_answers
    ),
    redeemed_gift_codes: value.redeemed_gift_codes,
    redeemed_promo_codes: value.redeemed_promo_codes
  };
}
