/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyReadResponseMeta } from './CancellationPolicyReadResponseMeta';
import {
  CancellationPolicyReadResponseMetaFromJSON,
  CancellationPolicyReadResponseMetaFromJSONTyped,
  CancellationPolicyReadResponseMetaToJSON
} from './CancellationPolicyReadResponseMeta';
import type { EmailTemplatesReadResponseEmailTemplatesInner } from './EmailTemplatesReadResponseEmailTemplatesInner';
import {
  EmailTemplatesReadResponseEmailTemplatesInnerFromJSON,
  EmailTemplatesReadResponseEmailTemplatesInnerFromJSONTyped,
  EmailTemplatesReadResponseEmailTemplatesInnerToJSON
} from './EmailTemplatesReadResponseEmailTemplatesInner';

/**
 *
 * @export
 * @interface EmailTemplatesReadResponse
 */
export interface EmailTemplatesReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof EmailTemplatesReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesReadResponse
   */
  error_code?: EmailTemplatesReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof EmailTemplatesReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<EmailTemplatesReadResponseEmailTemplatesInner>}
   * @memberof EmailTemplatesReadResponse
   */
  email_templates: Array<EmailTemplatesReadResponseEmailTemplatesInner>;
  /**
   *
   * @type {CancellationPolicyReadResponseMeta}
   * @memberof EmailTemplatesReadResponse
   */
  meta: CancellationPolicyReadResponseMeta;
}

/**
 * @export
 */
export const EmailTemplatesReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type EmailTemplatesReadResponseErrorCodeEnum =
  (typeof EmailTemplatesReadResponseErrorCodeEnum)[keyof typeof EmailTemplatesReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the EmailTemplatesReadResponse interface.
 */
export function instanceOfEmailTemplatesReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'email_templates' in value;
  isInstance = isInstance && 'meta' in value;

  return isInstance;
}

export function EmailTemplatesReadResponseFromJSON(json: any): EmailTemplatesReadResponse {
  return EmailTemplatesReadResponseFromJSONTyped(json, false);
}

export function EmailTemplatesReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTemplatesReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    email_templates: (json['email_templates'] as Array<any>).map(
      EmailTemplatesReadResponseEmailTemplatesInnerFromJSON
    ),
    meta: CancellationPolicyReadResponseMetaFromJSON(json['meta'])
  };
}

export function EmailTemplatesReadResponseToJSON(value?: EmailTemplatesReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    email_templates: (value.email_templates as Array<any>).map(
      EmailTemplatesReadResponseEmailTemplatesInnerToJSON
    ),
    meta: CancellationPolicyReadResponseMetaToJSON(value.meta)
  };
}
