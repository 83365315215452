/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner } from './ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner';
import {
  ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerFromJSON,
  ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerFromJSONTyped,
  ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerToJSON
} from './ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner';
import type { ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner } from './ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner';
import {
  ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerFromJSON,
  ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerFromJSONTyped,
  ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerToJSON
} from './ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner';

/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationReadResponseCheckoutFields
 */
export interface ChannelProductOptionChannelConfigurationReadResponseCheckoutFields {
  /**
   *
   * @type {Array<ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner>}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFields
   */
  available: Array<ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner>;
  /**
   *
   * @type {Array<ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner>}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFields
   */
  selected: Array<ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInner>;
}

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationReadResponseCheckoutFields interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationReadResponseCheckoutFields(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'available' in value;
  isInstance = isInstance && 'selected' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationReadResponseCheckoutFields {
  return ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsFromJSONTyped(
    json,
    false
  );
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationReadResponseCheckoutFields {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    available: (json['available'] as Array<any>).map(
      ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerFromJSON
    ),
    selected: (json['selected'] as Array<any>).map(
      ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerFromJSON
    )
  };
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsToJSON(
  value?: ChannelProductOptionChannelConfigurationReadResponseCheckoutFields | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    available: (value.available as Array<any>).map(
      ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerToJSON
    ),
    selected: (value.selected as Array<any>).map(
      ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsSelectedInnerToJSON
    )
  };
}
