/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResellerReadResponseDataInner
 */
export interface ResellerReadResponseDataInner {
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  commission_type: ResellerReadResponseDataInnerCommissionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  status: ResellerReadResponseDataInnerStatusEnum;
  /**
   *
   * @type {Date}
   * @memberof ResellerReadResponseDataInner
   */
  created_at: Date;
  /**
   *
   * @type {number}
   * @memberof ResellerReadResponseDataInner
   */
  commission_amount: number;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  commission_amount_formatted: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  email_address: string;
  /**
   *
   * @type {string}
   * @memberof ResellerReadResponseDataInner
   */
  phone_number: string;
}

/**
 * @export
 */
export const ResellerReadResponseDataInnerCommissionTypeEnum = {
  Absolute: 'absolute',
  Percentage: 'percentage'
} as const;
export type ResellerReadResponseDataInnerCommissionTypeEnum =
  (typeof ResellerReadResponseDataInnerCommissionTypeEnum)[keyof typeof ResellerReadResponseDataInnerCommissionTypeEnum];

/**
 * @export
 */
export const ResellerReadResponseDataInnerStatusEnum = {
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted',
  Invited: 'invited'
} as const;
export type ResellerReadResponseDataInnerStatusEnum =
  (typeof ResellerReadResponseDataInnerStatusEnum)[keyof typeof ResellerReadResponseDataInnerStatusEnum];

/**
 * Check if a given object implements the ResellerReadResponseDataInner interface.
 */
export function instanceOfResellerReadResponseDataInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'commission_type' in value;
  isInstance = isInstance && 'status' in value;
  isInstance = isInstance && 'created_at' in value;
  isInstance = isInstance && 'commission_amount' in value;
  isInstance = isInstance && 'commission_amount_formatted' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email_address' in value;
  isInstance = isInstance && 'phone_number' in value;

  return isInstance;
}

export function ResellerReadResponseDataInnerFromJSON(json: any): ResellerReadResponseDataInner {
  return ResellerReadResponseDataInnerFromJSONTyped(json, false);
}

export function ResellerReadResponseDataInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerReadResponseDataInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    commission_type: json['commission_type'],
    status: json['status'],
    created_at: new Date(json['created_at']),
    commission_amount: json['commission_amount'],
    commission_amount_formatted: json['commission_amount_formatted'],
    name: json['name'],
    last_name: json['last_name'],
    email_address: json['email_address'],
    phone_number: json['phone_number']
  };
}

export function ResellerReadResponseDataInnerToJSON(
  value?: ResellerReadResponseDataInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    commission_type: value.commission_type,
    status: value.status,
    created_at: value.created_at.toISOString(),
    commission_amount: value.commission_amount,
    commission_amount_formatted: value.commission_amount_formatted,
    name: value.name,
    last_name: value.last_name,
    email_address: value.email_address,
    phone_number: value.phone_number
  };
}
