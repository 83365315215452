import { ROUTE_NAMES } from '@/constants/routes';
import { PAGE_TITLES } from '@/constants/breadcrumbs';
import { InitializationReadResponsePermissionsEnum } from '@openapi/models';

// Define the feature flag for resellers
export const RESELLERS_FEATURE_FLAG = 'resellers-management';

export const RESELLERS_ROUTES = [
  {
    path: 'manage/resellers',
    name: ROUTE_NAMES.RESELLERS,
    component: () => import('@/views/pages/manage/reseller/Resellers.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.RESELLERS],
      label: PAGE_TITLES.RESELLERS,
      menu: ['main'],
      permissions: [InitializationReadResponsePermissionsEnum.ResellerView],
      icon: 'pi pi-fw pi-users',
      featureFlag: RESELLERS_FEATURE_FLAG
    }
  },
  {
    path: 'manage/resellers/+',
    name: ROUTE_NAMES.NEW_RESELLER,
    component: () => import('@/views/pages/manage/reseller/ResellerNew.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.RESELLERS, PAGE_TITLES.NEW_RESELLER],
      featureFlag: RESELLERS_FEATURE_FLAG,
      permissions: [InitializationReadResponsePermissionsEnum.ResellerManage]
    }
  },
  {
    path: 'manage/resellers/:resellerId',
    name: ROUTE_NAMES.EDIT_RESELLER,
    component: () => import('@/views/pages/manage/reseller/ResellerEdit.vue'),
    meta: {
      breadcrumbs: [PAGE_TITLES.MANAGE, PAGE_TITLES.RESELLERS, PAGE_TITLES.EDIT_RESELLER],
      featureFlag: RESELLERS_FEATURE_FLAG,
      permissions: [InitializationReadResponsePermissionsEnum.ResellerManage]
    }
  }
];
