/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingByIdReadResponsePricing } from './PricingByIdReadResponsePricing';
import {
  PricingByIdReadResponsePricingFromJSON,
  PricingByIdReadResponsePricingFromJSONTyped,
  PricingByIdReadResponsePricingToJSON
} from './PricingByIdReadResponsePricing';

/**
 *
 * @export
 * @interface PricingByIdReadResponse
 */
export interface PricingByIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof PricingByIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof PricingByIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof PricingByIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof PricingByIdReadResponse
   */
  error_code?: PricingByIdReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof PricingByIdReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {PricingByIdReadResponsePricing}
   * @memberof PricingByIdReadResponse
   */
  pricing: PricingByIdReadResponsePricing;
}

/**
 * @export
 */
export const PricingByIdReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type PricingByIdReadResponseErrorCodeEnum =
  (typeof PricingByIdReadResponseErrorCodeEnum)[keyof typeof PricingByIdReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the PricingByIdReadResponse interface.
 */
export function instanceOfPricingByIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'pricing' in value;

  return isInstance;
}

export function PricingByIdReadResponseFromJSON(json: any): PricingByIdReadResponse {
  return PricingByIdReadResponseFromJSONTyped(json, false);
}

export function PricingByIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingByIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    pricing: PricingByIdReadResponsePricingFromJSON(json['pricing'])
  };
}

export function PricingByIdReadResponseToJSON(value?: PricingByIdReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    pricing: PricingByIdReadResponsePricingToJSON(value.pricing)
  };
}
