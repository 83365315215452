/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner
 */
export interface ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner {
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner
   */
  checkout_field: ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerCheckoutFieldEnum;
  /**
   *
   * @type {boolean}
   * @memberof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner
   */
  is_required: boolean;
}

/**
 * @export
 */
export const ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerCheckoutFieldEnum =
  {
    Company: 'company',
    TaxId: 'tax_id',
    Name: 'name',
    Email: 'email',
    PhoneNr: 'phone_nr',
    Street: 'street',
    City: 'city',
    State: 'state',
    Zip: 'zip',
    Country: 'country'
  } as const;
export type ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerCheckoutFieldEnum =
  (typeof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerCheckoutFieldEnum)[keyof typeof ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerCheckoutFieldEnum];

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'checkout_field' in value;
  isInstance = isInstance && 'is_required' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner {
  return ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerFromJSONTyped(
    json,
    false
  );
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    checkout_field: json['checkout_field'],
    is_required: json['is_required']
  };
}

export function ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInnerToJSON(
  value?: ChannelProductOptionChannelConfigurationReadResponseCheckoutFieldsAvailableInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    checkout_field: value.checkout_field,
    is_required: value.is_required
  };
}
