/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface TicketConfigurationByProductOptionIdReadResponseTicketConfiguration
 */
export interface TicketConfigurationByProductOptionIdReadResponseTicketConfiguration {
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationByProductOptionIdReadResponseTicketConfiguration
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof TicketConfigurationByProductOptionIdReadResponseTicketConfiguration
   */
  external_tickets_low_threshold?: number | null;
  /**
   *
   * @type {string}
   * @memberof TicketConfigurationByProductOptionIdReadResponseTicketConfiguration
   */
  ticket_code_source: TicketConfigurationByProductOptionIdReadResponseTicketConfigurationTicketCodeSourceEnum;
}

/**
 * @export
 */
export const TicketConfigurationByProductOptionIdReadResponseTicketConfigurationTicketCodeSourceEnum =
  {
    GygTicketing: 'gyg_ticketing',
    ExternalTicketCodeTable: 'external_ticket_code_table',
    ReservationSystem: 'reservation_system',
    ExternalTicketPdfTable: 'external_ticket_pdf_table'
  } as const;
export type TicketConfigurationByProductOptionIdReadResponseTicketConfigurationTicketCodeSourceEnum =
  (typeof TicketConfigurationByProductOptionIdReadResponseTicketConfigurationTicketCodeSourceEnum)[keyof typeof TicketConfigurationByProductOptionIdReadResponseTicketConfigurationTicketCodeSourceEnum];

/**
 * Check if a given object implements the TicketConfigurationByProductOptionIdReadResponseTicketConfiguration interface.
 */
export function instanceOfTicketConfigurationByProductOptionIdReadResponseTicketConfiguration(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'ticket_code_source' in value;

  return isInstance;
}

export function TicketConfigurationByProductOptionIdReadResponseTicketConfigurationFromJSON(
  json: any
): TicketConfigurationByProductOptionIdReadResponseTicketConfiguration {
  return TicketConfigurationByProductOptionIdReadResponseTicketConfigurationFromJSONTyped(
    json,
    false
  );
}

export function TicketConfigurationByProductOptionIdReadResponseTicketConfigurationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): TicketConfigurationByProductOptionIdReadResponseTicketConfiguration {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    external_tickets_low_threshold: !exists(json, 'external_tickets_low_threshold')
      ? undefined
      : json['external_tickets_low_threshold'],
    ticket_code_source: json['ticket_code_source']
  };
}

export function TicketConfigurationByProductOptionIdReadResponseTicketConfigurationToJSON(
  value?: TicketConfigurationByProductOptionIdReadResponseTicketConfiguration | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    external_tickets_low_threshold: value.external_tickets_low_threshold,
    ticket_code_source: value.ticket_code_source
  };
}
