/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CancellationPolicyUpdateRequestCancellationPolicyRulesInner } from './CancellationPolicyUpdateRequestCancellationPolicyRulesInner';
import {
  CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSON,
  CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSONTyped,
  CancellationPolicyUpdateRequestCancellationPolicyRulesInnerToJSON
} from './CancellationPolicyUpdateRequestCancellationPolicyRulesInner';

/**
 *
 * @export
 * @interface CancellationPolicyCreateRequest
 */
export interface CancellationPolicyCreateRequest {
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyCreateRequest
   */
  partner_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof CancellationPolicyCreateRequest
   */
  name: string;
  /**
   *
   * @type {Array<CancellationPolicyUpdateRequestCancellationPolicyRulesInner>}
   * @memberof CancellationPolicyCreateRequest
   */
  rules: Array<CancellationPolicyUpdateRequestCancellationPolicyRulesInner>;
  /**
   *
   * @type {boolean}
   * @memberof CancellationPolicyCreateRequest
   */
  allows_customer_cancellation: boolean;
}

/**
 * Check if a given object implements the CancellationPolicyCreateRequest interface.
 */
export function instanceOfCancellationPolicyCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'rules' in value;
  isInstance = isInstance && 'allows_customer_cancellation' in value;

  return isInstance;
}

export function CancellationPolicyCreateRequestFromJSON(
  json: any
): CancellationPolicyCreateRequest {
  return CancellationPolicyCreateRequestFromJSONTyped(json, false);
}

export function CancellationPolicyCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CancellationPolicyCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    name: json['name'],
    rules: (json['rules'] as Array<any>).map(
      CancellationPolicyUpdateRequestCancellationPolicyRulesInnerFromJSON
    ),
    allows_customer_cancellation: json['allows_customer_cancellation']
  };
}

export function CancellationPolicyCreateRequestToJSON(
  value?: CancellationPolicyCreateRequest | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    name: value.name,
    rules: (value.rules as Array<any>).map(
      CancellationPolicyUpdateRequestCancellationPolicyRulesInnerToJSON
    ),
    allows_customer_cancellation: value.allows_customer_cancellation
  };
}
