/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ResellerCreateRequest
 */
export interface ResellerCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  locale_code: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  partner_id?: string | null;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  commission_type: ResellerCreateRequestCommissionTypeEnum;
  /**
   *
   * @type {number}
   * @memberof ResellerCreateRequest
   */
  commission_amount: number;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  last_name: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  email_address: string;
  /**
   *
   * @type {string}
   * @memberof ResellerCreateRequest
   */
  phone_number: string;
}

/**
 * @export
 */
export const ResellerCreateRequestCommissionTypeEnum = {
  Absolute: 'absolute',
  Percentage: 'percentage'
} as const;
export type ResellerCreateRequestCommissionTypeEnum =
  (typeof ResellerCreateRequestCommissionTypeEnum)[keyof typeof ResellerCreateRequestCommissionTypeEnum];

/**
 * Check if a given object implements the ResellerCreateRequest interface.
 */
export function instanceOfResellerCreateRequest(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'locale_code' in value;
  isInstance = isInstance && 'commission_type' in value;
  isInstance = isInstance && 'commission_amount' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'last_name' in value;
  isInstance = isInstance && 'email_address' in value;
  isInstance = isInstance && 'phone_number' in value;

  return isInstance;
}

export function ResellerCreateRequestFromJSON(json: any): ResellerCreateRequest {
  return ResellerCreateRequestFromJSONTyped(json, false);
}

export function ResellerCreateRequestFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ResellerCreateRequest {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    locale_code: json['locale_code'],
    partner_id: !exists(json, 'partner_id') ? undefined : json['partner_id'],
    commission_type: json['commission_type'],
    commission_amount: json['commission_amount'],
    name: json['name'],
    last_name: json['last_name'],
    email_address: json['email_address'],
    phone_number: json['phone_number']
  };
}

export function ResellerCreateRequestToJSON(value?: ResellerCreateRequest | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    locale_code: value.locale_code,
    partner_id: value.partner_id,
    commission_type: value.commission_type,
    commission_amount: value.commission_amount,
    name: value.name,
    last_name: value.last_name,
    email_address: value.email_address,
    phone_number: value.phone_number
  };
}
