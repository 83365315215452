import { datadogRum } from '@datadog/browser-rum';
import { isProduction, PORTAL_RELEASE_VERSION } from '@/config';

export const useDatadog = () => {
  if (!isProduction) {
    return;
  }
  datadogRum.init({
    applicationId: '395c67a9-b294-4960-95c3-8a81cb2e4f43',
    clientToken: 'pub32f5e658c70fe8731a6bbeb7e70a9dbe',
    defaultPrivacyLevel: 'mask-user-input',
    service: 'ticketing-portal',
    env: import.meta.env.MODE,
    version: PORTAL_RELEASE_VERSION.split('@')[1],
    allowedTracingUrls: [
      /^https:\/\/cdn\.getyourguide\.com/,
      /^https:\/\/ticketing-directus\.gygdev\.gygtest\.com/,
      /^https:\/\/ticketing-backend\.getyourguide\.com/
    ],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    enablePrivacyForActionName: true,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true
  });
};
