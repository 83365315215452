import { fetchPortalTranslations } from '@/i18n';
import { configureVeeValidate } from '@/vee-validate';
import { useDatadog } from '@/datadog';

/**
 * All the async calls necessary before starting the app should go here.
 */
export const bootstrapApp = async () => {
  useDatadog();

  // Load translations from the backend
  await fetchPortalTranslations();

  // Translate error messages in vee-validate
  await configureVeeValidate();
};
