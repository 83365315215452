/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner
 */
export interface ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner {
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner
   */
  checkout_field: ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerCheckoutFieldEnum;
  /**
   *
   * @type {boolean}
   * @memberof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner
   */
  is_required: boolean;
}

/**
 * @export
 */
export const ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerCheckoutFieldEnum =
  {
    Company: 'company',
    TaxId: 'tax_id',
    Name: 'name',
    Email: 'email',
    PhoneNr: 'phone_nr',
    Street: 'street',
    City: 'city',
    State: 'state',
    Zip: 'zip',
    Country: 'country'
  } as const;
export type ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerCheckoutFieldEnum =
  (typeof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerCheckoutFieldEnum)[keyof typeof ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerCheckoutFieldEnum];

/**
 * Check if a given object implements the ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner interface.
 */
export function instanceOfChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner(
  value: object
): boolean {
  let isInstance = true;
  isInstance = isInstance && 'checkout_field' in value;
  isInstance = isInstance && 'is_required' in value;

  return isInstance;
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerFromJSON(
  json: any
): ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner {
  return ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerFromJSONTyped(
    json,
    false
  );
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    checkout_field: json['checkout_field'],
    is_required: json['is_required']
  };
}

export function ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInnerToJSON(
  value?: ChannelProductOptionChannelConfigurationBulkUpsertRequestProductOptionChannelConfigurationsInnerCheckoutFieldsInner | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    checkout_field: value.checkout_field,
    is_required: value.is_required
  };
}
