/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductMediaReadResponseProductMediaInner } from './ProductMediaReadResponseProductMediaInner';
import {
  ProductMediaReadResponseProductMediaInnerFromJSON,
  ProductMediaReadResponseProductMediaInnerFromJSONTyped,
  ProductMediaReadResponseProductMediaInnerToJSON
} from './ProductMediaReadResponseProductMediaInner';

/**
 *
 * @export
 * @interface ProductMediaReadResponse
 */
export interface ProductMediaReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ProductMediaReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ProductMediaReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ProductMediaReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ProductMediaReadResponse
   */
  error_code?: ProductMediaReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ProductMediaReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<ProductMediaReadResponseProductMediaInner>}
   * @memberof ProductMediaReadResponse
   */
  product_media: Array<ProductMediaReadResponseProductMediaInner>;
}

/**
 * @export
 */
export const ProductMediaReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ProductMediaReadResponseErrorCodeEnum =
  (typeof ProductMediaReadResponseErrorCodeEnum)[keyof typeof ProductMediaReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the ProductMediaReadResponse interface.
 */
export function instanceOfProductMediaReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'product_media' in value;

  return isInstance;
}

export function ProductMediaReadResponseFromJSON(json: any): ProductMediaReadResponse {
  return ProductMediaReadResponseFromJSONTyped(json, false);
}

export function ProductMediaReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ProductMediaReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    product_media: (json['product_media'] as Array<any>).map(
      ProductMediaReadResponseProductMediaInnerFromJSON
    )
  };
}

export function ProductMediaReadResponseToJSON(value?: ProductMediaReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    product_media: (value.product_media as Array<any>).map(
      ProductMediaReadResponseProductMediaInnerToJSON
    )
  };
}
