<template>
  <Drawer v-model:visible="visible" position="right">
    <div class="mx-auto flex flex-col md:mx-0">
      <h4 class="mb-2 font-semibold">Welcome</h4>
      <span class="text-m font-bold text-muted-color">{{ getFirstName }} {{ getLastName }}</span>
      <span class="mb-8 text-sm text-muted-color">{{ getSelectedPartner?.name }}</span>
      <ul class="m-0 list-none p-0">
        <li>
          <a
            class="mb-4 flex cursor-pointer items-center border p-4 transition-colors duration-150 border-surface rounded-border hover:bg-emphasis"
            @click="onSettingsClick"
          >
            <span><i class="pi pi-cog text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">{{ t('portal_settings') }}</span>
              <p class="m-0 text-muted-color">{{ t('portal_view_profile') }}</p>
            </div>
          </a>
        </li>
        <li v-if="getAllowPartnerSelection || isGygAdminUser">
          <a
            class="mb-4 flex cursor-pointer items-center border p-4 transition-colors duration-150 border-surface rounded-border hover:bg-emphasis"
            @click="onSwitchPartnerClick"
          >
            <span>
              <i
                v-if="onSwitchPartnerLoading"
                class="pi pi-spin pi-spinner text-xl text-primary"
              ></i>
              <i v-else class="pi pi-users text-xl text-primary"></i>
            </span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">{{ t('portal_switch_partner') }}</span>
              <p class="m-0 text-muted-color">{{ t('portal_switch_partner_sub') }}</p>
            </div>
          </a>
        </li>
        <li>
          <a
            class="mb-4 flex cursor-pointer items-center border p-4 transition-colors duration-150 border-surface rounded-border hover:bg-emphasis"
            @click="onSignOutClick"
          >
            <span><i class="pi pi-power-off text-xl text-primary"></i></span>
            <div class="ml-4">
              <span class="mb-2 font-semibold">{{ t('portal_sign_out') }}</span>
              <p class="m-0 text-muted-color">{{ t('portal_sign_out_sub') }}</p>
            </div>
          </a>
        </li>
      </ul>
    </div>

    <div class="mx-auto mt-8 flex flex-col md:mx-0">
      <h4 class="mb-2 font-semibold">{{ t('portal_notifications') }}</h4>
      <span class="mb-8 font-medium text-muted-color">{{ notificationCount }}</span>
    </div>
    <div class="mx-auto mt-8 flex flex-col self-end text-surface-500 md:mx-0 dark:text-surface-300">
      <Button
        disabled
        severity="secondary"
        text
        class="mt-4"
        size="small"
        :label="`version: ${PORTAL_RELEASE_VERSION}`"
        @click="onTriggerError"
      />
    </div>
  </Drawer>
</template>

<script setup lang="ts">
import { useAuth } from '@/composables/use-auth';
import { DIRECTUS_API_URL, PORTAL_RELEASE_VERSION } from '@/config';
import { ROUTE_NAMES } from '@/constants/routes';
import { useUserStore } from '@/stores/user';
import { storeToRefs } from 'pinia';
import Button from 'primevue/button';
import Drawer from 'primevue/drawer';
import { useToast } from 'primevue/usetoast';
import { computed, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
    default: false
  }
});
const { isVisible } = toRefs(props);
const visible = ref(false);
const onSwitchPartnerLoading = ref(false);
const router = useRouter();
const { logout } = useAuth();
const toast = useToast();
const { getAllowPartnerSelection, getSelectedPartner, getFirstName, getLastName, isGygAdminUser } =
  storeToRefs(useUserStore());
watch(isVisible, (value) => {
  visible.value = value;
});
const notificationCount = computed(() => t('portal_notifications_count').replace('%s', '0'));

const onSignOutClick = async () => {
  await logout();

  toast.add({
    severity: 'success',
    summary: t('portal_success_title'),
    detail: t('portal_sign_out_success_text'),
    life: 10000
  });
};

const onSettingsClick = async () => {
  await router.push({ name: ROUTE_NAMES.PROFILE });
  visible.value = false;
};

const onTriggerError = async () => {
  await fetch(`${DIRECTUS_API_URL}/infrastructure/_error`, { method: 'GET' });
  throw new Error('Test error triggered');
};

const onSwitchPartnerClick = async () => {
  onSwitchPartnerLoading.value = true;
  await router.push({ name: ROUTE_NAMES.SELECT_PARTNER });
};
</script>
