/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AddonsReadResponseAddonsInner } from './AddonsReadResponseAddonsInner';
import {
  AddonsReadResponseAddonsInnerFromJSON,
  AddonsReadResponseAddonsInnerFromJSONTyped,
  AddonsReadResponseAddonsInnerToJSON
} from './AddonsReadResponseAddonsInner';
import type { AddonsReadResponseMeta } from './AddonsReadResponseMeta';
import {
  AddonsReadResponseMetaFromJSON,
  AddonsReadResponseMetaFromJSONTyped,
  AddonsReadResponseMetaToJSON
} from './AddonsReadResponseMeta';

/**
 *
 * @export
 * @interface AddonsReadResponse
 */
export interface AddonsReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof AddonsReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof AddonsReadResponse
   */
  error_code?: AddonsReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof AddonsReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {AddonsReadResponseMeta}
   * @memberof AddonsReadResponse
   */
  meta: AddonsReadResponseMeta;
  /**
   *
   * @type {Array<AddonsReadResponseAddonsInner>}
   * @memberof AddonsReadResponse
   */
  addons: Array<AddonsReadResponseAddonsInner>;
}

/**
 * @export
 */
export const AddonsReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type AddonsReadResponseErrorCodeEnum =
  (typeof AddonsReadResponseErrorCodeEnum)[keyof typeof AddonsReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the AddonsReadResponse interface.
 */
export function instanceOfAddonsReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'meta' in value;
  isInstance = isInstance && 'addons' in value;

  return isInstance;
}

export function AddonsReadResponseFromJSON(json: any): AddonsReadResponse {
  return AddonsReadResponseFromJSONTyped(json, false);
}

export function AddonsReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddonsReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    meta: AddonsReadResponseMetaFromJSON(json['meta']),
    addons: (json['addons'] as Array<any>).map(AddonsReadResponseAddonsInnerFromJSON)
  };
}

export function AddonsReadResponseToJSON(value?: AddonsReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    meta: AddonsReadResponseMetaToJSON(value.meta),
    addons: (value.addons as Array<any>).map(AddonsReadResponseAddonsInnerToJSON)
  };
}
