import type { InitializationReadResponsePermissionsEnum } from '@openapi/models';
import { computed } from 'vue';
import { useRouter, type RouteRecordRaw } from 'vue-router';
import usePermissions from './use-permissions';
import { ROUTE_NAMES } from '@/constants/routes';
import posthog from 'posthog-js';

export default function useRouteHandling() {
  const router = useRouter();
  const { hasPermission } = usePermissions();
  const primaryRoute = computed(() => router.options.routes.find((route) => route.path === '/'));

  const isMainMenu = (route: RouteRecordRaw) =>
    route.meta?.menu && Array.isArray(route.meta?.menu) && route.meta?.menu.includes('main');

  const hasPermissions = (route: RouteRecordRaw) =>
    hasPermission(
      (route.meta?.permissions as Array<InitializationReadResponsePermissionsEnum>) || []
    );

  const hasFeatureFlag = (route: RouteRecordRaw) => {
    // If no feature flag is specified, the route is enabled by default
    if (!route.meta?.featureFlag) {
      return true;
    }
    // Otherwise, check if the feature flag is enabled
    return posthog.isFeatureEnabled(route.meta.featureFlag as string);
  };

  const mainMenuRoutesBasedOnPermissions = computed(() =>
    primaryRoute.value?.children?.filter(isMainMenu)?.filter(hasPermissions)?.filter(hasFeatureFlag)
  );

  const getFirstRouteNameBasedOnPermissions = computed(() => {
    if (!mainMenuRoutesBasedOnPermissions.value) return ROUTE_NAMES.DASHBOARD;

    const route = mainMenuRoutesBasedOnPermissions.value.find((r) =>
      hasPermission(r.meta?.permissions as Array<InitializationReadResponsePermissionsEnum>)
    );
    return route?.name || ROUTE_NAMES.DASHBOARD;
  });

  return {
    mainMenuRoutesBasedOnPermissions,
    getFirstRouteNameBasedOnPermissions
  };
}
