/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner } from './ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner';
import {
  ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerFromJSON,
  ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerFromJSONTyped,
  ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerToJSON
} from './ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner';

/**
 *
 * @export
 * @interface ExternalTicketPdfsByProductOptionIdReadResponse
 */
export interface ExternalTicketPdfsByProductOptionIdReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponse
   */
  error_code?: ExternalTicketPdfsByProductOptionIdReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner>}
   * @memberof ExternalTicketPdfsByProductOptionIdReadResponse
   */
  external_ticket_pdfs?: Array<ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInner>;
}

/**
 * @export
 */
export const ExternalTicketPdfsByProductOptionIdReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type ExternalTicketPdfsByProductOptionIdReadResponseErrorCodeEnum =
  (typeof ExternalTicketPdfsByProductOptionIdReadResponseErrorCodeEnum)[keyof typeof ExternalTicketPdfsByProductOptionIdReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the ExternalTicketPdfsByProductOptionIdReadResponse interface.
 */
export function instanceOfExternalTicketPdfsByProductOptionIdReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;

  return isInstance;
}

export function ExternalTicketPdfsByProductOptionIdReadResponseFromJSON(
  json: any
): ExternalTicketPdfsByProductOptionIdReadResponse {
  return ExternalTicketPdfsByProductOptionIdReadResponseFromJSONTyped(json, false);
}

export function ExternalTicketPdfsByProductOptionIdReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ExternalTicketPdfsByProductOptionIdReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    external_ticket_pdfs: !exists(json, 'external_ticket_pdfs')
      ? undefined
      : (json['external_ticket_pdfs'] as Array<any>).map(
          ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerFromJSON
        )
  };
}

export function ExternalTicketPdfsByProductOptionIdReadResponseToJSON(
  value?: ExternalTicketPdfsByProductOptionIdReadResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    external_ticket_pdfs:
      value.external_ticket_pdfs === undefined
        ? undefined
        : (value.external_ticket_pdfs as Array<any>).map(
            ExternalTicketPdfsByProductOptionIdReadResponseExternalTicketPdfsInnerToJSON
          )
  };
}
