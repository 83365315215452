export const ROUTE_NAMES = {
  ADDONS: 'addons',
  ACCESS_DENIED: 'access-denied',
  ALLOCATIONS: 'allocations',
  AVAILABILITIES: 'availabilities',
  BOOKING_SCANNER: 'booking-scanner',
  BOOKING: 'booking',
  BOOKINGS_TABLE: 'bookings-table',
  ORDERS: 'orders',
  BUSINESS_INFORMATION: 'business-information',
  CHANNELS: 'channels',
  QUESTIONS: 'questions',
  CHANNEL_LIST: 'channel-list',
  CHANNEL_DETAILS: 'channel-details',
  CHANNEL_SETTINGS: 'channel-settings',
  CHANNEL_PRODUCT_CONNECTIONS: 'channel-product-connections',
  CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_EDIT: 'channel-product-option-configuration-edit',
  CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_NEW: 'channel-product-option-configuration-new',
  CUSTOMERS: 'customers',
  CUSTOMIZATION_SETTINGS: 'customization-settings',
  INTEGRATION_SETTINGS: 'integration-settings',
  INTEGRATION_EDIT: 'integration-edit',
  DASHBOARD: 'dashboard',
  PRICING_AVAILABILITY: 'pricing-availability',
  EDIT_ADDON: 'edit-addon',
  EDIT_AVAILABILITY: 'edit-availability',
  EDIT_CONNECTION: 'edit-connection',
  EDIT_CUSTOMER: 'edit-customer',
  EDIT_GIFT_CODE: 'edit-gift-code',
  EDIT_PRICING_CATEGORY: 'edit-pricing-category',
  EDIT_PRODUCT_ADDRESS: 'edit-product-address',
  EDIT_PRODUCT_INFO: 'edit-product-information',
  EDIT_PRODUCT_MEDIA: 'edit-product-media',
  EDIT_PRODUCT_OPTION: 'edit-product-option',
  EDIT_PRODUCT_OPTIONS: 'edit-product-options',
  EDIT_PRODUCT_PRICING: 'edit-product-pricing',
  EDIT_PRODUCT_SETTINGS: 'edit-product-settings',
  EDIT_PRODUCT_OPTION_INFO: 'edit-product-option-information',
  EDIT_PRODUCT_OPTION_SETTINGS: 'edit-product-option-settings',
  EDIT_PRODUCT_OPTION_ADDRESS: 'edit-product-option-address',
  EDIT_PRODUCT_OPTION_CHANNELS: 'edit-product-option-channels',
  EDIT_PRODUCT_OPTION_QUESTIONS: 'edit-product-option-questions',
  EDIT_PRODUCT_OPTION_TICKET_CONFIGURATION: 'edit-product-option-ticket-configuration',
  EDIT_PRODUCT: 'edit-product',
  EDIT_PROMO_CODE: 'edit-promo-code',
  EDIT_QUESTION: 'edit-question',
  EDIT_QUESTION_CHOICE: 'edit-question-choice',
  NEW_QUESTION_CHOICE: 'new-question-choice',
  EDIT_USER: 'edit-user',
  EDIT_CANCELLATION_POLICY: 'edit-cancellation-policy',
  EMAIL_SETTINGS: 'email-settings',
  EMPTY: 'empty',
  ERROR: 'error',
  INVITE_USER: 'invite-user',
  SELECT_PARTNER: 'select-partner',
  LOGIN: 'login',
  NEW_ADDON: 'new-addon',
  NEW_CONNECTION: 'new-connection',
  NEW_PRICING_CATEGORY: 'new-pricing-category',
  NEW_PRODUCT_OPTION: 'new-product-option',
  NEW_PRICING_AVAILABILITY: 'new-pricing-availability',
  NEW_FLEXIBLE_PRICING: 'new-flexible-pricing',
  NEW_PRODUCT: 'new-product',
  NEW_PROMO_CODE: 'new-promo-code',
  NEW_QUESTION: 'new-question',
  NEW_GIFT_CODE: 'new-gift-code',
  NEW_CANCELLATION_POLICY: 'new-cancellation-policy',
  GIFT_CODES: 'gift-codes',
  GIFT_CODE_ORDERS: 'gift-code-orders',
  NEWSLETTER_SETTINGS: 'newsletter-settings',
  NOT_FOUND: 'not-found',
  PAYMENT_PROVIDERS: 'payment-providers',
  PAYOUT: 'payout',
  PAYOUTS: 'payouts',
  PRICING_CATEGORIES: 'pricing-categories',
  CANCELLATION_POLICY: 'cancellation-policy',
  PRODUCTS: 'products',
  PROFILE: 'profile',
  PROMO_CODES: 'promo-codes',
  REGISTER_BUSINESS_INFORMATION: 'register-business-information',
  REGISTER_CONFIRMATION: 'register-confirmation',
  REGISTER_CREATE_ACCOUNT: 'register-create-account',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
  REPORTS: 'reports',
  REVENUE: 'revenue',
  SETTINGS: 'settings',
  TICKET_REDEMPTION_LOG: 'tickets-redemption-log',
  UPDATE_PASSWORD: 'update-password',
  USERS: 'users',
  EMAIL_TRIGGER: 'email-trigger',
  EMAIL_TRIGGER_NEW: 'email-trigger-new',
  EMAIL_TRIGGER_EDIT: 'email-trigger-edit',
  COMMUNICATION: 'communication',
  EMAIL_TEMPLATES: 'email-templates',
  EMAIL_TEMPLATE_NEW: 'email-template-new',
  EMAIL_TEMPLATE_EDIT: 'email-template-edit',
  RESELLERS: 'resellers',
  NEW_RESELLER: 'new-reseller',
  EDIT_RESELLER: 'edit-reseller',
  RESELLER_ORDERS: 'reseller-orders',
  RESELLER_DASHBOARD: 'reseller-dashboard'
};
