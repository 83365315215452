// Use translation keys set in Directus
export const PAGE_TITLES = {
  // AUTH: 'auth',
  ADDONS: 'portal_addons_page_title',
  ACCESS_DENIED: 'portal_access_denied',
  ALLOCATIONS: 'portal_allocations_page_title',
  AVAILABILITY: 'portal_time_slots_page_title',
  AVAILABILITIES: 'portal_availabilities_page_title',
  BOOKING_SCANNER: 'portal_ticketing_scanner_title',
  BOOKING: 'portal_booking_page_title',
  BOOKINGS_TABLE: 'portal_bookings_page_tab_title_overview',
  BOOKINGS: 'portal_bookings_page_title',
  ORDERS: 'portal_orders_page_title',
  BUSINESS_INFORMATION: 'portal_business_information_page_title',
  CHANNELS: 'portal_channels_page_title',
  CHANNEL_DETAILS: 'portal_channels_details_breadcrumb_title',
  CHANNEL_SETTINGS: 'portal_channels_settings_page_title',
  CHANNEL_PRODUCT_CONFIGURATIONS: 'portal_channels_product_configurations_page_title',
  CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_EDIT:
    'portal_channels_product_option_configuration_edit_page_title',
  CHANNEL_PRODUCT_OPTION_CHANNEL_CONFIGURATION_NEW:
    'portal_channels_product_option_configuration_new_page_title',
  CONNECTIONS: 'portal_connections_page_title',
  CUSTOMERS: 'portal_customers_page_title',
  CUSTOMIZATION_SETTINGS: 'portal_customisation_page_title',
  INTEGRATION_SETTINGS: 'portal_integration_settings_page_title',
  INTEGRATION_EDIT: 'portal_integration_edit_page_title',
  DASHBOARD: 'portal_dashboard_page_title',
  EDIT_ADDON: 'portal_addon_edit_page_title',
  EDIT_AVAILABILITY: 'portal_time_slots_edit_page_title',
  EDIT_CONNECTION: 'portal_connection_edit_page_title',
  EDIT_CUSTOMER: 'portal_customer_edit_page_title',
  EDIT_PRICING_CATEGORY: 'portal_pricing_category_edit_page_title',
  EDIT_PRODUCT_ADDRESS: 'portal_products_tab_title_location',
  EDIT_PRODUCT_INFO: 'portal_products_tab_title_info',
  EDIT_PRODUCT_MEDIA: 'portal_products_tab_title_media',
  EDIT_PRODUCT_OPTION: 'portal_product_options_edit_page_title',
  EDIT_PRODUCT_OPTION_INFO: 'portal_product_option_tab_title_info',
  EDIT_PRODUCT_OPTION_SETTINGS: 'portal_product_option_tab_title_settings',
  EDIT_PRODUCT_OPTION_ADDRESS: 'portal_product_option_tab_title_address',
  EDIT_PRODUCT_OPTION_PRICING: 'portal_product_option_tab_title_pricing',
  EDIT_PRODUCT_OPTION_PRICINGS: 'portal_product_option_tab_title_pricings',
  EDIT_PRODUCT_OPTION_AVAILABILITIES: 'portal_product_option_tab_title_availabilities',
  EDIT_PRODUCT_OPTION_CHANNELS: 'portal_product_option_tab_title_channels',
  EDIT_PRODUCT_OPTION_QUESTIONS: 'portal_product_option_tab_title_questions',
  EDIT_PRODUCT_OPTION_TICKET_CONFIGURATION: 'portal_product_option_tab_title_ticket_configuration',
  EDIT_PRODUCT_PRICING: 'portal_pricing_edit_page_title',
  EDIT_PRODUCT: 'portal_product_update_edit_page_title',
  EMAIL_SETTINGS: 'portal_email_page_title',
  EMPTY: 'portal_empty',
  ERROR: 'portal_error_title',
  HOME: 'portal_home_page_title',
  SELECT_PARTNER: 'portal_select_partner_page_title',
  LOGIN: 'portal_login',
  RESET_PASSWORD: 'portal_reset_password_page_title',
  UPDATE_PASSWORD: 'portal_update_password_page_title',
  MANAGE: 'portal_manage_page_title',
  NEW_ADDON: 'portal_addon_create_page_title',
  NEW_AVAILABILITY: 'portal_time_slots_create_page_title',
  NEW_CONNECTION: 'portal_connection_create_page_title',
  NEW_PRICING_CATEGORY: 'portal_pricing_category_create_page_title',
  NEW_PRODUCT_OPTION: 'portal_product_option_create_page_title',
  NEW_PRODUCT_PRICING: 'portal_pricing_create_page_title',
  NEW_PRODUCT: 'portal_product_new_page_title',
  NEWSLETTER_SETTINGS: 'portal_newsletter_page_title',
  NOT_FOUND: 'portal_not_found_page_title',
  PAYMENT_PROVIDERS: 'portal_payment_providers_page_title',
  PAYOUT: 'portal_payout_page_title',
  PAYOUTS: 'portal_payouts_page_title',
  PRICING_CATEGORIES: 'portal_pricing_categories_page_title',
  CANCELLATION_POLICY: 'portal_cancellation_policy_page_title',
  PRODUCTS: 'portal_products_page_title',
  PRODUCT_OPTIONS: 'portal_product_options_page_title',
  PROFILE: 'portal_profile_page_title',

  PROMO_CODES: 'portal_promo_codes_page_title',
  EDIT_PROMO_CODE: 'portal_promo_code_page_title',
  NEW_PROMO_CODE: 'portal_promo_code_create_page_title',

  GIFT_CODES: 'portal_gift_codes_page_title',
  EDIT_GIFT_CODE: 'portal_gift_code_edit_page_title',
  NEW_GIFT_CODE: 'portal_gift_code_create_page_title',

  REGISTER_BUSINESS_INFORMATION: 'portal_register_business_info_page_title',
  REGISTER_CONFIRMATION: 'portal_register_confirmation_page_title',
  REGISTER_CREATE_ACCOUNT: 'portal_register_create_account_page_title',
  REGISTER: 'portal_register',
  REPORTS: 'portal_reports_page_title',
  REVENUE: 'portal_revenue_page_title',
  SETTINGS: 'portal_settings_page_title',
  SELL: 'portal_sell_page_title',
  TICKET_REDEMPTION_LOG: 'portal_bookings_page_tab_title_redemption_log',
  TICKET_SCANNER: 'portal_ticketing_scanner_title',
  PRICING_AVAILABILITY: 'portal_products_tab_title_pricing_availability',
  NEW_PRICING_AVAILABILITY: 'portal_pricing_availability_new',
  NEW_FLEXIBLE_PRICING: 'portal_flexible_pricing_new',

  USERS: 'portal_users_page_title',
  EDIT_USER: 'portal_edit_user_page_title',
  INVITE_USER: 'portal_invite_user_page_title',

  QUESTIONS: 'portal_questions_page_title',
  NEW_QUESTION: 'portal_question_new_page_title',
  EDIT_QUESTION: 'portal_question_edit_page_title',
  EDIT_QUESTION_CHOICE: 'portal_question_choice_edit_page_title',
  NEW_QUESTION_CHOICE: 'portal_question_choice_new_page_title',

  // Communication
  COMMUNICATION: 'portal_communication_page_title',
  COMMUNICATION_EMAIL_TRIGGER: 'portal_communication_email_trigger_page_title',
  COMMUNICATION_EMAIL_TRIGGER_NEW: 'portal_communication_email_trigger_new_page_title',
  COMMUNICATION_EMAIL_TRIGGER_EDIT: 'portal_communication_email_trigger_edit_page_title',
  COMMUNICATION_EMAIL_TEMPLATES: 'portal_communication_email_templates_page_title',
  COMMUNICATION_EMAIL_TEMPLATE_NEW: 'portal_communication_email_template_new_page_title',
  COMMUNICATION_EMAIL_TEMPLATE_EDIT: 'portal_communication_email_template_edit_page_title',

  // Resellers
  RESELLERS: 'portal_resellers_page_title',
  NEW_RESELLER: 'portal_reseller_new_page_title',
  EDIT_RESELLER: 'portal_reseller_edit_page_title',
  RESELLER_ORDERS: 'portal_reseller_orders_page_title',
  RESELLER_DASHBOARD: 'portal_reseller_dashboard_page_title'
};
