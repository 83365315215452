/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RegisterReadResponseSupportedCountriesInner } from './RegisterReadResponseSupportedCountriesInner';
import {
  RegisterReadResponseSupportedCountriesInnerFromJSON,
  RegisterReadResponseSupportedCountriesInnerFromJSONTyped,
  RegisterReadResponseSupportedCountriesInnerToJSON
} from './RegisterReadResponseSupportedCountriesInner';

/**
 *
 * @export
 * @interface RegisterReadResponse
 */
export interface RegisterReadResponse {
  /**
   *
   * @type {boolean}
   * @memberof RegisterReadResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof RegisterReadResponse
   */
  exception_code?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterReadResponse
   */
  exception_message?: string;
  /**
   *
   * @type {string}
   * @memberof RegisterReadResponse
   */
  error_code?: RegisterReadResponseErrorCodeEnum;
  /**
   * Error message details. In case of a validation error, this will contain the validation error message in English.
   * @type {string}
   * @memberof RegisterReadResponse
   */
  error_message?: string;
  /**
   *
   * @type {Array<RegisterReadResponseSupportedCountriesInner>}
   * @memberof RegisterReadResponse
   */
  supported_countries: Array<RegisterReadResponseSupportedCountriesInner>;
  /**
   *
   * @type {Array<RegisterReadResponseSupportedCountriesInner>}
   * @memberof RegisterReadResponse
   */
  locales: Array<RegisterReadResponseSupportedCountriesInner>;
  /**
   *
   * @type {Array<RegisterReadResponseSupportedCountriesInner>}
   * @memberof RegisterReadResponse
   */
  currencies: Array<RegisterReadResponseSupportedCountriesInner>;
}

/**
 * @export
 */
export const RegisterReadResponseErrorCodeEnum = {
  GenericError: 'GENERIC_ERROR',
  RequestValidationError: 'REQUEST_VALIDATION_ERROR'
} as const;
export type RegisterReadResponseErrorCodeEnum =
  (typeof RegisterReadResponseErrorCodeEnum)[keyof typeof RegisterReadResponseErrorCodeEnum];

/**
 * Check if a given object implements the RegisterReadResponse interface.
 */
export function instanceOfRegisterReadResponse(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'success' in value;
  isInstance = isInstance && 'supported_countries' in value;
  isInstance = isInstance && 'locales' in value;
  isInstance = isInstance && 'currencies' in value;

  return isInstance;
}

export function RegisterReadResponseFromJSON(json: any): RegisterReadResponse {
  return RegisterReadResponseFromJSONTyped(json, false);
}

export function RegisterReadResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RegisterReadResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    success: json['success'],
    exception_code: !exists(json, 'exception_code') ? undefined : json['exception_code'],
    exception_message: !exists(json, 'exception_message') ? undefined : json['exception_message'],
    error_code: !exists(json, 'error_code') ? undefined : json['error_code'],
    error_message: !exists(json, 'error_message') ? undefined : json['error_message'],
    supported_countries: (json['supported_countries'] as Array<any>).map(
      RegisterReadResponseSupportedCountriesInnerFromJSON
    ),
    locales: (json['locales'] as Array<any>).map(
      RegisterReadResponseSupportedCountriesInnerFromJSON
    ),
    currencies: (json['currencies'] as Array<any>).map(
      RegisterReadResponseSupportedCountriesInnerFromJSON
    )
  };
}

export function RegisterReadResponseToJSON(value?: RegisterReadResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    success: value.success,
    exception_code: value.exception_code,
    exception_message: value.exception_message,
    error_code: value.error_code,
    error_message: value.error_message,
    supported_countries: (value.supported_countries as Array<any>).map(
      RegisterReadResponseSupportedCountriesInnerToJSON
    ),
    locales: (value.locales as Array<any>).map(RegisterReadResponseSupportedCountriesInnerToJSON),
    currencies: (value.currencies as Array<any>).map(
      RegisterReadResponseSupportedCountriesInnerToJSON
    )
  };
}
