/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmailTemplatesCreateResponseEmailTemplateTranslationsInner } from './EmailTemplatesCreateResponseEmailTemplateTranslationsInner';
import {
  EmailTemplatesCreateResponseEmailTemplateTranslationsInnerFromJSON,
  EmailTemplatesCreateResponseEmailTemplateTranslationsInnerFromJSONTyped,
  EmailTemplatesCreateResponseEmailTemplateTranslationsInnerToJSON
} from './EmailTemplatesCreateResponseEmailTemplateTranslationsInner';

/**
 *
 * @export
 * @interface EmailTemplatesCreateResponseEmailTemplate
 */
export interface EmailTemplatesCreateResponseEmailTemplate {
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponseEmailTemplate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponseEmailTemplate
   */
  partner_id: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponseEmailTemplate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof EmailTemplatesCreateResponseEmailTemplate
   */
  status: EmailTemplatesCreateResponseEmailTemplateStatusEnum;
  /**
   *
   * @type {Array<EmailTemplatesCreateResponseEmailTemplateTranslationsInner>}
   * @memberof EmailTemplatesCreateResponseEmailTemplate
   */
  translations?: Array<EmailTemplatesCreateResponseEmailTemplateTranslationsInner>;
}

/**
 * @export
 */
export const EmailTemplatesCreateResponseEmailTemplateStatusEnum = {
  Draft: 'draft',
  Active: 'active',
  Inactive: 'inactive',
  Deleted: 'deleted'
} as const;
export type EmailTemplatesCreateResponseEmailTemplateStatusEnum =
  (typeof EmailTemplatesCreateResponseEmailTemplateStatusEnum)[keyof typeof EmailTemplatesCreateResponseEmailTemplateStatusEnum];

/**
 * Check if a given object implements the EmailTemplatesCreateResponseEmailTemplate interface.
 */
export function instanceOfEmailTemplatesCreateResponseEmailTemplate(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'partner_id' in value;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'status' in value;

  return isInstance;
}

export function EmailTemplatesCreateResponseEmailTemplateFromJSON(
  json: any
): EmailTemplatesCreateResponseEmailTemplate {
  return EmailTemplatesCreateResponseEmailTemplateFromJSONTyped(json, false);
}

export function EmailTemplatesCreateResponseEmailTemplateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): EmailTemplatesCreateResponseEmailTemplate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    partner_id: json['partner_id'],
    name: json['name'],
    status: json['status'],
    translations: !exists(json, 'translations')
      ? undefined
      : (json['translations'] as Array<any>).map(
          EmailTemplatesCreateResponseEmailTemplateTranslationsInnerFromJSON
        )
  };
}

export function EmailTemplatesCreateResponseEmailTemplateToJSON(
  value?: EmailTemplatesCreateResponseEmailTemplate | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    partner_id: value.partner_id,
    name: value.name,
    status: value.status,
    translations:
      value.translations === undefined
        ? undefined
        : (value.translations as Array<any>).map(
            EmailTemplatesCreateResponseEmailTemplateTranslationsInnerToJSON
          )
  };
}
